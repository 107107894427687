import VideoPlayer from "./components/VideoPlayer";
import CareGivers from "./components/CareGivers";
import Questions from "./components/Questions";
import BannerImg from "../../assets/images/rqc-main.jpeg";
import BannerBottomImg from "../../assets/images/rqc-banner-bottom.jpeg";
import QuoteImg from "../../assets/images/qualityCareQuote.jpg";
import SupportTopics from "../SupportTopics/SupportTopics";
import { VideoLink } from "../VideoLink/VideoLink";
import FindSisterhood from "../FindSisterhood/FindSisterhood";
import ConnectToSpecialist from "../ConnectToSpecialist/ConnectToSpecialist";
import "./QualityCare.css";

function QualityCare() {
  return (
    <>
      <div className="rqc-main">
        <div className="gradient-bg-container">
          <div className="rqc-banner">
            <div className=" rqc-banner-container main-banner">
              <VideoLink url="https://www.youtube.com/watch?v=-w59QI4gAlk" />
              <div className="rqc-banner-text">
                <h1 className="display-h1">
                  <span>Getting</span> Quality Care
                </h1>
                <p>
                  Managing diabetes can feel like a full-time job. But that’s
                  because you need different types of health care team members
                  like a primary care doctor, an endocrinologist, or a diabetes
                  care and education specialist. All these health care
                  professionals working together can become your all-star team
                  for better care.{" "}
                </p>

                <p>
                  With a strong diabetes health care team, your diabetes
                  management can feel like less of a solo job and more like a
                  team effort. Remember, you deserve a health care team that
                  respects you, shares information with you, and communicates
                  with you.
                </p>

                <p>
                  Getting the care you deserve is not always easy. Everyone has
                  a right to quality care. Let’s make sure you are getting the
                  care you need. Start by answering the questions below.
                </p>
              </div>
              <div className="rqc-banner-image">
                <div className="img-wrapper">
                  <img
                    role="presentation"
                    className="banner-img"
                    src={BannerImg}
                    alt="smiling black woman"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="rqc-testimonial-section">
            <div className="rqc-blockquote-wrapper">
              <blockquote className="callout quote EN">
                <img
                  role="presentation"
                  className="rqc-testimonial-img"
                  src={QuoteImg}
                  alt="testimonial"
                />
                <p>
                  You deserve a health care provider that you can trust. With
                  the right care team supporting you, so much can be
                  accomplished.
                </p>
              </blockquote>
            </div>
          </div>
          <Questions />
        </div>
        <div className="gradient-bg-container">
          <CareGivers />
          <section className="video-container">
            <h2 className="display-h1">
              Getting Ready <br />
              For Your Visit
            </h2>
            <p className="strong">
              Watch this video to learn more about getting ready for your
              diabetes care visit!
            </p>
            <VideoPlayer />
          </section>
        </div>
        <div className="rqc-banner-bottom">
          <div className="rqc-banner-container">
            <div className="rqc-banner-text">
              <p>
                Each of these health care professionals is important, but every
                team needs a captain — that’s YOU! Remember, it’s your life and
                your health. You should feel supported, understood, and cared
                for by your teammates.
              </p>
              <p>
                Take a moment to think about the diabetes care team you have
                now. Talk to a health care provider you trust, like your primary
                care provider, about how you can add new members to the team to
                make sure you receive quality care.
              </p>
            </div>
            <div className="img-wrapper">
              <img role="presentation" src={BannerBottomImg} alt="" />
            </div>
          </div>
        </div>
        <div class="hideExternal">
          <FindSisterhood topic="quality" />
        </div>
        <div id="SupportTopics" className="SupportTopics">
          <SupportTopics />
        </div>
        <ConnectToSpecialist />
      </div>
    </>
  );
}

export default QualityCare;
