import React from 'react'
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import happy from '../../../assets/images/quiz-happy.svg'
import neutral from '../../../assets/images/quiz-neutral.svg'
import sad from '../../../assets/images/quiz-sad.svg'

export const Q4 = ({ handleNextClick, handlePreviousClick, handleWorriedClick, handleNeutralClick, error,  setError, answer, setQuizAnswers }) => {
  const handleWorriedAnswerClick = () => {
    setError(false);
    setQuizAnswers((state) => ({...state, q4: 'down'}))
    handleWorriedClick('coping');
    setTimeout(() => (handleNextClick()), 600);
  }
  const handleNeutralAnswerClick = () => {
    setError(false);
    setQuizAnswers((state) => ({...state, q4: 'neutral'}))
    handleNeutralClick('coping');
    setTimeout(() => (handleNextClick()), 600);
  }
  const handlePositiveAnswerClick = () => {
    setError(false);
    setQuizAnswers((state) => ({...state, q4: 'up'}))
    setTimeout(() => (handleNextClick()), 600);
  }
  const nextCheck = () => {
    if(answer !== '') {
      handleNextClick();
    } else {
      setError(true);
    }
  }
  return (
    <div className="quiz-question-container">
      <h2 className="h3">Think about how you feel about managing your diabetes when things get tough.</h2>
      <div className="button-grid">
        <button
          aria-pressed= {answer === 'up' ? true : false}
          tabIndex={4} className={`${"quiz-option happy"} ${(answer === 'up') ? "selected-option" : ""}`}
          onClick={handlePositiveAnswerClick}
        > 
          <img className="icon" src={happy} alt="" />
          <p><strong>I feel good </strong>about managing my emotions and stress.</p>
          <div className="checkmark-wrapper">
            <svg className="icon-check-mark" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M18.2973 7.65736C17.875 7.23498 17.1866 7.23498 16.7642 7.65736L9.21341 15.2095L7.06807 13.0642C6.64569 12.6418 5.95731 12.6418 5.53492 13.0642C5.33041 13.2687 5.2168 13.5413 5.2168 13.8314C5.2168 14.1214 5.32908 14.3928 5.53492 14.5986L8.44617 17.5099C8.65067 17.7144 8.92335 17.8267 9.21207 17.8267C9.50079 17.8267 9.77347 17.7144 9.97931 17.5085L18.2973 9.19051C18.7197 8.76813 18.7197 8.07975 18.2973 7.65736Z"/>
              <path d="M12.1379 0.792969C5.88236 0.792969 0.793701 5.88163 0.793701 12.1372C0.793701 18.3928 5.88236 23.4814 12.1379 23.4814C18.3935 23.4814 23.4821 18.3928 23.4821 12.1372C23.4821 5.88163 18.3935 0.792969 12.1379 0.792969ZM12.1379 21.2545C7.11075 21.2545 3.02057 17.1644 3.02057 12.1372C3.02057 7.11002 7.11075 3.01984 12.1379 3.01984C17.1651 3.01984 21.2553 7.11002 21.2553 12.1372C21.2553 17.1644 17.1651 21.2545 12.1379 21.2545Z"/>
            </svg>
          </div>
        </button>
        <button
          aria-pressed= {answer === 'neutral' ? true : false}
          tabIndex={5}
          className={`${"quiz-option neutral"} ${(answer === 'neutral') ? "selected-option" : ""}`}
          onClick={handleNeutralAnswerClick}
        >
          <img className="icon" src={neutral} alt="" />
          <p><strong>I get by,</strong> but some days I feel stressed.</p>
          <div className="checkmark-wrapper">
            <svg className="icon-check-mark" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M18.2973 7.65736C17.875 7.23498 17.1866 7.23498 16.7642 7.65736L9.21341 15.2095L7.06807 13.0642C6.64569 12.6418 5.95731 12.6418 5.53492 13.0642C5.33041 13.2687 5.2168 13.5413 5.2168 13.8314C5.2168 14.1214 5.32908 14.3928 5.53492 14.5986L8.44617 17.5099C8.65067 17.7144 8.92335 17.8267 9.21207 17.8267C9.50079 17.8267 9.77347 17.7144 9.97931 17.5085L18.2973 9.19051C18.7197 8.76813 18.7197 8.07975 18.2973 7.65736Z"/>
              <path d="M12.1379 0.792969C5.88236 0.792969 0.793701 5.88163 0.793701 12.1372C0.793701 18.3928 5.88236 23.4814 12.1379 23.4814C18.3935 23.4814 23.4821 18.3928 23.4821 12.1372C23.4821 5.88163 18.3935 0.792969 12.1379 0.792969ZM12.1379 21.2545C7.11075 21.2545 3.02057 17.1644 3.02057 12.1372C3.02057 7.11002 7.11075 3.01984 12.1379 3.01984C17.1651 3.01984 21.2553 7.11002 21.2553 12.1372C21.2553 17.1644 17.1651 21.2545 12.1379 21.2545Z"/>
            </svg>
          </div>
        </button>
        <button
          aria-pressed= {answer === 'down' ? true : false}
          tabIndex={6}
          className={`${"quiz-option worried"} ${(answer === 'down') ? "selected-option" : ""}`}
          onClick={handleWorriedAnswerClick}
        >
          <img className="icon" src={sad} alt="" />
          <p><strong>I feel upset</strong> and worried.</p>
          <div className="checkmark-wrapper">
            <svg className="icon-check-mark" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M18.2973 7.65736C17.875 7.23498 17.1866 7.23498 16.7642 7.65736L9.21341 15.2095L7.06807 13.0642C6.64569 12.6418 5.95731 12.6418 5.53492 13.0642C5.33041 13.2687 5.2168 13.5413 5.2168 13.8314C5.2168 14.1214 5.32908 14.3928 5.53492 14.5986L8.44617 17.5099C8.65067 17.7144 8.92335 17.8267 9.21207 17.8267C9.50079 17.8267 9.77347 17.7144 9.97931 17.5085L18.2973 9.19051C18.7197 8.76813 18.7197 8.07975 18.2973 7.65736Z"/>
              <path d="M12.1379 0.792969C5.88236 0.792969 0.793701 5.88163 0.793701 12.1372C0.793701 18.3928 5.88236 23.4814 12.1379 23.4814C18.3935 23.4814 23.4821 18.3928 23.4821 12.1372C23.4821 5.88163 18.3935 0.792969 12.1379 0.792969ZM12.1379 21.2545C7.11075 21.2545 3.02057 17.1644 3.02057 12.1372C3.02057 7.11002 7.11075 3.01984 12.1379 3.01984C17.1651 3.01984 21.2553 7.11002 21.2553 12.1372C21.2553 17.1644 17.1651 21.2545 12.1379 21.2545Z"/>
            </svg>
          </div>
        </button>
      </div>
      <div className="quiz-question-footer">
        <div tabIndex={-1} className={`${"error-msg-wrapper"} ${(error) ? "show" : ""}`}>
          <ArrowCircleUpIcon />
          <p className="error-msg">
            Please select one of the three options to continue the quiz
          </p>
          <ArrowCircleUpIcon />
        </div>
        <button
          tabIndex={7}
          className='btn-prev'
          value={'previous'}
          onClick={handlePreviousClick}>
            &lt; Previous 
        </button>
        <button
          tabIndex={8}
          className="btn-next"
          value={'next'}
          onClick={nextCheck}
        >
          Next &gt;
        </button>
      </div>
    </div>
  )
}

export default Q4
