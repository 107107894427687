import RoutePaths from "../../routes";
import SupportTopics from "../SupportTopics/SupportTopics";
import Carousel from "./components/Carousel/Carousel";
import { Grid } from "@mui/material";
import BannerImg from "../../assets/images/learningHeader.jpeg";
import FooterImage from "../../assets/images/learningFooter.jpeg";
import image2 from "../../assets/images/learning3.jpeg";
import "./LearningAboutDSMES.css";

function LearningAboutDSMES() {
  return (
    <>
      <div className="dsmes-gradient-container">
        <div className="dsmes-banner">
          <div className="dsmes-banner-container">
            <div className="dsmes-banner-text">
              <h1 className="display-h1">
                <span>Learning</span> About Personalized Support Services -
                Diabetes Self-Management Education and Support{" "}
              </h1>
              <div>
                <div className="dsmes-banner-image-wrapper dsmes-mobile-show">
                  <img
                    role="presentation"
                    className="dsmes-banner-img"
                    src={BannerImg}
                    alt="black woman smiling"
                  />
                </div>
              </div>
              <p>
                Personalized Support Services - diabetes self-management
                education and support (DSMES) are services to empower you and
                help you feel supported on your diabetes journey.
              </p>
              <p>
                When you participate in a DSMES program, you’ll work with a
                diabetes care and education specialist. Diabetes care and
                education specialists are trained to help, listen, and support
                you. DSMES can help you on your journey to:
              </p>
              <ul>
                <li>
                  Manage your diabetes to live a balanced and healthy life{" "}
                </li>
                <li>
                  Find healthy ways to cope with stress managing diabetes{" "}
                </li>
                <li>Provide dedicated specialists to talk about diabetes </li>
                <li>
                  Answer questions about diabetes that haven’t been answered{" "}
                </li>
              </ul>
              <p>Read on below to learn more about DSMES.</p>
            </div>
            <div>
              <div className="dsmes-banner-image-wrapper dsmes-mobile-no-show">
                <img
                  role="presentation"
                  className="dsmes-banner-img"
                  src={BannerImg}
                  alt="black woman smiling"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="dsmes-quote-container">
          <div className="hq-testimonial-section">
            <blockquote className="callout quote EN">
              <h2 className="h3 strong">DSMES Insight</h2>
              <p>
                DSMES gives you the knowledge, skills and support to manage your
                diabetes. It provides you with the foundation to help you
                navigate your daily self-care with confidence. Studies even show
                that diabetes education helps you lower your blood sugar, blood
                pressure and cholesterol levels. This helps you stay healthier
                and reduce the risk of diabetes complications.
              </p>
            </blockquote>
          </div>
        </div>
        <Carousel />
      </div>
      <hr className="dsmes-hr" />
      <div className="dsmes-boxes-section">
        <div className="dsmes-boxes-section-block">
          <div>
            <img
              role="presentation"
              src={image2}
              className="dsmes-boxes-section-block-img"
              alt="bowl of fruits"
            />
          </div>
          <div className="dsmes-boxes-section-block-text">
            <h1>What to Expect</h1>
            <p>
              As part of DSMES, your diabetes care and education specialist will
              most likely talk to you about these topics:
            </p>
          </div>
        </div>
        <Grid container spacing={6} className="dsmes-text-boxes-container">
          <Grid item xs={12} sm={6} md={4}>
            <div className="dsmes-text-boxes">
              <h2 className="h3">Healthy Eating</h2>
              <p>
                A diabetes care and education specialist can talk with you about
                foods and recipes that matter to you. You can talk to them about
                how to enjoy the foods you love while keeping your blood sugar
                on track.
              </p>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <div className="dsmes-text-boxes">
              <h2 className="h3">Being Active</h2>
              <p>
                Ask a diabetes care and education specialist about how to safely
                add activities you enjoy (like stretching or dancing) to your
                diabetes management plan. They can work with you to find ways to
                get active.
              </p>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <div className="dsmes-text-boxes">
              <h2 className="h3">Monitoring</h2>
              <p>
                A diabetes care and education specialist can answer your
                questions about monitoring your blood sugar and keeping track of
                other important numbers such as blood pressure. Talk to them
                about the best monitoring plan for you.
              </p>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <div className="dsmes-text-boxes">
              <h2 className="h3">Taking Medication</h2>
              <p>
                Diabetes care and education specialists can help you with the
                medications you may take to manage diabetes. You can talk to
                them about your medicines and share any questions or concerns
                you have.
              </p>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <div className="dsmes-text-boxes">
              <h2 className="h3">Problem Solving</h2>
              <p>
                Diabetes management is not always easy. Talk to your diabetes
                care and education specialist about things that can make
                managing diabetes difficult, like going out to eat with friends
                or fitting physical activity into your lifestyle. They can offer
                tips and ideas for how to handle these situations.
              </p>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <div className="dsmes-text-boxes">
              <h2 className="h3">Healthy Coping</h2>
              <p>
                You can talk to a diabetes care and education specialist about
                how you are feeling. They are here to support you. They can
                provide emotional support for your diabetes journey.
              </p>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <div className="dsmes-text-boxes">
              <h2 className="h3">Reducing Risk</h2>
              <p>
                Talk to your diabetes care and education specialist about ways
                you can reduce your risk every day. They can help you reduce the
                risk of diabetes related health issues such as heart disease,
                chronic kidney disease, nerve damage, and other problems with
                feet, oral health, vision, hearing, and mental health. Reducing
                these risks is a big part of managing diabetes.
              </p>
            </div>
          </Grid>
        </Grid>
      </div>
      <div className="dsmes-container-banner-bottom">
        <div className="dsmes-banner-bottom">
          <div className="dsmes-banner-image-wrapper dsmes-mobile-no-show">
            <img
              role="presentation"
              className="dsmes-banner-img dsmes-banner-img-bottom"
              src={FooterImage}
              alt="doctor going over information with patient"
            />
          </div>
          <div>
            <div className="dsmes-banner-image-wrapper dsmes-mobile-show">
              <img
                role="presentation"
                className="dsmes-banner-img dsmes-banner-img-bottom"
                src={FooterImage}
                alt="doctor going over information with patient"
              />
            </div>
            <h3 className="dsmes-highlight-text strong">Let's Get Started</h3>

            <p>
              Talk to your primary care provider or another member of your
              diabetes health-care team to see if DSMES is the right fit for
              you. You will need a health-care provider to refer you to DSMES to
              get started. If you don’t have someone to refer you, contact a
              DSMES program in your area and ask about next steps. Check out{" "}
              <a href={RoutePaths.ConnectingToDSMES}>
                Connecting to Personalized Support Services - DSMES
              </a>{" "}
              to learn more.
            </p>
            <h3 className="strong">Wait, What Will This Cost Me?</h3>
            <p>
              Check with your healthcare coverage provider to learn what’s
              covered:
            </p>
            <ul>
              <li>Most private insurance plans cover DSMES</li>
              <li>
                Your state’s Medicaid program may cover DSMES for certain
                individuals{" "}
              </li>
              <li>
                If you are 65 and older and have Medicare Part B, you may be
                eligible for up to 10 hours of{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href=" https://www.medicare.gov/coverage/diabetes-self-management-training"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  diabetes self-management training.
                </a>
              </li>
            </ul>
            <h3 className="strong">Is It Worth It?</h3>
            <p>
              Diabetes education helps people lower their blood sugar, blood
              pressure, and cholesterol levels. DSMES can help you stay healthy,
              better manage diabetes, and make managing diabetes less stressful.
              DSMES is here to help. 
            </p>
          </div>
        </div>
      </div>
      <div id="supportTopics" className="SupportTopics">
        <SupportTopics />
      </div>
    </>
  );
}

export default LearningAboutDSMES;
