import { Link } from 'react-router-dom'
import RoutePaths from '../../routes'
import coping from '../../assets/images/supportTopics/living-with-diabetes.png'
import he from '../../assets/images/supportTopics/cooking-eating.png'
import md from '../../assets/images/supportTopics/monitoring.png'
import pa from '../../assets/images/supportTopics/increasing-activity.png'
import provider from '../../assets/images/supportTopics/finding-provider.png'
import qc from '../../assets/images/supportTopics/quality-care.png'
import rr from '../../assets/images/supportTopics/reducing-risk.png'
import community from '../../assets/images/supportTopics/creating-community.png'
import chevron from '../../assets/images/down-chevron.png'
import sign from '../../assets/images/sign-icon.svg'

import style from  './SupportTopics.module.scss'

function SupportTopics() {
  return (
    <div className={style["supportFlex"]}>
      <h2 className={style["support-topic-heading"]}>
        Diabetes Support Topics
      </h2>
      <div className={style["support-topics-grid"]}>
          <Link
            to={RoutePaths.MonitoringDiabetes}
            className={style["topic-link"]}
          >
        <div className={style["topic-wrapper"]}>
            <img className={style["photo"]} src={md}/>
            <p className={style["link-title"]}>Monitoring Diabetes</p>
            <img className={style["chevron"]} src={chevron}/>
        </div>
          </Link>
          <Link
            to={RoutePaths.ReducingRisk}
            className={style["topic-link"]}
          >
        <div className={style["topic-wrapper"]}>
            <img className={style["photo"]} src={rr}/>
            <p className={style["link-title"]}>Reducing Risk of Complications</p>
            <img className={style["chevron"]} src={chevron}/>
        </div>
          </Link>
          <Link
            to={RoutePaths.HealthyEating}
            className={style["topic-link"]}
          >
        <div className={style["topic-wrapper"]}>
            <img className={style["photo"]} src={he}/>
            <p className={style["link-title"]}>Creative Eating and Cooking</p>
            <img className={style["chevron"]} src={chevron}/>
        </div>
          </Link>
          <Link
            to={RoutePaths.PhysicalActivity}
            className={style["topic-link"]}
          >
        <div className={style["topic-wrapper"]}>
            <img className={style["photo"]} src={pa}/>
            <p className={style["link-title"]}>Increasing Physical Activity</p>
            <img className={style["chevron"]} src={chevron}/>
        </div>
          </Link>
          <Link
            to={RoutePaths.QualityCare}
            className={style["topic-link"]}
          >
        <div className={style["topic-wrapper"]}>
            <img className={style["photo"]} src={qc}/>
            <p className={style["link-title"]}>Getting Quality Care</p>
            <img className={style["chevron"]} src={chevron}/>
        </div>
          </Link>
          <Link
            to={RoutePaths.FindingProvider}
            className={style["topic-link"]}
          >
        <div className={style["topic-wrapper"]}>
            <img className={style["photo"]} src={provider}/>
            <p className={style["link-title"]}>Finding the Right Provider</p>
            <img className={style["chevron"]} src={chevron}/>
        </div>
          </Link>
          <Link
            to={RoutePaths.CreatingCommunity}
            className={style["topic-link"]}
          >
        <div className={style["topic-wrapper"]}>
            <img className={style["photo"]} src={community}/>
            <p className={style["link-title"]}>Creating Community Support</p>
            <img className={style["chevron"]} src={chevron}/>
        </div>
          </Link>
          <Link
            to={RoutePaths.CopingDiabetes}
            className={style["topic-link"]}
          >
        <div className={style["topic-wrapper"]}>
            <img className={style["photo"]} src={coping}/>
            <p className={style["link-title"]}>Living With Diabetes</p>
            <img className={style["chevron"]} src={chevron}/>
        </div>
          </Link>
      </div>
    </div>
  )
}

export default SupportTopics
