// import portionSizeImg from '../../../assets/images/SelfCare/Diabetes-Manage-Eat-Well-Plate-Graphic_600px 1.png'
import headerUnderline from '../../../../assets/images/modal-header-underline.png'
import spiritImg from '../../../../assets/images/copingDiabetes/spirit-joy-head.png'
import styles from './SelfCareModals.scss'

export const SelfCareModalContent12 = () => {
  return (
    <>
    <div id="modalTitle-1" className="modal-title">
       <h2 className="display-h2">Spirit - Things that Bring You Joy</h2>
       <img className="headerUnderline" src={headerUnderline} alt="decorative underline"/>
      </div>
       <div id="modalContent-1" className="modal-content">
         <img className="headImg" src={spiritImg} alt="women playing double dutch"/>
        <p><strong>Doing things that bring you joy</strong> can be another way of building social connections that are good for your mental health. Involvement in enjoyable activities, like a favorite hobby or pastime, can be more than just fun. It can bolster our sense of well-being. </p>
        </div>
    </>
  )
}