import { useId } from "react";
import bodyMoveHeadImg from "../../../../assets/images/copingDiabetes/body-move-head.png";
import { ExternalLink } from "../../../ExternalLink/ExternalLink";
import headerUnderline from "../../../../assets/images/modal-header-underline.png";
import styles from "./SelfCareModals.scss";

export const SelfCareModalContent02 = () => {
  const id = useId();
  return (
    <>
      <div id="modalTitle-2" className="modal-title">
        <h2 className="display-h2">BODY-Move</h2>
        <img
          className="headerUnderline"
          src={headerUnderline}
          alt="decorative underline"
        />
      </div>
      <div id="modalContent-2" className="modal-content">
        <img
          className="headImg"
          src={bodyMoveHeadImg}
          alt="woman riding a bicycle"
        />
        <p>
          <ExternalLink
            url="https://www.nimh.nih.gov/health/topics/caring-for-your-mental-health"
            cname="org-button"
            aLabel="Staying physically healthy can improve your emotional well-being"
          >
            Staying physically healthy can improve your emotional well-being
          </ExternalLink>
          . Just 30 minutes of walking every day can help boost your mood and
          improve your health. Small amounts of exercise add up, so don’t be
          discouraged if you can’t do 30 minutes at one time.{" "}
        </p>
        <p>
          Want to get moving but unsure where to start?{" "}
          <ExternalLink
            url="https://www.girltrek.org/"
            cname="org-button"
            aLabel="GirlTREK - A nonprofit organization dedicated to mobilizing black women to walk as a form of healing"
          >
            GirlTREK
          </ExternalLink>
          , a nonprofit organization dedicated to mobilizing Black women to walk
          as a form of healing, has a{" "}
          <ExternalLink
            url="https://acrobat.adobe.com/id/urn:aaid:sc:VA6C2:e8decfe4-864c-42a1-a604-48ae6ec409be?ms=FridayNewsletter_03.31.23&utm_campaign=fridaynewsletter&emci=5ddd43a7-80cf-ed11-a8e0-00224832e811&emdi=8ab2fede-19d0-ed11-a8e0-00224832e811&ceid=1092599&viewer%21megaV"
            cname="org-button"
            aLabel="elf-care scavenger hunt"
          >
            self-care scavenger hunt
          </ExternalLink>{" "}
          and upbeat music playlist to help you kick-start your journey to
          wellness.{" "}
        </p>
        <p>
          For more ideas on incorporating self-care through movement, check out
          the{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="/increasing-physical-activity"
            role="link"
          >
            module on physical activity
          </a>
          .
        </p>
      </div>
    </>
  );
};
