import MonitorIcon from '@mui/icons-material/Monitor'
import PhoneIcon from '@mui/icons-material/Phone'
import EmailIcon from '@mui/icons-material/Email'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import FacebookIcon from '@mui/icons-material/Facebook'
import InstagramIcon from '@mui/icons-material/Instagram'
import TwitterIcon from '@mui/icons-material/Twitter'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import YouTubeIcon from '@mui/icons-material/YouTube'
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications'
import WifiTetheringIcon from '@mui/icons-material/WifiTethering'
import ScreenshotMonitorIcon from '@mui/icons-material/ScreenshotMonitor'
import RssFeedIcon from '@mui/icons-material/RssFeed'
import './Footer.css'

function Footer() {
  return (
    <footer>
      <div className="row footer-container">
        <div className="footer-column">
          <h3 className="footer-title">HAVE QUESTIONS ?</h3>
          <p>
            <a
              className="footer-link"
              href="https://www.cdc.gov/cdc-info/index.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              <MonitorIcon /> &nbsp;Visit CDC-INFO
            </a>
          </p>
          <p>
            <span className="footer-link">
              <PhoneIcon /> &nbsp;<strong>Call 800-232-4636</strong>
            </span>
          </p>
          <p>
            <a
              className="footer-link"
              href="https://wwwn.cdc.gov/dcs/contactus/form"
              target="_blank"
              rel="noopener noreferrer"
            >
              <EmailIcon /> &nbsp;Email CDC-INFO
            </a>
          </p>
          <p>
            <a
              className="footer-link"
              href="https://www.cdc.gov/cdc-info/index.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              <AccessTimeIcon /> &nbsp;Open 24/7
            </a>
          </p>
        </div>
        <div className="footer-column">
          <h3 className="footer-title">CDC INFORMATION</h3>
          <p>
            <a
              className="footer-link"
              href="https://www.cdc.gov/about/"
              target="_blank"
              rel="noopener noreferrer"
            >
              About CDC
            </a>
          </p>
          <p>
            <a
              className="footer-link"
              href="https://jobs.cdc.gov/index.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              Jobs
            </a>
          </p>
          <p>
            <a
              className="footer-link"
              href="https://www.cdc.gov/funding/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Funding
            </a>
          </p>
          <p>
            <a
              className="footer-link"
              href="https://www.cdc.gov/Other/policies.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              Policies
            </a>
          </p>
          <p>
            <a
              className="footer-link"
              href="https://www.cdc.gov/other/plugins/index.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              File Viewers & players
            </a>
          </p>
        </div>
        <div className="footer-column information-section">
          <p>
            <a
              className="footer-link"
              href="https://www.cdc.gov/other/privacy.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy
            </a>
          </p>
          <p>
            <a
              className="footer-link"
              href="https://www.cdc.gov/od/foia/"
              target="_blank"
              rel="noopener noreferrer"
            >
              FOIA
            </a>
          </p>
          <p>
            <a
              className="footer-link"
              href="https://www.cdc.gov/eeo/nofearact/index.htm"
              target="_blank"
              rel="noopener noreferrer"
            >
              No Fear Act
            </a>
          </p>
          <p>
            <a
              className="footer-link"
              href="https://oig.hhs.gov/"
              target="_blank"
              rel="noopener noreferrer"
            >
              OIG
            </a>
          </p>
          <p>
            <a
              className="footer-link"
              href="https://www.cdc.gov/other/nondiscrimination.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              Nondiscrimination
            </a>
          </p>
          <p>
            <a
              className="footer-link"
              href="https://www.cdc.gov/contact/accessibility.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              Accessibility
            </a>
          </p>
        </div>
        <div className="footer-column">
          <h3 className="footer-title">CONNECT WITH CDC</h3>
          <p className="footer-socialmeadia">
            <a
              className="footer-link"
              href="https://www.facebook.com/login/?next=https%3A%2F%2Fwww.facebook.com%2FCDC"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Facebook"
            >
              <FacebookIcon role="link" className="social" />
            </a>
            <a
              className="footer-link"
              href="https://www.instagram.com/CDCgov/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Instagram"
            >
              <InstagramIcon role="link" className="social" />
            </a>
            <a
              className="footer-link"
              href="https://twitter.com/CDCgov"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Twitter"
            >
              <TwitterIcon role="link" className="social" />
            </a>
            <a
              className="footer-link"
              href="https://www.linkedin.com/authwall?trk=ripf&trkInfo=AQFdTy2RWOvADQAAAYEAZk-Q5SajohCnfCpwzyOcuYhV3VcDDzCiMU7pniXL02aX0rZQk7UiCsZ-ve-S4fyiW5Mu7ATn7aJCY0qsmHxY-i7p5oyvOqmawpN8963mAgZmx_3MtjY=&original_referer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Fcenters-for-disease-control-and-prevention"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="LinkedIn"
            >
              <LinkedInIcon role="link" className="social" />
            </a>
            <a
              className="footer-link"
              href="https://www.snapchat.com/add/cdcgov"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Notifications"
            >
              <CircleNotificationsIcon role="link" className="social" />
            </a>
          </p>
          <p className="footer-socialmeadia">
            <a
              className="footer-link"
              href="https://www.youtube.com/user/CDCstreamingHealth"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="YouTube"
            >
              <YouTubeIcon role="link" className="social" />
            </a>
            <a
              className="footer-link"
              href="https://tools.cdc.gov/medialibrary/index.aspx#/media/id/280594"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="National Diabetes Protection Program"
            >
              <WifiTetheringIcon role="link" className="social" />
            </a>
            <a
              className="footer-link"
              href="https://www.cdc.gov/cdctv/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="CDC-TV"
            >
              <ScreenshotMonitorIcon role="link" className="social" />
            </a>
            <a
              className="footer-link"
              href="https://tools.cdc.gov/medialibrary/index.aspx#/landing/mediatype/Feed/language/english/page/1/sort/desc/group/0"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="RSS Feed"
            >
              <RssFeedIcon role="link" className="social" />
            </a>
            <a
              className="footer-link"
              href="https://wwwn.cdc.gov/DCS/ContactUs/Form"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Contact Us"
            >
              <EmailIcon role="link" className="social" />
            </a>
          </p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
