// import portionSizeImg from '../../../assets/images/SelfCare/Diabetes-Manage-Eat-Well-Plate-Graphic_600px 1.png'
import headerUnderline from "../../../../assets/images/modal-header-underline.png";
import spiritImg from "../../../../assets/images/copingDiabetes/spirit-nature-head.png";
import styles from "./SelfCareModals.scss";

export const SelfCareModalContent10 = () => {
  return (
    <>
      <div id="modalTitle-1" className="modal-title">
        <h2 className="display-h2">Spirit - Be Out In Nature</h2>
        <img
          className="headerUnderline"
          src={headerUnderline}
          alt="decorative underline"
        />
      </div>
      <div id="modalContent-1" className="modal-content">
        <img
          className="headImg"
          src={spiritImg}
          alt="woman planting in a garden"
        />
        <p>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://wwwn.cdc.gov/ResearchGateway/Content/pdfs/R2C_FactSheets/en/R2C_Factsheet_102117_Desai_en.pdf"
          >
            Being outside in nature is a time-tested way to increase positive
            thinking and overall sense of well-being
          </a>
          . Spending time outdoors can improve overall health and wellness,
          promoting mental health and stress reduction. Time outdoors may also
          offer many opportunities to be physically active, another important
          way to practice self-care.{" "}
        </p>
      </div>
    </>
  );
};
