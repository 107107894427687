import { Link } from 'react-router-dom'
import image01 from '../../../assets/images/quiz-result-img-01.jpeg'
import image02 from '../../../assets/images/quiz-result-img-02.jpeg'
import RoutePaths from '../../../routes'
import Print from './Print/Print'

export const QuizResult = ({lowAnswers, mediumAnswers}) => {
  let stringAnswers = lowAnswers.toString() === '' ? mediumAnswers.toString() : lowAnswers.toString();
  let lowFlag = lowAnswers.toString() === '' ? 0 : 1;
  localStorage.setItem('dsmes_compass_date', Date.now().toString());
  localStorage.setItem('dsmes_learning_guide', stringAnswers);
  if (stringAnswers.indexOf('md') > -1 && stringAnswers.indexOf('qualityCare') > -1) {
    stringAnswers = 'md, qualityCare';
    localStorage.setItem('dsmes_learning_guide', stringAnswers);
  }
  else if (stringAnswers.indexOf('md') > -1 && stringAnswers.indexOf('healthyEating') > -1) {
    stringAnswers = 'md, healthyEating';
    localStorage.setItem('dsmes_learning_guide', stringAnswers);
  }
  else if (stringAnswers.indexOf('qualityCare') > -1 && stringAnswers.indexOf('healthyEating') > -1) {
    stringAnswers = 'qualityCare, healthyEating';
    localStorage.setItem('dsmes_learning_guide', stringAnswers);
  }
  if (lowFlag && (stringAnswers === 'md' || stringAnswers === 'qualityCare' || stringAnswers === 'healthyEating')) {
    stringAnswers+=(
      mediumAnswers.indexOf('md') > -1 ? 'md' : 
      mediumAnswers.indexOf('qualityCare') > -1 ? 'qualityCare' : 
      mediumAnswers.indexOf('healthyEating') > -1 ? 'healthyEating' : 
      mediumAnswers.indexOf('coping') > -1 && mediumAnswers.indexOf('community') > -1 ? 'coping, community' : 
      mediumAnswers.indexOf('coping') > -1 ? 'coping' : 
      mediumAnswers.indexOf('community') > -1 ? 'community' : '')
    localStorage.setItem('dsmes_learning_guide', stringAnswers);
  }
  if (lowFlag && (stringAnswers === 'coping' || stringAnswers === 'community' || stringAnswers === 'coping, community' || stringAnswers === 'community, coping')) {
    stringAnswers+=(
      mediumAnswers.indexOf('md') > -1 && mediumAnswers.indexOf('coping') > -1 ? 'md, coping' : 
      mediumAnswers.indexOf('md') > -1 && mediumAnswers.indexOf('community') > -1 ? 'md, community' : 
      mediumAnswers.indexOf('md') > -1 ? 'md' : 
      mediumAnswers.indexOf('qualityCare') > -1 && mediumAnswers.indexOf('coping') ? 'qualityCare, coping' : 
      mediumAnswers.indexOf('qualityCare') > -1 && mediumAnswers.indexOf('community') ? 'qualityCare, community' : 
      mediumAnswers.indexOf('qualityCare') > -1 ? 'qualityCare' : 
      mediumAnswers.indexOf('healthyEating') > -1 && mediumAnswers.indexOf('coping') ? 'healthyEating, coping' : 
      mediumAnswers.indexOf('healthyEating') > -1 && mediumAnswers.indexOf('community') ? 'healthyEating, community' : 
      mediumAnswers.indexOf('healthyEating') > -1 ? 'healthyEating' : 
      '')
    localStorage.setItem('dsmes_learning_guide', stringAnswers);
  }
  const handleRouteToTakeTheQuiz = () => window.location.reload()

    return (
    <>
      <div className="quiz-results-container">
        <div className="img-panel">
          <div className="img-wrapper chopped"><img role="presentation" src={image01} alt="woman listening to headphones" className='quiz-result-img'/></div>
          <div className="img-wrapper"><img role="presentation" src={image02} alt="woman smiling with hands clasped" className='quiz-result-img ml'/></div>
        </div>
        <div className="results-info-panel"  id="printDivContents">
          <p><i>Thanks for your responses! <br /> Check out the learning guide below to learn more. </i></p>
          <h2>Your Compass Learning Guide</h2>
          <div className="learning-guide-list">
          {stringAnswers.indexOf('md') !== -1 && (
              <>
                <div className="quiz-results-option">
                  <div className="icon-wrapper">
                    <svg width="46" height="68" viewBox="0 0 46 68" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <line x1="22.8848" y1="47.5513" x2="21.6967" y2="65.9359" stroke="#A8A9B3" strokeWidth="3" strokeLinecap="round" strokeDasharray="1 7"/>
                      <path d="M32.8995 19.7168C32.8995 14.0107 28.2674 9.37015 22.5726 9.37015C22.5698 9.37015 22.567 9.37015 22.5642 9.37015C22.5613 9.37015 22.5585 9.37015 22.5557 9.37015C16.861 9.37015 12.2289 14.0107 12.2289 19.7168C12.2289 21.6584 12.6386 23.1591 13.673 24.9395L21.7728 37.1684C21.9537 37.4425 22.2533 37.5895 22.5585 37.5895C22.5613 37.5895 22.5642 37.5895 22.567 37.5895C22.5698 37.5895 22.5726 37.5895 22.5755 37.5895C22.8807 37.5895 23.1803 37.4425 23.3612 37.1684L31.4892 24.8943C32.4953 23.1619 32.9051 21.6612 32.9051 19.7196L32.8995 19.7168ZM45.1453 23.4784C45.1453 35.9447 35.0389 46.0511 22.5726 46.0511C10.1064 46.0511 0 35.9447 0 23.4784C0 11.0122 10.1064 0.905762 22.5726 0.905762C35.0389 0.905762 45.1453 11.0122 45.1453 23.4784ZM27.2754 19.7168C27.2754 17.1252 25.1643 15.014 22.5726 15.014C19.981 15.014 17.8699 17.1252 17.8699 19.7168C17.8699 22.3084 19.981 24.4195 22.5726 24.4195C25.1643 24.4195 27.2754 22.3084 27.2754 19.7168ZM31.0173 19.7168C31.0173 21.3192 30.6922 22.5062 29.884 23.8995L22.5613 34.9583L15.267 23.9476C14.4304 22.5062 14.1054 21.322 14.1054 19.7168C14.1054 15.0479 17.8953 11.2524 22.55 11.2524C22.5529 11.2524 22.5557 11.2524 22.5585 11.2524C22.5613 11.2524 22.5642 11.2524 22.567 11.2524C27.2245 11.2524 31.0116 15.0508 31.0116 19.7168H31.0173ZM25.3932 19.7168C25.3932 21.274 24.127 22.5373 22.5726 22.5373C21.0183 22.5373 19.7521 21.2712 19.7521 19.7168C19.7521 18.1624 21.0183 16.8963 22.5726 16.8963C24.127 16.8963 25.3932 18.1624 25.3932 19.7168Z" fill="#1E5947"/>
                    </svg>
                  </div>
                  <Link to={RoutePaths.MonitoringDiabetes}>
                    <p className='quiz-results-label'>
                      Monitoring Diabetes 
                    </p>
                  </Link>
                </div>
                <div className="quiz-results-option">
                  <div className="icon-wrapper">
                    <svg width="46" height="68" viewBox="0 0 46 68" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <line x1="22.8848" y1="47.5513" x2="21.6967" y2="65.9359" stroke="#A8A9B3" strokeWidth="3" strokeLinecap="round" strokeDasharray="1 7"/>
                      <path d="M32.8995 19.7168C32.8995 14.0107 28.2674 9.37015 22.5726 9.37015C22.5698 9.37015 22.567 9.37015 22.5642 9.37015C22.5613 9.37015 22.5585 9.37015 22.5557 9.37015C16.861 9.37015 12.2289 14.0107 12.2289 19.7168C12.2289 21.6584 12.6386 23.1591 13.673 24.9395L21.7728 37.1684C21.9537 37.4425 22.2533 37.5895 22.5585 37.5895C22.5613 37.5895 22.5642 37.5895 22.567 37.5895C22.5698 37.5895 22.5726 37.5895 22.5755 37.5895C22.8807 37.5895 23.1803 37.4425 23.3612 37.1684L31.4892 24.8943C32.4953 23.1619 32.9051 21.6612 32.9051 19.7196L32.8995 19.7168ZM45.1453 23.4784C45.1453 35.9447 35.0389 46.0511 22.5726 46.0511C10.1064 46.0511 0 35.9447 0 23.4784C0 11.0122 10.1064 0.905762 22.5726 0.905762C35.0389 0.905762 45.1453 11.0122 45.1453 23.4784ZM27.2754 19.7168C27.2754 17.1252 25.1643 15.014 22.5726 15.014C19.981 15.014 17.8699 17.1252 17.8699 19.7168C17.8699 22.3084 19.981 24.4195 22.5726 24.4195C25.1643 24.4195 27.2754 22.3084 27.2754 19.7168ZM31.0173 19.7168C31.0173 21.3192 30.6922 22.5062 29.884 23.8995L22.5613 34.9583L15.267 23.9476C14.4304 22.5062 14.1054 21.322 14.1054 19.7168C14.1054 15.0479 17.8953 11.2524 22.55 11.2524C22.5529 11.2524 22.5557 11.2524 22.5585 11.2524C22.5613 11.2524 22.5642 11.2524 22.567 11.2524C27.2245 11.2524 31.0116 15.0508 31.0116 19.7168H31.0173ZM25.3932 19.7168C25.3932 21.274 24.127 22.5373 22.5726 22.5373C21.0183 22.5373 19.7521 21.2712 19.7521 19.7168C19.7521 18.1624 21.0183 16.8963 22.5726 16.8963C24.127 16.8963 25.3932 18.1624 25.3932 19.7168Z" fill="#1E5947"/>
                    </svg>
                  </div>
                  <Link to={RoutePaths.ReducingRisk}>
                    <p className='quiz-results-label'>
                      Reducing Risk of Complications 
                    </p>
                  </Link>
                </div>
              </>
            )}
            {stringAnswers.indexOf('qualityCare') !== -1 && (
              <>
                <div className="quiz-results-option">
                  <div className="icon-wrapper">
                    <svg width="46" height="68" viewBox="0 0 46 68" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <line x1="22.8848" y1="47.5513" x2="21.6967" y2="65.9359" stroke="#A8A9B3" strokeWidth="3" strokeLinecap="round" strokeDasharray="1 7"/>
                      <path d="M32.8995 19.7168C32.8995 14.0107 28.2674 9.37015 22.5726 9.37015C22.5698 9.37015 22.567 9.37015 22.5642 9.37015C22.5613 9.37015 22.5585 9.37015 22.5557 9.37015C16.861 9.37015 12.2289 14.0107 12.2289 19.7168C12.2289 21.6584 12.6386 23.1591 13.673 24.9395L21.7728 37.1684C21.9537 37.4425 22.2533 37.5895 22.5585 37.5895C22.5613 37.5895 22.5642 37.5895 22.567 37.5895C22.5698 37.5895 22.5726 37.5895 22.5755 37.5895C22.8807 37.5895 23.1803 37.4425 23.3612 37.1684L31.4892 24.8943C32.4953 23.1619 32.9051 21.6612 32.9051 19.7196L32.8995 19.7168ZM45.1453 23.4784C45.1453 35.9447 35.0389 46.0511 22.5726 46.0511C10.1064 46.0511 0 35.9447 0 23.4784C0 11.0122 10.1064 0.905762 22.5726 0.905762C35.0389 0.905762 45.1453 11.0122 45.1453 23.4784ZM27.2754 19.7168C27.2754 17.1252 25.1643 15.014 22.5726 15.014C19.981 15.014 17.8699 17.1252 17.8699 19.7168C17.8699 22.3084 19.981 24.4195 22.5726 24.4195C25.1643 24.4195 27.2754 22.3084 27.2754 19.7168ZM31.0173 19.7168C31.0173 21.3192 30.6922 22.5062 29.884 23.8995L22.5613 34.9583L15.267 23.9476C14.4304 22.5062 14.1054 21.322 14.1054 19.7168C14.1054 15.0479 17.8953 11.2524 22.55 11.2524C22.5529 11.2524 22.5557 11.2524 22.5585 11.2524C22.5613 11.2524 22.5642 11.2524 22.567 11.2524C27.2245 11.2524 31.0116 15.0508 31.0116 19.7168H31.0173ZM25.3932 19.7168C25.3932 21.274 24.127 22.5373 22.5726 22.5373C21.0183 22.5373 19.7521 21.2712 19.7521 19.7168C19.7521 18.1624 21.0183 16.8963 22.5726 16.8963C24.127 16.8963 25.3932 18.1624 25.3932 19.7168Z" fill="#1E5947"/>
                    </svg>
                  </div>
                  <Link to={RoutePaths.QualityCare}>
                    <p className='quiz-results-label'>
                      Receiving Quality Care
                    </p>
                  </Link>
                </div>
                <div className="quiz-results-option">
                  <div className="icon-wrapper">
                    <svg width="46" height="68" viewBox="0 0 46 68" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <line x1="22.8848" y1="47.5513" x2="21.6967" y2="65.9359" stroke="#A8A9B3" strokeWidth="3" strokeLinecap="round" strokeDasharray="1 7"/>
                      <path d="M32.8995 19.7168C32.8995 14.0107 28.2674 9.37015 22.5726 9.37015C22.5698 9.37015 22.567 9.37015 22.5642 9.37015C22.5613 9.37015 22.5585 9.37015 22.5557 9.37015C16.861 9.37015 12.2289 14.0107 12.2289 19.7168C12.2289 21.6584 12.6386 23.1591 13.673 24.9395L21.7728 37.1684C21.9537 37.4425 22.2533 37.5895 22.5585 37.5895C22.5613 37.5895 22.5642 37.5895 22.567 37.5895C22.5698 37.5895 22.5726 37.5895 22.5755 37.5895C22.8807 37.5895 23.1803 37.4425 23.3612 37.1684L31.4892 24.8943C32.4953 23.1619 32.9051 21.6612 32.9051 19.7196L32.8995 19.7168ZM45.1453 23.4784C45.1453 35.9447 35.0389 46.0511 22.5726 46.0511C10.1064 46.0511 0 35.9447 0 23.4784C0 11.0122 10.1064 0.905762 22.5726 0.905762C35.0389 0.905762 45.1453 11.0122 45.1453 23.4784ZM27.2754 19.7168C27.2754 17.1252 25.1643 15.014 22.5726 15.014C19.981 15.014 17.8699 17.1252 17.8699 19.7168C17.8699 22.3084 19.981 24.4195 22.5726 24.4195C25.1643 24.4195 27.2754 22.3084 27.2754 19.7168ZM31.0173 19.7168C31.0173 21.3192 30.6922 22.5062 29.884 23.8995L22.5613 34.9583L15.267 23.9476C14.4304 22.5062 14.1054 21.322 14.1054 19.7168C14.1054 15.0479 17.8953 11.2524 22.55 11.2524C22.5529 11.2524 22.5557 11.2524 22.5585 11.2524C22.5613 11.2524 22.5642 11.2524 22.567 11.2524C27.2245 11.2524 31.0116 15.0508 31.0116 19.7168H31.0173ZM25.3932 19.7168C25.3932 21.274 24.127 22.5373 22.5726 22.5373C21.0183 22.5373 19.7521 21.2712 19.7521 19.7168C19.7521 18.1624 21.0183 16.8963 22.5726 16.8963C24.127 16.8963 25.3932 18.1624 25.3932 19.7168Z" fill="#1E5947"/>
                    </svg>
                  </div>
                  <Link to={RoutePaths.FindingProvider}>
                    <p className='quiz-results-label'>
                      Finding the Right Provider
                    </p>
                  </Link>
                </div>
              </>
            )}
            {stringAnswers.indexOf('healthyEating') !== -1 && (
              <>
                <div className="quiz-results-option">
                  <div className="icon-wrapper">
                    <svg width="46" height="68" viewBox="0 0 46 68" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <line x1="22.8848" y1="47.5513" x2="21.6967" y2="65.9359" stroke="#A8A9B3" strokeWidth="3" strokeLinecap="round" strokeDasharray="1 7"/>
                      <path d="M32.8995 19.7168C32.8995 14.0107 28.2674 9.37015 22.5726 9.37015C22.5698 9.37015 22.567 9.37015 22.5642 9.37015C22.5613 9.37015 22.5585 9.37015 22.5557 9.37015C16.861 9.37015 12.2289 14.0107 12.2289 19.7168C12.2289 21.6584 12.6386 23.1591 13.673 24.9395L21.7728 37.1684C21.9537 37.4425 22.2533 37.5895 22.5585 37.5895C22.5613 37.5895 22.5642 37.5895 22.567 37.5895C22.5698 37.5895 22.5726 37.5895 22.5755 37.5895C22.8807 37.5895 23.1803 37.4425 23.3612 37.1684L31.4892 24.8943C32.4953 23.1619 32.9051 21.6612 32.9051 19.7196L32.8995 19.7168ZM45.1453 23.4784C45.1453 35.9447 35.0389 46.0511 22.5726 46.0511C10.1064 46.0511 0 35.9447 0 23.4784C0 11.0122 10.1064 0.905762 22.5726 0.905762C35.0389 0.905762 45.1453 11.0122 45.1453 23.4784ZM27.2754 19.7168C27.2754 17.1252 25.1643 15.014 22.5726 15.014C19.981 15.014 17.8699 17.1252 17.8699 19.7168C17.8699 22.3084 19.981 24.4195 22.5726 24.4195C25.1643 24.4195 27.2754 22.3084 27.2754 19.7168ZM31.0173 19.7168C31.0173 21.3192 30.6922 22.5062 29.884 23.8995L22.5613 34.9583L15.267 23.9476C14.4304 22.5062 14.1054 21.322 14.1054 19.7168C14.1054 15.0479 17.8953 11.2524 22.55 11.2524C22.5529 11.2524 22.5557 11.2524 22.5585 11.2524C22.5613 11.2524 22.5642 11.2524 22.567 11.2524C27.2245 11.2524 31.0116 15.0508 31.0116 19.7168H31.0173ZM25.3932 19.7168C25.3932 21.274 24.127 22.5373 22.5726 22.5373C21.0183 22.5373 19.7521 21.2712 19.7521 19.7168C19.7521 18.1624 21.0183 16.8963 22.5726 16.8963C24.127 16.8963 25.3932 18.1624 25.3932 19.7168Z" fill="#1E5947"/>
                    </svg>
                  </div>
                  <Link to={RoutePaths.HealthyEating}>
                    <p className='quiz-results-label'>
                      Creative Eating and Cooking 
                    </p>
                  </Link>
                </div>
                <div className="quiz-results-option">
                  <div className="icon-wrapper">
                    <svg width="46" height="68" viewBox="0 0 46 68" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <line x1="22.8848" y1="47.5513" x2="21.6967" y2="65.9359" stroke="#A8A9B3" strokeWidth="3" strokeLinecap="round" strokeDasharray="1 7"/>
                      <path d="M32.8995 19.7168C32.8995 14.0107 28.2674 9.37015 22.5726 9.37015C22.5698 9.37015 22.567 9.37015 22.5642 9.37015C22.5613 9.37015 22.5585 9.37015 22.5557 9.37015C16.861 9.37015 12.2289 14.0107 12.2289 19.7168C12.2289 21.6584 12.6386 23.1591 13.673 24.9395L21.7728 37.1684C21.9537 37.4425 22.2533 37.5895 22.5585 37.5895C22.5613 37.5895 22.5642 37.5895 22.567 37.5895C22.5698 37.5895 22.5726 37.5895 22.5755 37.5895C22.8807 37.5895 23.1803 37.4425 23.3612 37.1684L31.4892 24.8943C32.4953 23.1619 32.9051 21.6612 32.9051 19.7196L32.8995 19.7168ZM45.1453 23.4784C45.1453 35.9447 35.0389 46.0511 22.5726 46.0511C10.1064 46.0511 0 35.9447 0 23.4784C0 11.0122 10.1064 0.905762 22.5726 0.905762C35.0389 0.905762 45.1453 11.0122 45.1453 23.4784ZM27.2754 19.7168C27.2754 17.1252 25.1643 15.014 22.5726 15.014C19.981 15.014 17.8699 17.1252 17.8699 19.7168C17.8699 22.3084 19.981 24.4195 22.5726 24.4195C25.1643 24.4195 27.2754 22.3084 27.2754 19.7168ZM31.0173 19.7168C31.0173 21.3192 30.6922 22.5062 29.884 23.8995L22.5613 34.9583L15.267 23.9476C14.4304 22.5062 14.1054 21.322 14.1054 19.7168C14.1054 15.0479 17.8953 11.2524 22.55 11.2524C22.5529 11.2524 22.5557 11.2524 22.5585 11.2524C22.5613 11.2524 22.5642 11.2524 22.567 11.2524C27.2245 11.2524 31.0116 15.0508 31.0116 19.7168H31.0173ZM25.3932 19.7168C25.3932 21.274 24.127 22.5373 22.5726 22.5373C21.0183 22.5373 19.7521 21.2712 19.7521 19.7168C19.7521 18.1624 21.0183 16.8963 22.5726 16.8963C24.127 16.8963 25.3932 18.1624 25.3932 19.7168Z" fill="#1E5947"/>
                    </svg>
                  </div>
                  <Link to={RoutePaths.PhysicalActivity}>
                    <p className='quiz-results-label'>
                      Increasing Physical Activity
                    </p>
                  </Link>
                </div>
              </>
            )}

            {stringAnswers.indexOf('coping') !== -1 && (
              <>
                <div className="quiz-results-option">
                  <div className="icon-wrapper">
                    <svg width="46" height="68" viewBox="0 0 46 68" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <line x1="22.8848" y1="47.5513" x2="21.6967" y2="65.9359" stroke="#A8A9B3" strokeWidth="3" strokeLinecap="round" strokeDasharray="1 7"/>
                      <path d="M32.8995 19.7168C32.8995 14.0107 28.2674 9.37015 22.5726 9.37015C22.5698 9.37015 22.567 9.37015 22.5642 9.37015C22.5613 9.37015 22.5585 9.37015 22.5557 9.37015C16.861 9.37015 12.2289 14.0107 12.2289 19.7168C12.2289 21.6584 12.6386 23.1591 13.673 24.9395L21.7728 37.1684C21.9537 37.4425 22.2533 37.5895 22.5585 37.5895C22.5613 37.5895 22.5642 37.5895 22.567 37.5895C22.5698 37.5895 22.5726 37.5895 22.5755 37.5895C22.8807 37.5895 23.1803 37.4425 23.3612 37.1684L31.4892 24.8943C32.4953 23.1619 32.9051 21.6612 32.9051 19.7196L32.8995 19.7168ZM45.1453 23.4784C45.1453 35.9447 35.0389 46.0511 22.5726 46.0511C10.1064 46.0511 0 35.9447 0 23.4784C0 11.0122 10.1064 0.905762 22.5726 0.905762C35.0389 0.905762 45.1453 11.0122 45.1453 23.4784ZM27.2754 19.7168C27.2754 17.1252 25.1643 15.014 22.5726 15.014C19.981 15.014 17.8699 17.1252 17.8699 19.7168C17.8699 22.3084 19.981 24.4195 22.5726 24.4195C25.1643 24.4195 27.2754 22.3084 27.2754 19.7168ZM31.0173 19.7168C31.0173 21.3192 30.6922 22.5062 29.884 23.8995L22.5613 34.9583L15.267 23.9476C14.4304 22.5062 14.1054 21.322 14.1054 19.7168C14.1054 15.0479 17.8953 11.2524 22.55 11.2524C22.5529 11.2524 22.5557 11.2524 22.5585 11.2524C22.5613 11.2524 22.5642 11.2524 22.567 11.2524C27.2245 11.2524 31.0116 15.0508 31.0116 19.7168H31.0173ZM25.3932 19.7168C25.3932 21.274 24.127 22.5373 22.5726 22.5373C21.0183 22.5373 19.7521 21.2712 19.7521 19.7168C19.7521 18.1624 21.0183 16.8963 22.5726 16.8963C24.127 16.8963 25.3932 18.1624 25.3932 19.7168Z" fill="#1E5947"/>
                    </svg>
                  </div>
                  <Link to={RoutePaths.CopingDiabetes}>
                    <p className='quiz-results-label'>
                      Living with Diabetes
                    </p>
                  </Link>
                </div>
              </>
            )}
            {stringAnswers.indexOf('community') !== -1 && (
              <>
                <div className="quiz-results-option">
                  <div className="icon-wrapper">
                    <svg width="46" height="68" viewBox="0 0 46 68" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <line x1="22.8848" y1="47.5513" x2="21.6967" y2="65.9359" stroke="#A8A9B3" strokeWidth="3" strokeLinecap="round" strokeDasharray="1 7"/>
                      <path d="M32.8995 19.7168C32.8995 14.0107 28.2674 9.37015 22.5726 9.37015C22.5698 9.37015 22.567 9.37015 22.5642 9.37015C22.5613 9.37015 22.5585 9.37015 22.5557 9.37015C16.861 9.37015 12.2289 14.0107 12.2289 19.7168C12.2289 21.6584 12.6386 23.1591 13.673 24.9395L21.7728 37.1684C21.9537 37.4425 22.2533 37.5895 22.5585 37.5895C22.5613 37.5895 22.5642 37.5895 22.567 37.5895C22.5698 37.5895 22.5726 37.5895 22.5755 37.5895C22.8807 37.5895 23.1803 37.4425 23.3612 37.1684L31.4892 24.8943C32.4953 23.1619 32.9051 21.6612 32.9051 19.7196L32.8995 19.7168ZM45.1453 23.4784C45.1453 35.9447 35.0389 46.0511 22.5726 46.0511C10.1064 46.0511 0 35.9447 0 23.4784C0 11.0122 10.1064 0.905762 22.5726 0.905762C35.0389 0.905762 45.1453 11.0122 45.1453 23.4784ZM27.2754 19.7168C27.2754 17.1252 25.1643 15.014 22.5726 15.014C19.981 15.014 17.8699 17.1252 17.8699 19.7168C17.8699 22.3084 19.981 24.4195 22.5726 24.4195C25.1643 24.4195 27.2754 22.3084 27.2754 19.7168ZM31.0173 19.7168C31.0173 21.3192 30.6922 22.5062 29.884 23.8995L22.5613 34.9583L15.267 23.9476C14.4304 22.5062 14.1054 21.322 14.1054 19.7168C14.1054 15.0479 17.8953 11.2524 22.55 11.2524C22.5529 11.2524 22.5557 11.2524 22.5585 11.2524C22.5613 11.2524 22.5642 11.2524 22.567 11.2524C27.2245 11.2524 31.0116 15.0508 31.0116 19.7168H31.0173ZM25.3932 19.7168C25.3932 21.274 24.127 22.5373 22.5726 22.5373C21.0183 22.5373 19.7521 21.2712 19.7521 19.7168C19.7521 18.1624 21.0183 16.8963 22.5726 16.8963C24.127 16.8963 25.3932 18.1624 25.3932 19.7168Z" fill="#1E5947"/>
                    </svg>
                  </div>
                  <Link to={RoutePaths.CreatingCommunity}>
                    <p className='quiz-results-label'>
                      Creating Community
                    </p>
                  </Link>
                </div>
              </>
            )}
            
            <div className="quiz-results-option">
              <div className="icon-wrapper">
                <svg className='last-icon' width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M32.8995 19.3325C32.8995 13.6265 28.2674 8.98587 22.5726 8.98587C22.5698 8.98587 22.567 8.98587 22.5642 8.98587C22.5613 8.98587 22.5585 8.98587 22.5557 8.98587C16.861 8.98587 12.2289 13.6265 12.2289 19.3325C12.2289 21.2741 12.6386 22.7748 13.673 24.5553L21.7728 36.7841C21.9537 37.0583 22.2533 37.2052 22.5585 37.2052C22.5613 37.2052 22.5642 37.2052 22.567 37.2052C22.5698 37.2052 22.5726 37.2052 22.5755 37.2052C22.8807 37.2052 23.1803 37.0583 23.3612 36.7841L31.4892 24.51C32.4953 22.7776 32.9051 21.2769 32.9051 19.3353L32.8995 19.3325ZM45.1453 23.0941C45.1453 35.5604 35.0389 45.6668 22.5726 45.6668C10.1064 45.6668 0 35.5604 0 23.0941C0 10.6279 10.1064 0.521484 22.5726 0.521484C35.0389 0.521484 45.1453 10.6279 45.1453 23.0941ZM27.2754 19.3325C27.2754 16.7409 25.1643 14.6297 22.5726 14.6297C19.981 14.6297 17.8699 16.7409 17.8699 19.3325C17.8699 21.9241 19.981 24.0352 22.5726 24.0352C25.1643 24.0352 27.2754 21.9241 27.2754 19.3325ZM31.0173 19.3325C31.0173 20.9349 30.6922 22.1219 29.884 23.5152L22.5613 34.5741L15.267 23.5633C14.4304 22.1219 14.1054 20.9378 14.1054 19.3325C14.1054 14.6637 17.8953 10.8681 22.55 10.8681C22.5529 10.8681 22.5557 10.8681 22.5585 10.8681C22.5613 10.8681 22.5642 10.8681 22.567 10.8681C27.2245 10.8681 31.0116 14.6665 31.0116 19.3325H31.0173ZM25.3932 19.3325C25.3932 20.8897 24.127 22.153 22.5726 22.153C21.0183 22.153 19.7521 20.8869 19.7521 19.3325C19.7521 17.7781 21.0183 16.512 22.5726 16.512C24.127 16.512 25.3932 17.7781 25.3932 19.3325Z" fill="#1E5947"/>
                </svg>
              </div>
              <Link to={RoutePaths.ConnectingToDSMES}>
                <p className='quiz-results-label'>
                Connecting to Diabetes Self-Management Education and Support (DSMES) 
                </p>
              </Link>
            </div>
          </div>
          <p>Remember, you can always check out the other <a href={RoutePaths.HomeSupportTopics}> Diabetes Support topics</a></p>
          <button onClick={handleRouteToTakeTheQuiz} className='btn-redo-learning-guide'>Rebuild Your Learning Guide</button>
        </div>
        <Print />
      </div>
    </>
  )
}

export default QuizResult
