import { useId } from "react";
import { ExternalLink } from "../../../ExternalLink/ExternalLink";
import bodyRefuelHeadImg from "../../../../assets/images/copingDiabetes/body-refuel-head.png";
import headerUnderline from "../../../../assets/images/modal-header-underline.png";
import styles from "./SelfCareModals.scss";

export const SelfCareModalContent03 = () => {
  const id = useId();
  return (
    <>
      <div id="modalTitle-3" className="modal-title">
        <h2 className="display-h2">BODY-Refuel</h2>
        <img
          className="headerUnderline"
          src={headerUnderline}
          alt="decorative underline"
        />
      </div>
      <div id="modalContent-3" className="modal-content">
        <img
          className="headImg"
          src={bodyRefuelHeadImg}
          alt="woman holding up a grapefruit"
        />
        <p>
          Did you know that poor nutrition and unhealthy eating habits can lower
          your mood by spiking your blood sugar? That’s right,{" "}
          <a
            target="_blank"
            rel="noreferrer noopener"
            class="org-button xyz"
            href="https://www.cdc.gov/diabetes/library/features/family-friends-diabetes.html"
          >
            changes in blood sugar levels can transform your feelings of
            happiness into irritation and sadness
          </a>
          , increasing your risk of depression and anxiety. A balanced diet of
          healthy meals and staying hydrated can improve your energy and focus
          throughout the day. For more ideas on how to care for your body with
          better nutrition,{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="/healthy-eating-cooking"
          >
            check out the Creative Eating and Cooking module
          </a>{" "}
          for meal planning ideas.
        </p>
      </div>
    </>
  );
};
