import * as React from "react";
import { CopingDiabetesTips } from "./components/CopingDiabetesTips";
import StressOption from "./components/StressOption/StressOption";
import SupportTopics from "../SupportTopics/SupportTopics";
import image1 from "../../assets/images/copingDiabetes/copingDiabetes1.jpeg";
import image2 from "../../assets/images/copingDiabetes/copingDiabetes2.jpeg";
import compass from "../../assets/images/icon-compass.png";
import quote from "../../assets/images/copingDiabetes/copingDiabetesQuote.jpeg";
import download from "../../assets/images/copingDiabetes/download.svg";
import image11 from "../../assets/images/circle-woman-glasses-01.png";
import tile1 from "../../assets/images/diamond-anger.png";
import tile1b from "../../assets/images/diamond-anger-flip.png";
import tile2 from "../../assets/images/diamond-aches.png";
import tile2b from "../../assets/images/diamond-aches-flip.png";
import tile3 from "../../assets/images/diamond-concentrate.png";
import tile3b from "../../assets/images/diamond-concentrate-flip.png";
import tile4 from "../../assets/images/diamond-sleep.png";
import tile4b from "../../assets/images/diamond-sleep-flip.png";
import tile5 from "../../assets/images/diamond-eat.png";
import tile5b from "../../assets/images/diamond-eat-flip.png";
import infographicLiving from "../../assets/Diabetes-Mental-Health-Stress.pdf";
import infographicLiving2 from "../../assets/Living-w-Diabetes-Affirmations.pdf";
import livingInfoAffirmations from "../../assets/images/copingDiabetes/Living2.png";
import livingInfoStress from "../../assets/images/copingDiabetes/Living1.png";
import { ExternalLink } from "../ExternalLink/ExternalLink";
import { VideoLink } from "../VideoLink/VideoLink";
import swoosh from "../../assets/images/pinkSwoosh.svg";
import MultipleModals from "./components/SelfCareModalsOLD/SelfCareModals";
import SelfCareModals from "./components/SelfCareModals/SelfCareModals";
import FindSisterhood from "../FindSisterhood/FindSisterhood";
import ConnectToSpecialist from "../ConnectToSpecialist/ConnectToSpecialist";
import "./CopingDiabetes.scss";

export default function CopingDiabetes() {
  const [flipTile1, setTile1] = React.useState(false);
  const [flipTile2, setTile2] = React.useState(false);
  const [flipTile3, setTile3] = React.useState(false);
  const [flipTile4, setTile4] = React.useState(false);
  const [flipTile5, setTile5] = React.useState(false);

  return (
    <>
      <div className="coping-diabetes-page">
        <div className="gradient-bg-container">
          {/* <div className="cd-banner"> */}
          <div className="cd-banner-container main-banner">
            <VideoLink url="https://www.youtube.com/watch?v=_99imyY8g0g" />
            <h1 className="display-h1">
              <img
                role="presentation"
                className="compassImg"
                src={compass}
                alt="compass icon"
              />
              <span className="red-text">Living</span>{" "}
              <span className="purple-text">with Diabetes</span>
            </h1>
            <div className="innerFlex">
              <div className="cd-banner-text">
                <p>
                  In life, we all experience stress that can come from many
                  different places, including income challenges, workplace
                  difficulties, and family responsibilities. But Black women are
                  uniquely impacted by racism and sexism, which can take an
                  extra toll on their mental health. Adding a health condition
                  like diabetes on top of regular and discrimination-based
                  stressors can create a huge mental burden. Chronic or
                  reoccurring stress can complicate diabetes by increasing your
                  blood sugar levels, which affects your blood pressure and
                  overall heart health.{" "}
                  <ExternalLink
                    tabIndex="4"
                    url="https://www.diabeteseducator.org/docs/default-source/living-with-diabetes/tip-sheets/healthy-coping/healthy-coping-patient-flyer-v-1-(002).pdf?sfvrsn=66036959_0"
                    cname="org-button"
                    aLabel="Experiencing constant stress can also hurt your mental health"
                  >
                    Experiencing constant stress can also hurt your mental
                    health by making stress your default state of being
                  </ExternalLink>
                  . And if you’re always stressed, it can be harder to notice
                  when stressors have reached dangerous levels. Adjusting to the
                  ups and downs of having diabetes can also{" "}
                  <a
                    tabIndex="5"
                    href="https://www.cdc.gov/diabetes/managing/mental-health.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    cause overwhelming feelings of distress that can look like
                    other mental health problems, such as depression or anxiety
                  </a>
                  . An important part of managing diabetes is understanding how
                  all of your stressors, diabetes-related and otherwise, can
                  result in different symptoms and mental health conditions.{" "}
                </p>
              </div>
              <div className="cd-banner-image">
                <div className="img-wrapper">
                  <img
                    role="presentation"
                    className="cd-banner-img"
                    src={image1}
                    alt="woman checking her blood sugar"
                  />
                </div>
              </div>
            </div>
          </div>
          {/* </div> */}
          <div className="cd-testimonial-section">
            <div className="quote-wrapper">
              <img
                role="presentation"
                className="cd-testimonial-img"
                src={quote}
                alt="testimonial"
              />
              <div className="quote">
                <p>
                  <i>
                    Sometimes you have to just pause and find your peace by
                    inhaling the good and exhaling the bad.
                  </i>
                </p>
              </div>
            </div>
          </div>

          {/* <CopingDiabetesTips /> */}
          <div className="section-pink-swoosh">
            <div className="fusion-section-separator-svg fusion-section-separator-fullwidth">
              <img src={swoosh} alt="decorative background image" />
            </div>
            <div className="fusion-section-separator-spacer fusion-section-separator-fullwidth">
              <div className="fusion-section-separator-spacer-height"></div>
            </div>

            <div className="section-what-is-anxiety">
              <div className="cd-banner">
                <div className="cd-banner-container">
                  <div className="stress">
                    <h2>
                      What Is Stress?
                      <img
                        src={image11}
                        alt="woman showing signs of stress looking out a window."
                      />
                    </h2>

                    <p>
                      Stress is a physical and emotional reaction that people
                      experience as they encounter challenges in life. A
                      stressor may be a one-time or short-term occurrence, or it
                      can happen repeatedly over a long time (chronic stress).{" "}
                    </p>
                    <div className="tileWrapper">
                      <h2>What Stress Looks like for you</h2>
                      <p>
                        It’s important to know what normal stress looks like for
                        you. Read the boxes below to learn signs of stress.
                        Click on the signs you experience and get a tip on how
                        to respond.
                      </p>
                      <div className="rotatedBox">
                        <div className="tileFlex">
                          <div className="tile filler"></div>
                          <div className="tile">
                            <img
                              tabIndex="7"
                              title="Problems Sleeping. Limit screen time before bed including phones, TV, and tablets."
                              alt="Problems Sleeping. Limit screen time before bed including phones, TV, and tablets."
                              onClick={() => setTile4((prevMode) => !prevMode)}
                              src={flipTile4 ? tile4b : tile4}
                            />
                          </div>
                          <div className="tile filler"></div>
                        </div>
                        <div className="tileFlex">
                          <div className="tile">
                            <img
                              tabIndex="6"
                              title="Feeling Anger, Anxiety, or Frustration. Take a break to unwinde. Take several breaths to release negative emotions or go on a walk to release some stress."
                              alt="Feeling Anger, Anxiety, or Frustration. Take a break to unwinde. Take several breaths to release negative emotions or go on a walk to release some stress."
                              onClick={() => setTile1((prevMode) => !prevMode)}
                              src={flipTile1 ? tile1b : tile1}
                            />
                          </div>
                          <div className="tile">
                            <img
                              tabIndex="8"
                              title="Difficulting Concentrating. Focus on getting enough sleep. Sleep is key to concentration."
                              alt="Difficulting Concentrating. Focus on getting enough sleep. Sleep is key to concentration."
                              onClick={() => setTile3((prevMode) => !prevMode)}
                              src={flipTile3 ? tile3b : tile3}
                            />
                          </div>
                          <div className="tile">
                            <img
                              tabIndex="10"
                              title="Headaches or Other Body Pains. Take a long hot bath to relieve your muscles."
                              alt="Headaches or Other Body Pains. Take a long hot bath to relieve your muscles."
                              onClick={() => setTile5((prevMode) => !prevMode)}
                              src={flipTile5 ? tile5b : tile5}
                            />
                          </div>
                        </div>
                        <div className="tileFlex">
                          <div className="tile filler"> </div>
                          <div className="tile">
                            <img
                              tabIndex="9"
                              title="Eating Too Much or Too Little. Talk to a diabetes care and education specialist about creating a healthy meal plan."
                              alt="Eating Too Much or Too Little. Talk to a diabetes care and education specialist about creating a healthy meal plan."
                              onClick={() => setTile2((prevMode) => !prevMode)}
                              src={flipTile2 ? tile2b : tile2}
                            />
                          </div>
                          <div className="tile filler"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="cd-ss-section">
            <h2 className="display-h2">is it stress or something else?</h2>
            <div className="cd-banner-container">
              <div className="innerFlex">
                <div className="cd-banner-text">
                  <p>
                    Experiencing diabetes (a chronic condition) alongside
                    chronic stress can show up via other mental health symptoms
                    and conditions. Sometimes chronic stress can be a symptom of
                    something more.{" "}
                  </p>
                </div>

                <div className="cd-banner-image">
                  <div className="img-wrapper">
                    <img
                      role="presentation"
                      src={image2}
                      className="cd-banner-img"
                      alt="two day"
                    />
                  </div>
                </div>
              </div>
              <div className="section-stressOption">
                <p className="center">
                  <strong>
                    Click the options below to see some common mental health
                    outcomes associated with stress and diabetes, including
                    anxiety, depression, and diabetes burnout.
                  </strong>
                </p>
                <StressOption />
              </div>
            </div>

            <div className="cd-testimonial-section infoPDFFlex">
              <img
                src={livingInfoStress}
                alt="Anxiety, Depression, Diabetes Burnout chart"
              />
              <p>
                Find this resource useful?{" "}
                <a tabIndex="17" href={infographicLiving} target="_blank">
                  Download a copy of the chart
                </a>{" "}
                to make sure you’re aware of the signs and symptoms if you ever
                start to feel like you’re experiencing more than normal stress.{" "}
              </p>
            </div>
          </div>

          <div className="cd-selfCare-section">
            <div className="cd-banner">
              <div className="cd-banner-container">
                <h2 className="display-h2">SELF-CARE</h2>
                <p>
                  Hopefully the chart above can help you identify and understand
                  any stress-related symptoms you may be experiencing. When it
                  comes to both diabetes and your mental health, self-care can
                  be a helpful way to manage stress, lower your risk of illness,
                  and increase your energy.{" "}
                  <a
                    tabIndex="18"
                    href="https://www.nimh.nih.gov/health/topics/caring-for-your-mental-health"
                    target="_blank"
                  >
                    Self-care
                  </a>{" "}
                  is all about taking the time to do things that help you live
                  well and improve both your physical health and mental health.
                  Even small acts of self-care in your daily routine can have a
                  big impact.
                </p>
                <p>
                  <strong>
                    Click the topic areas below to learn more about how you can
                    practice self-care as part of your diabetes care plan.
                  </strong>
                </p>
                {/*<MultipleModals/>*/}
                <SelfCareModals />

                <div className="cd-testimonial-section infoPDFFlex">
                  <img
                    src={livingInfoAffirmations}
                    alt="download self-care affirmations"
                  />

                  <a tabIndex="23" href={infographicLiving2} target="_blank">
                    <p>Download Self-Care Affirmations</p>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="hideExternal">
          <FindSisterhood topic="living" />
        </div>
        <div id="supportTopics" className="SupportTopics">
          <SupportTopics />
        </div>
        <ConnectToSpecialist />
      </div>
    </>
  );
}
