import { useState } from "react";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import tipBgImage01 from "../../assets/images/IncreasingPhysicalActivity/pa-card-img-01.jpg";
import tipBgImage02 from "../../assets/images/IncreasingPhysicalActivity/pa-card-img-02.jpg";
import tipBgImage03 from "../../assets/images/IncreasingPhysicalActivity/pa-card-img-03.jpg";
import tipBgImage04 from "../../assets/images/IncreasingPhysicalActivity/pa-card-img-04.jpeg";
import tipBgImage05 from "../../assets/images/IncreasingPhysicalActivity/pa-card-img-05.jpg";
import tipBgImage06 from "../../assets/images/IncreasingPhysicalActivity/pa-card-img-06.jpg";
import tipBgImage07 from "../../assets/images/IncreasingPhysicalActivity/pa-card-img-07.jpg";
import tipModalHeaderImage01 from "../../assets/images/IncreasingPhysicalActivity/pa-modal-img-01.jpg";
import tipModalHeaderImage02 from "../../assets/images/IncreasingPhysicalActivity/pa-modal-img-02.jpg";
import tipModalHeaderImage03 from "../../assets/images/IncreasingPhysicalActivity/pa-modal-img-03.jpg";
import tipModalHeaderImage05 from "../../assets/images/IncreasingPhysicalActivity/pa-modal-img-05.jpg";
import tipModalHeaderImage06 from "../../assets/images/IncreasingPhysicalActivity/pa-modal-img-06.jpg";
import tipModalHeaderImage07 from "../../assets/images/IncreasingPhysicalActivity/pa-modal-img-07.jpg";
import icon01 from "../../assets/images/IncreasingPhysicalActivity/pa-icon-01.svg";
import icon02 from "../../assets/images/IncreasingPhysicalActivity/pa-icon-02.svg";
import icon03 from "../../assets/images/IncreasingPhysicalActivity/pa-icon-03.png";
import icon04 from "../../assets/images/IncreasingPhysicalActivity/pa-icon-04.png";
import icon05 from "../../assets/images/IncreasingPhysicalActivity/pa-icon-05.svg";
import { PhysicalActivityModalContent01 } from "./components/PhysicalActivityModalContent01";
import { PhysicalActivityModalContent05 } from "./components/PhysicalActivityModalContent05";
import { ExternalLink } from "../ExternalLink/ExternalLink";

export const PhysicalActivityTips = () => {
  const [tipModels, setTipModels] = useState({
    tipModelOne: false,
    tipModelTwo: false,
    tipModelThree: false,
    tipModelFour: false,
    tipModelFive: false,
    tipModelSix: false,
    tipModelSeven: false,
  });
  const getTipModelHandler = (tipModelName) => {
    return {
      isOpen: tipModels[tipModelName],
      open: () => setTipModels((state) => ({ ...state, [tipModelName]: true })),
      close: () =>
        setTipModels((state) => ({ ...state, [tipModelName]: false })),
    };
  };

  const tipModelOne = getTipModelHandler("tipModelOne");
  const tipModelTwo = getTipModelHandler("tipModelTwo");
  const tipModelThree = getTipModelHandler("tipModelThree");
  const tipModelFour = getTipModelHandler("tipModelFour");
  const tipModelFive = getTipModelHandler("tipModelFive");
  const tipModelSix = getTipModelHandler("tipModelSix");
  const tipModelSeven = getTipModelHandler("tipModelSeven");

  const nextModal = (current, next) => {
    current.close();
    next.open();
  };
  return (
    <section className="pa-tips-section">
      <div className="pa-tips-flex-wrapper">
        <div className="tip-button-wrapper">
          <button
            style={{ backgroundImage: `url(${tipBgImage01})` }}
            className="tip"
            onClick={tipModelOne.open}
          >
            <h2>What Being Physically Active Means</h2>
          </button>
        </div>
        <div className="tip-button-wrapper">
          <button
            style={{ backgroundImage: `url(${tipBgImage02})` }}
            className="tip"
            onClick={tipModelTwo.open}
          >
            <h2>How You Benefit from Getting Active?</h2>
          </button>
        </div>
        <div className="tip-button-wrapper">
          <button
            style={{ backgroundImage: `url(${tipBgImage03})` }}
            className="tip"
            onClick={tipModelThree.open}
          >
            <h2>Getting Started Safely </h2>
          </button>
        </div>
        <div className="tip-button-wrapper">
          <button
            style={{ backgroundImage: `url(${tipBgImage04})` }}
            className="tip"
            onClick={tipModelFour.open}
          >
            <h2>Hair Care and Workouts</h2>
          </button>
        </div>
        <div className="tip-button-wrapper">
          <button
            style={{ backgroundImage: `url(${tipBgImage05})` }}
            className="tip"
            onClick={tipModelFive.open}
          >
            <h2>Ways to Include Physical Activity into Your Busy Schedule</h2>
          </button>
        </div>
        <div className="tip-button-wrapper">
          <button
            style={{ backgroundImage: `url(${tipBgImage06})` }}
            className="tip"
            onClick={tipModelSix.open}
          >
            <h2>Putting the Fun in Fitness</h2>
          </button>
        </div>
        <div className="tip-button-wrapper">
          <button
            style={{ backgroundImage: `url(${tipBgImage07})` }}
            className="tip"
            onClick={tipModelSeven.open}
          >
            <h2>Motivation and Consistency Are Key</h2>
          </button>
        </div>
      </div>
      <div className="dialog-wrapper">
        <Dialog
          aria-label="What Being Physically Active Means"
          role="dialog"
          open={tipModelOne.isOpen}
          onClose={tipModelOne.close}
          className="dsmes-modal pa-modal modal-theme-cyan"
        >
          <button
            className="modal-close-btn"
            onClick={tipModelOne.close}
            aria-label="Close"
          >
            <CloseIcon className="modal-close-icon" />
          </button>
          <div
            className="modal-header bcPhys"
            style={{ backgroundImage: `url(${tipModalHeaderImage01})` }}
          >
            &nbsp;
          </div>
          <div id="modalTitle-1" className="modal-title">
            <h3 tabIndex={0} className="strong">
              What Being Physically Active Means
            </h3>
          </div>
          <div tabIndex={0} id="modalContent-1" className="modal-content">
            <PhysicalActivityModalContent01 />
          </div>
          <div className="modal-footer">
            <button
              onClick={() => nextModal(tipModelOne, tipModelTwo)}
              className="next"
              aria-label="Next"
            >
              <svg
                width="17"
                height="28"
                viewBox="0 0 17 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2 26L14 14L2 2"
                  stroke="white"
                  strokeWidth="3"
                  strokeLinecap="round"
                />
              </svg>
            </button>
          </div>
        </Dialog>
        <Dialog
          role="dialog"
          open={tipModelTwo.isOpen}
          onClose={tipModelTwo.close}
          className="dsmes-modal pa-modal modal-theme-cyan"
          aria-label="How You Benefit from Getting Active?"
        >
          <button
            className="modal-close-btn"
            onClick={tipModelTwo.close}
            aria-label="Close"
          >
            <CloseIcon className="modal-close-icon" />
          </button>
          <div
            className="modal-header bcPhys"
            style={{ backgroundImage: `url(${tipModalHeaderImage02})` }}
          >
            &nbsp;
          </div>
          <div id="modalTitle-2" className="modal-title">
            <h3 tabIndex={0} className="strong">
              How You Benefit from Getting Active?
            </h3>
          </div>
          <div tabIndex={0} id="modalContent-2" className="modal-content">
            <p>
              Being physically active makes your body respond better to insulin.
              Increasing your daily movement can also benefit you in other ways,
              including helping you:{" "}
            </p>
            <ul>
              <li>Reduce stress and anxiety</li>
              <li>Feel happier</li>
              <li>Have more energy</li>
              <li>Sleep better</li>
              <li>Improve your memory</li>
              <li>Manage your blood pressure and cholesterol levels</li>
            </ul>
          </div>
          <div className="modal-footer">
            <button
              onClick={() => nextModal(tipModelTwo, tipModelOne)}
              className="prev"
              aria-label="Previous"
            >
              <svg
                width="17"
                height="28"
                viewBox="0 0 17 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15 2L3 14L15 26"
                  stroke="white"
                  strokeWidth="3"
                  strokeLinecap="round"
                />
              </svg>
            </button>
            <button
              onClick={() => nextModal(tipModelTwo, tipModelThree)}
              className="next"
              aria-label="Next"
            >
              <svg
                width="17"
                height="28"
                viewBox="0 0 17 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2 26L14 14L2 2"
                  stroke="white"
                  strokeWidth="3"
                  strokeLinecap="round"
                />
              </svg>
            </button>
          </div>
        </Dialog>
        <Dialog
          role="dialog"
          open={tipModelThree.isOpen}
          onClose={tipModelThree.close}
          className="dsmes-modal pa-modal modal-theme-cyan"
          aria-label="Getting Started Safely"
        >
          <button
            className="modal-close-btn"
            onClick={tipModelThree.close}
            aria-label="Close"
          >
            <CloseIcon className="modal-close-icon" />
          </button>
          <div
            className="modal-header bcPhys mt1"
            style={{
              backgroundImage: `url(${tipModalHeaderImage03})`,
              backgroundPosition: `center 0`,
            }}
          >
            &nbsp;
          </div>
          <div id="modalTitle-3" className="modal-title">
            <h3 tabIndex={0} className="strong">
              Getting Started Safely{" "}
            </h3>
          </div>
          <div tabIndex={0} id="modalContent-3" className="modal-content">
            <p>
              Always check with your health-care provider before starting any
              physical activity. They can guide you through the safest physical
              activities for you. Below are some tips to make sure you’re safe
              while exercising.
            </p>
            <div className="flex-row">
              <img src={icon01} alt="" />
              <p>
                Wear cotton socks and athletic shoes that fit well and are
                comfortable. Never go barefoot.{" "}
              </p>
            </div>
            <div className="flex-row">
              <img src={icon02} alt="" />
              <p>Drink plenty of water. </p>
            </div>
            <div className="flex-row">
              <img src={icon03} alt="" />
              <p>Check your blood sugar, especially if you take insulin.</p>
            </div>
            <div className="flex-row">
              <ul>
                <li>
                  If it’s below 100 mg/dL, eat a small snack like raisins or
                  fruit, so your blood sugar doesn’t fall too low.
                </li>
                <li>
                  If it’s above 240 mg/dL, your blood sugar may be too high to
                  be active safely.
                </li>
              </ul>
            </div>
            <div className="flex-row">
              <img src={icon04} alt="" />
              <p>
                If you’re using insulin or taking any medication that can cause
                low blood sugar (hypoglycemia):{" "}
              </p>
            </div>
            <div className="flex-row">
              <ul>
                <li>
                  Don’t exercise alone. As a bonus, working out with a partner
                  can be more fun.
                </li>
                <li>
                  Carry rapid-acting carbs like glucose tablets, juice, or hard
                  candy.
                </li>
                <li>
                  Don’t inject insulin into a body part that you will use to
                  exercise. For example, don’t inject insulin into your thigh if
                  you will walk or run.
                </li>
              </ul>
            </div>
            <div className="flex-row">
              <img src={icon05} alt="" />
              <p>
                After your activity, check how it has affected your blood sugar
                levels, and check your feet for sores, blisters, irritation,
                cuts, or other injuries. Be sure to call your health-care
                provider if an injury doesn’t begin to heal after two days.{" "}
              </p>
            </div>
          </div>
          <div className="modal-footer">
            <button
              onClick={() => nextModal(tipModelThree, tipModelTwo)}
              className="prev"
              aria-label="Previous"
            >
              <svg
                width="17"
                height="28"
                viewBox="0 0 17 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15 2L3 14L15 26"
                  stroke="white"
                  strokeWidth="3"
                  strokeLinecap="round"
                />
              </svg>
            </button>
            <button
              onClick={() => nextModal(tipModelThree, tipModelFour)}
              className="next"
              aria-label="Next"
            >
              <svg
                width="17"
                height="28"
                viewBox="0 0 17 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2 26L14 14L2 2"
                  stroke="white"
                  strokeWidth="3"
                  strokeLinecap="round"
                />
              </svg>
            </button>
          </div>
        </Dialog>
        <Dialog
          role="dialog"
          open={tipModelFour.isOpen}
          onClose={tipModelFour.close}
          className="dsmes-modal pa-modal modal-theme-cyan"
          aria-label="Hair Care and Workouts"
        >
          <button
            className="modal-close-btn"
            onClick={tipModelFour.close}
            aria-label="Close"
          >
            <CloseIcon className="modal-close-icon" />
          </button>
          <div
            className="modal-header bcPhys"
            style={{ backgroundImage: `url(${tipBgImage04})` }}
          >
            &nbsp;
          </div>
          <div id="modalTitle-4" className="modal-title">
            <h3 tabIndex={0} className="strong">
              Hair Care and Workouts
            </h3>
          </div>
          <div tabIndex={0} id="modalContent-4" className="modal-content">
            <p>
              Because of the time, effort, identity, and money invested in hair
              care, Black women face additional considerations when making the
              choice to be physically active. Talk to your stylist. Let them
              know you plan on being physically active and doing activities that
              will cause you to sweat or get wet.  Work with them to and find a
              solution that works for how you wear your hair, like:
            </p>
            <ul>
              <li>Putting your hair in a ponytail or bun to protect it</li>
              <li>
                Wearing a silk or satin band if your hair is in its natural
                state
              </li>
              <li>
                Getting protective styling, like braids or two-strand twists
              </li>
              <li>
                Using a silicone swim cap designed specifically to protect and
                work with relaxers, naturals, braids, and locs
              </li>
            </ul>
          </div>
          <div className="modal-footer">
            <button
              onClick={() => nextModal(tipModelFour, tipModelThree)}
              className="prev"
              aria-label="Previous"
            >
              <svg
                width="17"
                height="28"
                viewBox="0 0 17 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15 2L3 14L15 26"
                  stroke="white"
                  strokeWidth="3"
                  strokeLinecap="round"
                />
              </svg>
            </button>
            <button
              onClick={() => nextModal(tipModelFour, tipModelFive)}
              className="next"
              aria-label="Next"
            >
              <svg
                width="17"
                height="28"
                viewBox="0 0 17 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2 26L14 14L2 2"
                  stroke="white"
                  strokeWidth="3"
                  strokeLinecap="round"
                />
              </svg>
            </button>
          </div>
        </Dialog>
        <Dialog
          role="dialog"
          open={tipModelFive.isOpen}
          onClose={tipModelFive.close}
          className="dsmes-modal pa-modal modal-theme-cyan"
          aria-label="Ways to Include Physical Activity into Your Busy Schedule"
        >
          <button
            className="modal-close-btn"
            onClick={tipModelFive.close}
            aria-label="Close"
          >
            <CloseIcon className="modal-close-icon" />
          </button>
          <div
            className="modal-header bcPhys"
            style={{ backgroundImage: `url(${tipModalHeaderImage05})` }}
          >
            &nbsp;
          </div>
          <div id="modalTitle-5" className="modal-title">
            <h3 tabIndex={0} className="strong">
              Ways to Include Physical Activity into Your Busy Schedule
            </h3>
          </div>
          <div tabIndex={0} id="modalContent-5" className="modal-content">
            <PhysicalActivityModalContent05 />
          </div>
          <div className="modal-footer">
            <button
              onClick={() => nextModal(tipModelFive, tipModelFour)}
              className="prev"
              aria-label="Previous"
            >
              <svg
                width="17"
                height="28"
                viewBox="0 0 17 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15 2L3 14L15 26"
                  stroke="white"
                  strokeWidth="3"
                  strokeLinecap="round"
                />
              </svg>
            </button>
            <button
              onClick={() => nextModal(tipModelFive, tipModelSix)}
              className="next"
              aria-label="Next"
            >
              <svg
                width="17"
                height="28"
                viewBox="0 0 17 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2 26L14 14L2 2"
                  stroke="white"
                  strokeWidth="3"
                  strokeLinecap="round"
                />
              </svg>
            </button>
          </div>
        </Dialog>
        <Dialog
          role="dialog"
          open={tipModelSix.isOpen}
          onClose={tipModelSix.close}
          className="dsmes-modal pa-modal modal-theme-cyan"
          aria-label="Putting the Fun in Fitness"
        >
          <button
            className="modal-close-btn"
            onClick={tipModelSix.close}
            aria-label="Close"
          >
            <CloseIcon className="modal-close-icon" />
          </button>
          <div
            className="modal-header bcPhys mt1"
            style={{ backgroundImage: `url(${tipModalHeaderImage06})` }}
          >
            &nbsp;
          </div>
          <div id="modalTitle-6" className="modal-title">
            <h3 tabIndex={0} className="strong">
              Putting the Fun in Fitness
            </h3>
          </div>
          <div tabIndex={0} id="modalContent-6" className="modal-content">
            <p>
              Positive self-talk such as “I can and I will be healthy” or “I’m
              going to invest in myself today,” can help you stay motivated to
              be active. If you think of being active in a positive way, you’ll
              have an easier time getting started and enjoying it. So, pay
              attention to negative self-talk, such as “I can’t do it”, and try
              and increase your positive self-talk.
            </p>
            <p>
              You don’t need to pay for a gym membership or spend hours on the
              treadmill to get active. There are plenty of fun and free options
              for you to try. There are even certain fitness groups that are
              specifically for Black women to exercise together. Here are a few
              examples:
            </p>
            <ul>
              <li>
                <ExternalLink url="https://www.40plusdoubledutchclub.org/">
                  40+ Double Dutch Club
                </ExternalLink>
              </li>
              <li>
                <ExternalLink url="https://blackgirlsrun.com/">
                  Black Girls Run
                </ExternalLink>
              </li>
              <li>
                <ExternalLink url="https://www.girltrek.org/">
                  GirlTREK
                </ExternalLink>
              </li>
            </ul>
          </div>
          <div className="modal-footer">
            <button
              onClick={() => nextModal(tipModelSix, tipModelFive)}
              className="prev"
              aria-label="Previous"
            >
              <svg
                width="17"
                height="28"
                viewBox="0 0 17 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15 2L3 14L15 26"
                  stroke="white"
                  strokeWidth="3"
                  strokeLinecap="round"
                />
              </svg>
            </button>
            <button
              onClick={() => nextModal(tipModelSix, tipModelSeven)}
              className="next"
              aria-label="Next"
            >
              <svg
                width="17"
                height="28"
                viewBox="0 0 17 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2 26L14 14L2 2"
                  stroke="white"
                  strokeWidth="3"
                  strokeLinecap="round"
                />
              </svg>
            </button>
          </div>
        </Dialog>
        <Dialog
          role="dialog"
          open={tipModelSeven.isOpen}
          onClose={tipModelSeven.close}
          className="dsmes-modal pa-modal modal-theme-cyan"
          aria-label="Motivation and Consistency Are Key"
        >
          <button
            className="modal-close-btn"
            onClick={tipModelSeven.close}
            aria-label="Close"
          >
            <CloseIcon className="modal-close-icon" />
          </button>
          <div
            className="modal-header bcPhys"
            style={{ backgroundImage: `url(${tipModalHeaderImage07})` }}
          >
            &nbsp;
          </div>
          <div id="modalTitle-7" className="modal-title">
            <h3 tabIndex={0} className="strong">
              Motivation and Consistency Are Key
            </h3>
          </div>
          <div tabIndex={0} id="modalContent-7" className="modal-content">
            <p>
              Even one small step forward is a step toward your goal. Here are
              some ways that you can stay motivated and consistent when
              increasing your physical activity.
            </p>
            <ul>
              <li>
                Try a few different activities so that you don’t get bored.
              </li>
              <li>Workout with a family member or with friends.</li>
              <li>
                Make physical activity simple by finding ways to squeeze it into
                your day-to-day life. For example, try walking around your house
                while you’re on the phone instead of sitting.
              </li>
              <li>
                Make physical activity a routine so that it can become a habit.
              </li>
              <li>
                If you get bored with your physical activities, mix it up and
                try something new.
              </li>
              <li>
                Keep track of your activity with an app or write it down in a
                journal so you can see how far you’ve come and learn what’s
                working for you.
              </li>
              <li>
                Put in some effort, but don’t overdo it. You should be able to
                talk but not sing while exercising.
              </li>
              <li>
                Focus on the physical and mental health benefits, not just the
                number on the scale.
              </li>
              <li>
                It’s normal to miss some workouts with busy schedules. Be kind
                to yourself and get back into it as soon as you can.
              </li>
            </ul>
            <p>
              There are so many resources available to help you get active.
              Check out these additional{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.cdc.gov/diabetes/library/features/get-moving-to-manage-diabetes.html"
                target="_blank"
              >
                ways to stay active.
              </a>
            </p>
          </div>
          <div className="modal-footer">
            <button
              onClick={() => nextModal(tipModelSeven, tipModelSix)}
              className="prev"
              aria-label="Previous"
            >
              <svg
                width="17"
                height="28"
                viewBox="0 0 17 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15 2L3 14L15 26"
                  stroke="white"
                  strokeWidth="3"
                  strokeLinecap="round"
                />
              </svg>
            </button>
          </div>
        </Dialog>
      </div>
    </section>
  );
};
