import { PopUp } from './components/PopUp/PopUp'
import SupportTopics from '../SupportTopics/SupportTopics'
import image1 from '../../assets/images/reducingRisk3.jpeg'
import image2 from '../../assets/images/reducingRisk2.jpeg'
import infoPDF from '../../assets/images/reducing1.png'
import quote from '../../assets/images/homeQuote4.jpg'
import Arrow from '../../assets/images/rr-vector-1.png'
import Hash from '../../assets/images/rr-vector-2.png'
import { VideoLink } from '../VideoLink/VideoLink'
import FindSisterhood from '../FindSisterhood/FindSisterhood'
import ConnectToSpecialist from '../ConnectToSpecialist/ConnectToSpecialist'
import infographicReducingRisk from '../../assets/Healthy-Checkup-Checklist.pdf'
import './ReducingRisk.scss'

export default function ReducingRisk() {
  return (
    <>
      <div className="reducing-risk">
        <div className="reducing-risk-banner">
          <VideoLink url="https://www.youtube.com/watch?v=Dyq-rVnZMvs"/>
          <div className="reducing-risk-banner-text-section">
            <h1 className="display-h1"><span>Reducing</span> Risk of Complications</h1>
            <p className="reducing-risk-banner-text">
              Living with diabetes can be stressful especially since it may also
              increase your risk for other health problems such as heart
              disease, vision loss and kidney disease.
            </p>
            <p className="reducing-risk-banner-text">
              Luckily, there are steps that you can take to help lower your
              chances of developing these other health issues. Scroll down to learn
              what you can do to avoid these complications and stay healthy! 
            </p>
          </div>
          <div>
            <img role="presentation"
              src={image1}
              className="reducing-risk-banner-img"
              alt="reducing risk banner"
            />
          </div>
        </div>

        <div className="reducing-risk-testimonial-section">
          <div className="blockquote-wrapper">
            <blockquote className="callout quote EN">
              
              <img role="presentation"
                src={quote}
                className="reducing-risk-testimonial-img"
                alt="testimonial"
              />
              <p>We know you have a lot on your mind trying to manage life
                and diabetes. We have your back. You don’t have to do it alone. We
                are your village.
                <br />
                <span className="strong">– Natalie, diabetes care and education specialist</span>
              </p>
              
            </blockquote>
          </div>
        </div>

        <PopUp />

        

        <div className="rr-box-wrapper">
          <div className="reducing-risk-box-section">
            <p>
              You may already be taking steps to reduce your risk of
              complications. Ask yourself:
            </p>
            <ul>
              <li>
                What am I already doing daily to reduce my risk of complications?
              </li>
              <li>
                What new thing can I add to my routine to further reduce my health
                risk?
              </li>
            </ul>
            <p>
              Be proud of yourself for doing what you can to prevent future health
              issues!
            </p>
          </div>
        </div>
      </div>

      <div className="reducing-risk-footer-section">
        <div className="reducing-risk-footer-text">
          <p>
            A diagnosis of diabetes requires some changes to your routine and can be a lot
            to think about.
          </p>
          <p>
            But you can do this! Make a promise to yourself to take
            steps to help reduce your risks and stay on track!
          </p>
          
          <div className="infoPDFFlex">
            <p>Pledging to getting regular health checkups is one of the most important ways to reduce risks for related health complications. Here’s a resource you can use to keep track of all the appointments and check-ins you make with your health care support team: </p>
              <a class="btnLink" href={infographicReducingRisk} target="_blank"><button class="infoBtn">Healthy Checkup Checklist</button></a>
          </div>
        </div>
        <div>
          <img role="presentation"
            src={image2}
            className="reducing-risk-footer-image"
            alt="woman wearing glasses smiling at another woman"
          />
        </div>
      </div>
      <div id="SupportTopics" className="SupportTopics">
        <SupportTopics/>
        </div>
          <ConnectToSpecialist/>
    </>
  )
}
