import { Routes, Route } from 'react-router-dom'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import Home from './components/Home/Home'
import Navbar from './components/Navbar/Navbar'
import Quiz from './components/Quiz/Quiz'
import { QuizResult } from './components/Quiz/components/QuizResult'
import HealthyEatingCooking from './components/HealthyEatingCooking/HealthyEatingCooking'
import { IncreasingPhysicalActivity } from './components/IncreasingPhysicalActivity/IncreasingPhysicalActivity'
import CreatingCommunity from './components/CreatingCommunity/CreatingCommunity'
import CopingDiabetes from './components/CopingDiabetes/CopingDiabetes'
import ConnectingToDSMES from './components/ConnectingDSMES/ConnectingDSMES'
import LearningAboutDSMES from './components/LearningAboutDSMES/LearningAboutDSMES'
import MonitoringDiabetes from './components/MonitoringDiabetes/MonitoringDiabetes'
import ReducingRisk from './components/ReducingRisk/ReducingRisk'
import QualityCare from './components/QualityCare/QualityCare'
import FindingProvider from './components/FindingProvider/FindingProvider'
import PageNotFound from './components/PageNotFound'
import StyleGuide from './components/StyleGuide/StyleGuide'
import Footer from './components/Footer/Footer'
import RoutePaths from './routes'
import ScrollToTop from './ScrollToTop'

function App() {
  return (
    <HelmetProvider>
      <main className="App">
        <Helmet>
          <title id="tab-title">Your Diabetes Compass | CDC</title>
          <meta name="description" content="DDT DSMES On-Ramp web app focusing on supporting black women with type 2 diabetes" />
        </Helmet>
        <Navbar />
        <ScrollToTop />
        <Routes>
          <Route path={RoutePaths.Landing} element={<Home />} />
          <Route path={RoutePaths.Home} element={<Home />} />
          <Route path={RoutePaths.QualityCare} element={<QualityCare />} />
          <Route path={RoutePaths.HealthyEating} element={<HealthyEatingCooking />} />
          <Route path={RoutePaths.PhysicalActivity} element={<IncreasingPhysicalActivity />} />
          <Route path={RoutePaths.FindingProvider} element={<FindingProvider />} />
          <Route path={RoutePaths.CreatingCommunity} element={<CreatingCommunity />} />
          <Route path={RoutePaths.CopingDiabetes} element={<CopingDiabetes />} />
          <Route path={RoutePaths.LearningAboutDSMES} element={<LearningAboutDSMES />} />
          <Route path={RoutePaths.ConnectingToDSMES} element={<ConnectingToDSMES />} />
          <Route path={RoutePaths.MonitoringDiabetes} element={<MonitoringDiabetes />} />
          <Route path={RoutePaths.ReducingRisk} element={<ReducingRisk />} />
          <Route path={RoutePaths.StyleGuide} element={<StyleGuide />} />
          <Route path={RoutePaths.Quiz} element={<Quiz />} />
          <Route path={RoutePaths.QuizResult} element={<QuizResult />} />
          <Route path='*' element={<PageNotFound />} />
        </Routes>
        <Footer />
        {/* <p>{`${process.env.REACT_APP_NAME}`}</p> */}
      </main>
    </HelmetProvider>
  )
}

export default App
