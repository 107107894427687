// import nutritionLabel from '../../../assets/images/SelfCare/NutritionLabel.png'
import headerUnderline from "../../../../assets/images/modal-header-underline.png";
import mindMindfulness from "../../../../assets/images/copingDiabetes/mind-meditation-head.png";
import { ExternalLink } from "../../../ExternalLink/ExternalLink";
import infoGraphicAffirmations from "../../../../assets/Living-w-Diabetes-Affirmations.pdf";
import styles from "./SelfCareModals.scss";

export const SelfCareModalContent06 = () => {
  return (
    <>
      <div id="modalTitle-1" className="modal-title">
        <h2 className="display-h2">Mind - Mindfulness and Meditation</h2>
        <img
          className="headerUnderline"
          src={headerUnderline}
          alt="decorative underline"
        />
      </div>
      <div id="modalContent-1" className="modal-content">
        <img className="headImg" src={mindMindfulness} alt="woman meditating" />
        <p>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.cdc.gov/diabetes/professional-info/toolkits/new-beginnings/mindfulness.html"
          >
            Two other tools that can help people manage diabetes distress are
            mindfulness and meditation
          </a>
          . Mindfulness is simply taking time to be present, and it has many
          benefits such as relieving stress, lowering blood pressure, and
          helping some people with diabetes regain control of how they feel.
          Meditation is a state of contemplation, focused attention, and
          reflection aimed at improving emotional well-being. There are many
          ways to be mindful or meditate, including the following:{" "}
        </p>
        <ul>
          <li>
            <strong>
              Guided meditation that focuses on breathing, stress reduction, and
              deep relaxation
            </strong>
            . Meditation can be done almost anywhere like during a bath, in your
            bed, and even while walking. Click the button below for a guided
            meditation.
            <div class="meditationButton">
              <ExternalLink
                url="https://www.youtube.com/watch?v=WAIgwUMthXQ"
                cname="org-button"
                aLabel="5-minute guided meditation"
              >
                Click here for a quick 5-min guided meditation video
              </ExternalLink>
              .{" "}
            </div>{" "}
          </li>
          <li>
            <strong>
              Practice journaling, spending time in nature, and taking time to
              pause and think
            </strong>
            .
          </li>
          <li>
            <strong>
              Mantra meditations use repetitive sounds or sayings to clear the
              mind and refocus on goals
            </strong>
            . Here are a few examples of positive affirmations you can try
            repeating to yourself during meditative moments:
            <ul>
              <li>I am more than enough.</li>
              <li>I will honor my temple with rest to release stress.</li>
              <li>I am worthy of self-love and self-care.</li>
              <li>I manage my diabetes. It does not manage me.</li>
            </ul>
          </li>
        </ul>
        <p>
          Click the button below to download these positive affirmations and try
          committing to some mindful or meditative practices for at least a week
          to see if they make a difference for you.{" "}
        </p>
        <div class="meditationButton">
          <a
            href={infoGraphicAffirmations}
            target="_blank"
            rel="noreferrer noopener"
          >
            Managing Diabetes with Positive Affirmations
          </a>
        </div>
      </div>
    </>
  );
};
