import "./StyleGuide.css";
import Guidelines from "../../assets/images/guidelines.gif";

function StyleGuide() {
  return (
    <>
      <div className="style-guide-main">
        <h1 className="mb-0">Style Guide</h1>
        <hr />
        <p>
          This is a quick and dirty style guide created to make sure all the
          devs/designers are on the same page. Changes and updates are likely.
          Unless and until this document is finalized, the following are
          intended to be used only as guidelines.
        </p>
        <img
          src={Guidelines}
          alt="They're more what you'd call 'guidelines' than actual rules"
        />
        <br />
        <section>
          <h2 className="mb-0">Typography</h2>
          <hr />
          <p>
            The default font family is{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://fonts.google.com/specimen/Lato"
              target="_blank"
              rel="noopener noreferrer"
            >
              "Lato"
            </a>
          </p>
          <p>
            At present the site is loading 7 font styles/weights (4 regular and
            3 italic)
          </p>
          <ul>
            <li>
              <span className="fw-300">Light</span>
              <code>'font-weight: 300;'</code>
            </li>
            <li>
              <span className="fw-300 fst-italic">Light Italic</span>
              <code>'font-weight: 300; font-style: italic;'</code>
            </li>
            <li>
              <span className="fw-400">Regular</span>
              <code>'font-weight: 400;'</code>
            </li>
            <li>
              <span className="fw-400 fst-italic">Regular Italic</span>
              <code>'font-weight: 400; font-style: italic;'</code>
            </li>
            <li>
              <span className="fw-700">Bold</span>
              <code>font-weight: 700;'</code>
            </li>
            <li>
              <span className="fw-700 fst-italic">Bold Italic</span>
              <code>font-weight: 700; font-style: italic;'</code>
            </li>
            <li>
              <span className="fw-900">Black</span>
              <code>font-weight: 900;'</code>
            </li>
          </ul>
          <p>
            Font sizes should be specified in <code>'rem'</code> units to ensure
            consistent scalability and accessability. The font-size on the{" "}
            <code>&lt;body&gt;</code> is being left at the default 16px size so
            all 'rem' units should be a relative unit to that size
          </p>
          <p>
            The default font-size for most text elements (incl: p, li, button,
            input, select, and textarea) is being set to <code>1.125rem</code>{" "}
            (18px) based off the designs in Figma
          </p>
          <h2 className="mb-0">Headings</h2>
          <hr />
          <h1>h1. Heading</h1>
          <h2>h2. Heading</h2>
          <h3>h3. Heading</h3>
          <h4>h4. Heading</h4>
          <h5>h5. Heading</h5>
          <h6>h6. Heading</h6>
          <p>
            All the traditional HTML headings are using a variable font-size
            calculation based on the viewport width which maxes out at screen
            width greater than 1200px
          </p>
          <h2 className="mb-0">Display Headings</h2>
          <hr />
          <p>
            The <strong>display heading</strong> classes are intended to be used
            whenever a heading needs to stand out more{" "}
            <code>.display-h1, .display-h2</code>
          </p>
          <h1 className="display-h1">Display Heading 1</h1>
          <h2 className="display-h2">Display Heading 2</h2>
        </section>
        <section>
          <h3>Style Guide Log</h3>
          <hr />
          <ul>
            <li>
              2022.07.22 - Created Style Guide, added Typography and Header
              style sections ::DL
            </li>
          </ul>
        </section>
      </div>
    </>
  );
}

export default StyleGuide;
