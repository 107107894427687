import { HealthyEatingModalCards } from "./HealthyEatingModalCards/HealthyEatingModalCards";
import SupportTopics from "../SupportTopics/SupportTopics";
import HECFamily from "../../assets/images/hec.jpeg";
import HECWoman from "../../assets/images/hec1.jpeg";
import Accordions from "./Accordions/Accordions";
import { VideoLink } from "../VideoLink/VideoLink";
import FindSisterhood from "../FindSisterhood/FindSisterhood";
import ConnectToSpecialist from "../ConnectToSpecialist/ConnectToSpecialist";
import "./HealthyEatingCooking.scss";

export default function HealthyEatingCooking() {
  return (
    <>
      <div className="healthy-eating-cooking-section">
        <div className="he-banner-container">
          <VideoLink url="https://www.youtube.com/watch?v=oq7VseHnxng" />
          <div className="he-banner main-banner">
            <div className="he-banner-text">
              <h1 className="display-h1">
                <span>Creative Eating</span> and Cooking
              </h1>
              <p>
                Food can be a fun part of your journey with diabetes. You may
                think living with diabetes means living a life without foods you
                enjoy. While what you eat has a big impact on managing diabetes,
                this doesn’t mean the end of delicious and satisfying food. So,
                what do you need to know?
              </p>
              <ul>
                <li>What to eat and what to limit</li>
                <li>How to create a healthy plate</li>
                <li>How to read nutrition labels</li>
                <li>
                  How to use pro tips for cooking for someone with diabetes{" "}
                </li>
              </ul>
            </div>
            <div className="he-banner-image">
              <div className="img-wrapper">
                <img
                  src={HECFamily}
                  className="healthy-eating-cooking-section-banner-img"
                  alt="family cooking"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="he-insight-section">
          <div className="blockquote-wrapper">
            <blockquote className="quote EN">
              <h2 className="strong italic">
                Diabetes Self-Management Education and Support (DSMES) Insight
              </h2>
              <p>
                Losing weight doesn’t have to mean losing a lot of weight.
                Taking off just 5% to 10% — that’s 10 to 20 pounds if you weigh
                200 pounds — can improve your health and well-being.
              </p>
            </blockquote>
          </div>
        </div>
        <HealthyEatingModalCards />
      </div>
      <Accordions />
      <div className="healthy-eating-cooking-section-footer">
        <div className="healthy-eating-cooking-section-footer-text">
          <p>
            Living with and managing diabetes can come with some changes to your
            life such as creating new habits and regularly monitoring various
            aspects of your health. You can do this. With the right resources
            and support, you can feel confident and empowered to live the happy,
            healthy life you desire.
          </p>
        </div>
        <div>
          <img
            src={HECWoman}
            className="healthy-eating-cooking-section-footer-img"
            alt="smiling woman"
          />
        </div>
      </div>
      <div class="hideExternal">
        <FindSisterhood topic="cooking" />
      </div>
      <div id="SupportTopics" className="SupportTopics">
        <SupportTopics />
      </div>
      <ConnectToSpecialist />
    </>
  );
}
