import { useId } from 'react'
import flipCardImg01 from '../../../assets/images/healthyEating/bfy-good-protein-01.png'
import flipCardImg02 from '../../../assets/images/healthyEating/bfy-good-protein-02.png'
import flipCardImg03 from '../../../assets/images/healthyEating/bfy-good-protein-03.png'
import flipCardImg04 from '../../../assets/images/healthyEating/bfy-bad-protein-01.png'
import flipCardImg05 from '../../../assets/images/healthyEating/bfy-bad-protein-02.png'
import flipCardImg06 from '../../../assets/images/healthyEating/bfy-bad-protein-03.png'
import { ExternalLink } from '../../ExternalLink/ExternalLink'
import styles from './HealthyEatingModalCards.module.scss'

export const HealthyEatingModalContent03 = () => {
  const id = useId();
  return (
    <>
      <p>Proteins are essential because they help build and repair muscles and 
      help your body fight infection. To manage healthy cholesterol and blood 
      pressure levels, try to eat lean (low-fat) proteins such as chicken breast, 
      fish, beans, or tofu and limit red meat and processed meats which are 
      often higher in saturated fat. See examples below of better-for-you proteins 
      and proteins to avoid. To learn more, read this {' '}
      <ExternalLink url="https://www.diabetes.org/healthy-living/recipes-nutrition/eating-well/protein">
      protein guide.
      </ExternalLink></p>
      <div className={styles["flip-card-grid"]}>
        <h5 className={styles["bfy-title"]}>Better-for-you Proteins</h5>
        <div className={styles.card} tabIndex={0} aria-roledescription="flipping-card">
          <div className={styles["card-body"]}>
            <div className={styles["card-front"]}>
              <img src={flipCardImg01} alt="" aria-describedby={id + '-cardCaption01'} />
            </div>
            <div className={styles["card-back"]}>
              <p id={id + '-cardCaption01'} className={styles.bfy}>Chicken</p>
            </div>
          </div>
        </div>
        <div className={styles.card} tabIndex={0} aria-roledescription="flipping-card">
          <div className={styles["card-body"]}>
            <div className={styles["card-front"]}>
              <img src={flipCardImg02} alt="" aria-describedby={id + 'cardCaption02'} />
            </div>
            <div className={styles["card-back"]}>
              <p id={id + '-cardCaption02'} className={styles.bfy}>Fish</p>
            </div>
          </div>
        </div>
        <div className={styles.card} tabIndex={0} aria-roledescription="flipping-card">
          <div className={styles["card-body"]}>
            <div className={styles["card-front"]}>
              <img src={flipCardImg03} alt="" aria-describedby={id + 'cardCaption03'} />
            </div>
            <div className={styles["card-back"]}>
              <p id={id + '-cardCaption03'} className={styles.bfy}>Beans</p>
            </div>
          </div>
        </div>
        <h5 className={styles["avoid-title"]}>Proteins to Avoid</h5>
        <div className={styles.card} tabIndex={0} aria-roledescription="flipping-card">
          <div className={styles["card-body"]}>
            <div className={styles["card-front"]}>
              <img src={flipCardImg04} alt="" aria-describedby={id + 'cardCaption04'} />
            </div>
            <div className={styles["card-back"]}>
              <p id={id + '-cardCaption04'} className={styles.avoid}>Sausage</p>
            </div>
          </div>
        </div>
        <div className={styles.card} tabIndex={0} aria-roledescription="flipping-card">
          <div className={styles["card-body"]}>
            <div className={styles["card-front"]}>
              <img src={flipCardImg05} alt="" aria-describedby={id + 'cardCaption05'} />
            </div>
            <div className={styles["card-back"]}>
              <p id={id + '-cardCaption05'} className={styles.avoid}>Hot dogs</p>
            </div>
          </div>
        </div>
        <div className={styles.card} tabIndex={0} aria-roledescription="flipping-card">
          <div className={styles["card-body"]}>
            <div className={styles["card-front"]}>
              <img src={flipCardImg06} alt="" aria-describedby={id + 'cardCaption06'} />
            </div>
            <div className={styles["card-back"]}>
              <p id={id + '-cardCaption06'} className={styles.avoid}>Bacon</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}