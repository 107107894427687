// import portionSizeImg from '../../../assets/images/SelfCare/Diabetes-Manage-Eat-Well-Plate-Graphic_600px 1.png'
import headerUnderline from '../../../../assets/images/modal-header-underline.png'
import spiritImg from '../../../../assets/images/copingDiabetes/spirit-gratitude-head.png'
import styles from './SelfCareModals.scss'

export const SelfCareModalContent13 = () => {
  return (
    <>
    <div id="modalTitle-1" className="modal-title">
       <h2 className="display-h2">Spirit - Practice Gratitude</h2>
       <img className="headerUnderline" src={headerUnderline} alt="decorative underline"/>
    </div>
       <div id="modalContent-1" className="modal-content">
         <img className="headImg" src={spiritImg} alt="a notepad with the words I am grateful for written on it"/>
        <p><strong>Practicing gratitude can help</strong> remind you of daily things and people you are grateful for. Be specific. Write them down in the morning or at night or replay them in your mind. </p>

        <p>Here are a few journal prompt ideas you can try to kick-start your gratitude reflections:</p> 

        <ul>
          <li>I am grateful for… </li>
          <li>I am celebrating all my wins, big and small, including…</li>
          <li>Because I am grateful for my body, I will practice self-care by…  </li>
          </ul>
      </div>
    </>
  )
}