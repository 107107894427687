import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

export const PhysicalActivityModalContent01 = () => {
  return (
    <>
      <p>Being active means doing any type of daily physical movement. It can be a session of exercise, mopping the floor, or anything that increases the time you spend standing up. </p>
      <p>The goal is to get at least 30 minutes of activity a day and 150 minutes every week.  </p>
      <p>Below are some examples of physical activities. Click the ones that you can include in your daily routine!  </p>
      <div className="activity-list">
        <Accordion className="pa-modal-accordion">
          <AccordionSummary
            expandIcon={<ArrowRightIcon/>}
            id="modal01panel01-header"
            aria-controls="modal01panel01-content"
            className="pa-modal-accordion-summary"
          >
            <h5 className="strong">Walking briskly</h5>
          </AccordionSummary>
          <AccordionDetails className="pa-modal-accordion-details">
            <p tabIndex={0} >Walking is a mood booster and great for your mental health. Try walking with a friend at a nearby school track or even an air-conditioned mall, or walk around a large store.</p>
          </AccordionDetails>
        </Accordion>
        <Accordion className="pa-modal-accordion">
          <AccordionSummary
            expandIcon={<ArrowRightIcon/>}
            id="modal01panel02-header"
            aria-controls="modal01panel02-content"
            className="pa-modal-accordion-summary"
          >
            <h5 className="strong">Doing housework</h5>
          </AccordionSummary>
          <AccordionDetails className="pa-modal-accordion-details">
            <p tabIndex={0} >Most household chores burn calories using some of the same physical movements that you could do in the gym.</p>
          </AccordionDetails>
        </Accordion>
        <Accordion className="pa-modal-accordion">
          <AccordionSummary
            expandIcon={<ArrowRightIcon/>}
            id="modal01panel03-header"
            aria-controls="modal01panel03-content"
            className="pa-modal-accordion-summary"
          >
            <h5 className="strong">Stretching</h5>
          </AccordionSummary>
          <AccordionDetails className="pa-modal-accordion-details">
            <p tabIndex={0} >Stretching feels so good and can improve blood flow to your muscles and help prevent injury.</p>
          </AccordionDetails>
        </Accordion>
        <Accordion className="pa-modal-accordion">
          <AccordionSummary
            expandIcon={<ArrowRightIcon/>}
            id="modal01panel04-header"
            aria-controls="modal01panel04-content"
            className="pa-modal-accordion-summary"
          >
            <h5 className="strong">Gardening</h5>
          </AccordionSummary>
          <AccordionDetails className="pa-modal-accordion-details">
            <p tabIndex={0} >Planting and tending a garden can help you burn calories and improve your coordination and overall mobility.</p>
          </AccordionDetails>
        </Accordion>
        <Accordion className="pa-modal-accordion">
          <AccordionSummary
            expandIcon={<ArrowRightIcon/>}
            id="modal01panel05-header"
            aria-controls="modal01panel05-content"
            className="pa-modal-accordion-summary"
          >
            <h5 className="strong">Dancing</h5>
          </AccordionSummary>
          <AccordionDetails className="pa-modal-accordion-details">
            <p tabIndex={0} >Dancing can be fun and expressive and helps you improve your coordination and balance so get up and dance! </p>
          </AccordionDetails>
        </Accordion>
        <Accordion className="pa-modal-accordion">
          <AccordionSummary
            expandIcon={<ArrowRightIcon/>}
            id="modal01panel06-header"
            aria-controls="modal01panel06-content"
            className="pa-modal-accordion-summary"
          >
            <h5 className="strong">Swimming</h5>
          </AccordionSummary>
          <AccordionDetails className="pa-modal-accordion-details">
            <p tabIndex={0} >Swimming and even walking in the pool are low-impact forms of exercise that can improve your cardiovascular health and endurance.</p>
          </AccordionDetails>
        </Accordion>
        <Accordion className="pa-modal-accordion">
          <AccordionSummary
            expandIcon={<ArrowRightIcon/>}
            id="modal01panel07-header"
            aria-controls="modal01panel07-content"
            className="pa-modal-accordion-summary"
          >
            <h5 className="strong">Running</h5>
          </AccordionSummary>
          <AccordionDetails className="pa-modal-accordion-details">
            <p tabIndex={0} >Running is something you can do anytime, anywhere. There may even be a local organization that promotes running and sisterhood where you live. Treadmills can also be a weatherproof solution to increase your physical activity.</p>
          </AccordionDetails>
        </Accordion>
        <Accordion className="pa-modal-accordion">
          <AccordionSummary
            expandIcon={<ArrowRightIcon/>}
            id="modal01panel08-header"
            aria-controls="modal01panel08-content"
            className="pa-modal-accordion-summary"
          >
            <h5 className="strong">Moderate- to high-intensity muscle-strengthening activity</h5>
          </AccordionSummary>
          <AccordionDetails className="pa-modal-accordion-details">
            <p tabIndex={0} >Use resistance bands or lift small weights such as canned vegetables on at least two days per week. Try to work all major muscle groups.</p>
          </AccordionDetails>
        </Accordion>
      </div>
    </>
  )
}