import React from "react";
import Modal from "react-modal";
import image7 from "../../../../assets/images/copingDiabetes/self-care-body.png";
import image8 from "../../../../assets/images/copingDiabetes/self-care-mind.png";
import image9 from "../../../../assets/images/copingDiabetes/self-care-spirit.png";
import image10 from "../../../../assets/images/copingDiabetes/self-care-general.png";
import headerUnderline from "../../../../assets/images/modal-header-underline.png";
import bodyRelaxHeadImg from "../../../../assets/images/copingDiabetes/body-relax-head-img.png";
import spiritSelfCareImg from "../../../../assets/images/copingDiabetes/spirit-self-care.png";
import spiritSelfCareRemindersImg from "../../../../assets/images/copingDiabetes/spirit-self-care-reminders.png";
import mindSelfTalkImg from "../../../../assets/images/copingDiabetes/mind-self-talk.png";
import iconLungs from "../../../../assets/images/copingDiabetes/icon-lungs.svg";
import iconCal from "../../../../assets/images/copingDiabetes/icon-calendar.svg";
import iconRun from "../../../../assets/images/copingDiabetes/icon-person.svg";
import iconNose from "../../../../assets/images/copingDiabetes/icon-nose.svg";
import { ExternalLink } from "../../../ExternalLink/ExternalLink";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import "./SelfCareModals.scss";

class MutipleButtonsWithModalInSameComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      activeModal: "",
    };
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  handleOpenModal(val) {
    this.setState({ activeModal: val });
    this.setState({ showModal: true });
  }

  handleCloseModal() {
    this.setState({ showModal: false });
    this.setState({ showModal: "" });
  }
  render() {
    return (
      <>
        <div className="self-care-row">
          <div className="self-care-card">
            <a className="button" onClick={() => this.handleOpenModal("body")}>
              <img src={image7} />
            </a>
          </div>
          <Modal
            isOpen={this.state.showModal && this.state.activeModal === "body"}
            contentLabel="Body Modal"
          >
            {/* body modals */}
            <div className="content">
              <button className="close" onClick={this.handleCloseModal}>
                X
              </button>
              <Accordion className="md-modal-accordion">
                <AccordionSummary
                  expandIcon={<ArrowRightIcon />}
                  id="modalBodypanel01-header"
                  aria-controls="modalBodypanel01-content"
                  className="md-modal-accordion-summary"
                >
                  <h2 className="display-h2">BODY-Relax</h2>
                  <img className="headerUnderline" src={headerUnderline} />
                </AccordionSummary>
                <AccordionDetails className="md-modal-accordion-details">
                  <img className="headImg" src={bodyRelaxHeadImg} />
                  <div className="modalFlex">
                    <div className="flexCard">
                      <img className="icon" src={iconLungs} />
                      <p>
                        Stress can cause physical reactions and tension in the
                        body, but{" "}
                        <ExternalLink
                          url="https://www.nccih.nih.gov/health/stress"
                          cname="org-button"
                          aLabel="Relaxation Techniques"
                        >
                          relaxation techniques can counteract the negative
                          effects of stress
                        </ExternalLink>
                        . Try to ease stress by finding time to concentrate on
                        your breathing.
                      </p>
                    </div>

                    <div className="flexCard">
                      <img className="icon" src={iconCal} />
                      <p>
                        Regularly dedicate a day or moments throughout the week
                        for me-time. During this time, try to free yourself from
                        work and obligations and spend time with yourself doing
                        whatever you want to do such as reading, cleaning, or
                        watching a movie.{" "}
                      </p>
                    </div>
                  </div>
                  <div className="modalFlex">
                    <div className="flexCard">
                      <img className="icon" src={iconRun} />
                      <p>
                        Commit to a day of pampering by booking a spa date or
                        doing an at-home facial with some soothing aromatherapy
                        and light music.{" "}
                      </p>
                    </div>

                    <div className="flexCard">
                      <img className="icon" src={iconNose} />
                      <p>
                        Deep breathing exercises can reduce glycemia in people
                        with type 2 diabetes, which may make it a useful
                        self-care tool.
                      </p>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion className="md-modal-accordion">
                <AccordionSummary
                  expandIcon={<ArrowRightIcon />}
                  id="modalBodypanel02-header"
                  aria-controls="modalBodypanel02-content"
                  className="md-modal-accordion-summary"
                >
                  <h5 className="strong">Refuel</h5>
                </AccordionSummary>
                <AccordionDetails className="md-modal-accordion-details">
                  <p>
                    Did you know that poor nutrition and unhealthy eating habits
                    can lower your mood by spiking your blood sugar? That’s
                    right, changes in blood sugar levels can transform your
                    feelings of happiness into irritation and sadness,
                    increasing your risk of depression and anxiety A balanced
                    diet of healthy meals and staying hydrated can improve your
                    energy and focus throughout the day. For more ideas on how
                    to care for your body with better nutrition, check out the
                    creative eating and cooking module for meal planning ideas.
                  </p>
                </AccordionDetails>
              </Accordion>
              <Accordion className="md-modal-accordion">
                <AccordionSummary
                  expandIcon={<ArrowRightIcon />}
                  id="modalBodypanel03-header"
                  aria-controls="modalBodypanel03-content"
                  className="md-modal-accordion-summary"
                >
                  <h5 className="strong">Move</h5>
                </AccordionSummary>
                <AccordionDetails className="md-modal-accordion-details">
                  <div className="modalFlex">
                    <div className="flexCard">
                      {/*<img className="icon" src={iconLungs}/>*/}
                      <p>
                        Staying physically healthy can improve your emotional
                        well-being. Just 30 minutes of walking every day can
                        help boost your mood and improve your health. Small
                        amounts of exercise add up, so don’t be discouraged if
                        you can’t do 30 minutes at one time.
                      </p>
                    </div>
                  </div>
                  <div className="modalFlex">
                    <div className="flexCard">
                      <img className="icon" src={iconRun} />
                      <p>
                        Want to get moving but unsure where to start? GirlTrek,
                        a nonprofit organization dedicated to mobilizing Black
                        women to walk as a form of healing, has a self-care
                        scavenger hunt and upbeat music playlist to help you
                        kickstart your journey to wellness.
                      </p>
                    </div>
                  </div>
                  <div className="modalFlex">
                    <div className="flexCard">
                      {/*<img className="icon" src={iconLungs}/>*/}
                      <p>
                        For more ideas to incorporate self-care through
                        movement, check out the module on physical activity
                      </p>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion className="md-modal-accordion">
                <AccordionSummary
                  expandIcon={<ArrowRightIcon />}
                  id="modalBodypanel04-header"
                  aria-controls="modalBodypanel04-content"
                  className="md-modal-accordion-summary"
                >
                  <h5 className="strong">Rest</h5>
                </AccordionSummary>
                <AccordionDetails className="md-modal-accordion-details">
                  <p>
                    If you have diabetes, too little sleep negatively affects
                    every area of your health, including how much you eat, what
                    you choose to eat, and how you respond to insulin. Proper
                    rest isn’t just important for reducing stress, it may also
                    put you in a better mood and give you more energy. Stick to
                    a night-time routine, and make sure you’re getting at least
                    7 hours of sleep. Light from devices and screens can make it
                    harder to fall asleep, so try to turn down device brightness
                    and cut screentime 1-2 hours before bedtime.
                  </p>
                </AccordionDetails>
              </Accordion>
            </div>
          </Modal>
          <div className="self-care-card">
            <a className="button" onClick={() => this.handleOpenModal("mind")}>
              <img src={image8} />
            </a>
          </div>
          <Modal
            isOpen={this.state.showModal && this.state.activeModal === "mind"}
            contentLabel="Mind Modal"
          >
            <div className="content">
              <button className="close" onClick={this.handleCloseModal}>
                X
              </button>
              <h2 className="display-h2">MIND</h2>
              <img className="headerUnderline" src={headerUnderline} />
              <img className="headImg" src={mindSelfTalkImg} />

              <Accordion className="md-modal-accordion">
                <AccordionSummary
                  expandIcon={<ArrowRightIcon />}
                  id="modalMindpanel01-header"
                  aria-controls="modalMindpanel01-content"
                  className="md-modal-accordion-summary"
                >
                  <h5 className="strong">Positive self-Talk as Self-Care</h5>
                </AccordionSummary>
                <AccordionDetails className="md-modal-accordion-details">
                  <div className="modalnoFlex">
                    <p>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.cdc.gov/diabetes/managing/mental-health.html#:~:text=People%20with%20diabetes%20are%2020,sometimes%20both%20together%20works%20best."
                        target="_blank"
                      >
                        Thoughts, feelings, beliefs, and attitudes can affect
                        your physical health
                      </a>
                      . Negative thoughts cause us to lose hope or to stand in
                      the way of our progress. A negative thought is usually a
                      criticism of ourselves. We all have negative thoughts from
                      time to time, but it’s important to try to overcome them
                      because they often lead us into making unhealthy
                      decisions. Recognizing your feelings and taking steps to
                      control them can have a positive effect on your quality of
                      life. Here are a few resources to help you identify common
                      negative thoughts and advice on how to change them into
                      positive motivation.
                      <ul>
                        <li>
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.cdc.gov/diabetes/library/features/change_negative_thoughts_to_reach_your_goals.html"
                            target="_blank"
                          >
                            <strong>Article:</strong> Change Negative Thoughts
                            to Reach Your Goals
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.cdc.gov/diabetes/prevention/pdf/handout_session11.pdf"
                            target="_blank"
                          >
                            <strong>Handout & Worksheet:</strong> Talk Back to
                            Negative Thoughts
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.cdc.gov/diabetes/ndep/audio/yvonnes-story-1a.mp3"
                            target="_blank"
                          >
                            Listen to Yvonne’s story
                          </a>{" "}
                          of how she went from denial about having diabetes to a
                          more positive response.
                        </li>
                      </ul>
                    </p>
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion className="md-modal-accordion">
                <AccordionSummary
                  expandIcon={<ArrowRightIcon />}
                  id="modalMindpanel02-header"
                  aria-controls="modalMindpanel02-content"
                  className="md-modal-accordion-summary"
                >
                  <h5 className="strong">Mindfulness &amp; Meditation</h5>
                </AccordionSummary>
                <AccordionDetails className="md-modal-accordion-details">
                  <p>
                    Two other tools that can help people manage diabetes
                    distress are mindfulness and meditation. Mindfulness is
                    simply taking time to be present, and it has many benefits,
                    such as relieving stress, lowering blood pressure, and
                    helping some people with diabetes regain control of how they
                    feel. Meditation is a state of contemplation, focused
                    attention, and reflection aimed at improving emotional
                    wellbeing. There are many ways to be mindful or meditate
                    including:
                  </p>
                  <p>
                    <ul>
                      <li>
                        Guided meditation that focuses on breathing, stress
                        reduction, and deep relaxation. There are free guided
                        meditations available online and in apps. Meditation can
                        be done almost anywhere: during a bath, in your bed, and
                        even while walking. Check in with our Diabetes Compass
                        navigator for some guidance on a quick meditation.
                      </li>
                      <li>
                        Mantra meditations use repetitive sounds or sayings to
                        clear the mind and refocus on goals. during a bath, in
                        your bed, and even while walking. Check in with our
                        Diabetes Compass navigator for some guidance on a quick,
                        guided meditation. Practices like journaling, spending
                        time in nature, and taking time to pause and think.
                      </li>
                    </ul>
                  </p>
                </AccordionDetails>
              </Accordion>
            </div>
          </Modal>
        </div>

        <div className="self-care-row">
          <div className="self-care-card">
            <a
              className="button"
              onClick={() => this.handleOpenModal("spirit")}
            >
              <img src={image9} />
            </a>
          </div>
          <Modal
            isOpen={this.state.showModal && this.state.activeModal === "spirit"}
            contentLabel="Spirit Modal"
          >
            <div className="content">
              <button className="close" onClick={this.handleCloseModal}>
                X
              </button>
              <h2 className="display-h2">SPIRIT</h2>
              <img className="headerUnderline" src={headerUnderline} />
              <img className="headImg" src={spiritSelfCareImg} />

              <Accordion className="md-modal-accordion">
                <AccordionSummary
                  expandIcon={<ArrowRightIcon />}
                  id="modalSpiritpanel01-header"
                  aria-controls="modalSpiritpanel01-content"
                  className="md-modal-accordion-summary"
                >
                  <h5 className="strong">Spiritual self-care</h5>
                </AccordionSummary>
                <AccordionDetails className="md-modal-accordion-details">
                  <p>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.cdc.gov/diabetes/professional-info/toolkits/new-beginnings/pdf/spirituality-guide-508.pdf"
                      target="_blank"
                    >
                      Spiritual self-care can mean different things to different
                      people
                    </a>
                    . For some people, spiritual self-care means joining a
                    religious institution, praying, reading religious texts, or
                    attending religious services. For others, spiritual
                    self-care might include connecting with your deepest self by
                    appreciating nature, art, or music. In any case, spiritual
                    self-care can provide valuable social support,
                    <ExternalLink
                      url="https://journals.sagepub.com/doi/pdf/10.1177/0145721713475843?casa_token=3M0SoAg_W4YAAAAA:qnbLhAVdK03yLgvlhZtLuZUL-NDqpNzQ_CwU4YiKRhKVdYi8_n6AQ0F2Pgjsr0BkuEwQGBNnm3uK"
                      cname="org-button"
                      aLabel="Journaling"
                    >
                      particularly among Black women who use these practices to
                      cope with diabetes
                    </ExternalLink>
                    . Here are some activities you can consider adding to your
                    regimen of spiritual self-care:
                  </p>
                </AccordionDetails>
              </Accordion>
              <Accordion className="md-modal-accordion">
                <AccordionSummary
                  expandIcon={<ArrowRightIcon />}
                  id="modalSpiritpanel02-header"
                  aria-controls="modalSpiritpanel02-content"
                  className="md-modal-accordion-summary"
                >
                  <h5 className="strong">Journaling</h5>
                </AccordionSummary>
                <AccordionDetails className="md-modal-accordion-details">
                  <p>
                    <ul>
                      <li>
                        <strong>
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.cdc.gov/diabetes/library/spotlights/journalinghealth.html"
                            target="_blank"
                          >
                            Journaling
                          </a>
                        </strong>{" "}
                        offers many positive results, such as improving memory
                        and boosting creativity. Recording your thoughts and
                        feelings in a journal could help you manage things about
                        diabetes that aren’t as easy to measure. For example,
                        stress can raise blood sugar. Regular journal entries
                        could help pinpoint what’s causing the stress—and
                        whether it’s a one-time thing or part of a pattern—and
                        help point the way toward a solution you might not have
                        thought of otherwise.
                        <ul>
                          <li>
                            Try setting aside a few minutes every day to write
                            some personal notes. Keep your journal nearby for
                            easy access or use a journaling app on your phone.
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </p>
                </AccordionDetails>
              </Accordion>
              <Accordion className="md-modal-accordion">
                <AccordionSummary
                  expandIcon={<ArrowRightIcon />}
                  id="modalSpiritpanel03-header"
                  aria-controls="modalSpiritpanel03-content"
                  className="md-modal-accordion-summary"
                >
                  <h5 className="strong">Be out in nature</h5>
                </AccordionSummary>
                <AccordionDetails className="md-modal-accordion-details">
                  <p>
                    <ul>
                      <li>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://wwwn.cdc.gov/ResearchGateway/Content/pdfs/R2C_FactSheets/en/R2C_Factsheet_102117_Desai_en.pdf"
                          target="_blank"
                        >
                          <strong>Being outside in nature</strong> is a
                          time-tested way to increase positive thinking and
                          overall sense of well-being
                        </a>
                        .{" "}
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://www.cdc.gov/cancer/skin/basic_info/sun-safety.htm"
                          target="_blank"
                        >
                          With proper protection from UV exposure
                        </a>
                        , spending time outdoors can improve overall health and
                        wellness, promoting mental health and stress reduction.
                        Time outdoors may also offer many opportunities to be
                        physically active, another important way to practice
                        self-care.
                      </li>
                    </ul>
                  </p>
                </AccordionDetails>
              </Accordion>
              <Accordion className="md-modal-accordion">
                <AccordionSummary
                  expandIcon={<ArrowRightIcon />}
                  id="modalSpiritpanel04-header"
                  aria-controls="modalSpiritpanel04-content"
                  className="md-modal-accordion-summary"
                >
                  <h5 className="strong">Volunteer</h5>
                </AccordionSummary>
                <AccordionDetails className="md-modal-accordion-details">
                  <p>
                    <ul>
                      <li>
                        Since ancient times, helping others has been seen as a
                        virtue that can benefit both the giver and receiver.
                        Helping others can reduce unhealthy mental feelings and
                        increase healthy feelings like love, joy, and
                        generosity. <strong>Volunteering</strong> can be a
                        small, but mighty act that can help you{" "}
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://www.cdc.gov/emotional-wellbeing/features/power-of-connection.htm"
                          target="_blank"
                        >
                          build meaningful social connections and feel a sense
                          of belonging
                        </a>{" "}
                        that adds to your overall health and quality of life.
                      </li>
                    </ul>
                  </p>
                </AccordionDetails>
              </Accordion>
              <Accordion className="md-modal-accordion">
                <AccordionSummary
                  expandIcon={<ArrowRightIcon />}
                  id="modalSpiritpanel05-header"
                  aria-controls="modalSpiritpanel05-content"
                  className="md-modal-accordion-summary"
                >
                  <h5 className="strong">Things that bring joy</h5>
                </AccordionSummary>
                <AccordionDetails className="md-modal-accordion-details">
                  <p>
                    <ul>
                      <li>
                        <strong>Doing things that bring you joy</strong> can be
                        another way of building social connections that are good
                        for your mental health. Involvement in enjoyable
                        activities, like a favorite hobby or pastime, can be
                        more than just fun. It can bolster our sense of
                        well-being.
                      </li>
                    </ul>
                  </p>
                </AccordionDetails>
              </Accordion>
              <Accordion className="md-modal-accordion">
                <AccordionSummary
                  expandIcon={<ArrowRightIcon />}
                  id="modalSpiritpanel06-header"
                  aria-controls="modalSpiritpanel06-content"
                  className="md-modal-accordion-summary"
                >
                  <h5 className="strong">Practice Gratitude</h5>
                </AccordionSummary>
                <AccordionDetails className="md-modal-accordion-details">
                  <p>
                    <ul>
                      <li>
                        <strong>Practicing gratitude can help</strong> remind
                        you of daily things and people you are grateful for. Be
                        specific. Write them down in the morning or at night or
                        replay them in your mind.
                        <ul>
                          <li>
                            Here are a few journal prompt ideas you can try to
                            kickstart your gratitude reflections:
                            <ul>
                              <li>I am grateful for…</li>
                              <li>
                                I am celebrating all my wins, big and small,
                                including…
                              </li>
                              <li>
                                Because I am grateful for my body, I will
                                practice self-care by…
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </p>
                </AccordionDetails>
              </Accordion>
            </div>
          </Modal>

          <div className="self-care-card">
            <a
              className="button"
              onClick={() => this.handleOpenModal("selfcare")}
            >
              <img src={image10} />
            </a>
          </div>
          <Modal
            isOpen={
              this.state.showModal && this.state.activeModal === "selfcare"
            }
            contentLabel="Selfcare Modal"
          >
            <div className="content">
              <button className="close" onClick={this.handleCloseModal}>
                X
              </button>

              <h2 className="display-h2">General Self-Care</h2>
              <img className="headerUnderline" src={headerUnderline} />
              <img className="headImg" src={spiritSelfCareRemindersImg} />

              <div className="modalnoFlex">
                <Accordion className="md-modal-accordion">
                  <AccordionSummary
                    expandIcon={<ArrowRightIcon />}
                    id="modalGeneralpanel01-header"
                    aria-controls="modalGeneralpanel01-content"
                    className="md-modal-accordion-summary"
                  >
                    <h5 className="strong">Self care reminders</h5>
                  </AccordionSummary>
                  <AccordionDetails className="md-modal-accordion-details">
                    <ul>
                      <li>
                        When managing diabetes while also dealing with stress,
                        you may be tempted to make poor choices. Be gentle with
                        yourself and know that dealing with your emotions is a
                        process. Taking care of YOU—getting consistent sleep,
                        taking moments to pause, moving more, and eating
                        healthy—is a key form of self-care that can help you
                        take charge of the stress in your life.
                      </li>
                      <li>
                        Recognize that even though you may be taking your
                        medications, eating a balanced diet, exercising, and
                        doing everything within your power to manage your
                        health, diabetes is an unpredictable condition. Although
                        you can’t plan for all the stressful moments, you do
                        have the power to respond in ways that help you feel at
                        peace.
                        <ul>
                          <li>
                            Some of those ways might mean saying “No” to other
                            people’s needs and requests as a form of
                            self-preservation.{" "}
                            <ExternalLink
                              url="https://www.pbs.org/newshour/nation/why-black-women-are-saying-no"
                              cname="org-button"
                              aLabel="PBS News Hour Nation"
                            >
                              Read more on examples of other Black women
                            </ExternalLink>{" "}
                            on examples of other Black women that have chosen to
                            empower and prioritize themselves.
                          </li>
                          <li>
                            Leaning on experts and loved ones can also be a way
                            to deal with stressful moments.{" "}
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href="https://www.cdc.gov/diabetes/dsmes/dsmes-living-with-diabetes.html"
                              target="_blank"
                            >
                              Talking to a diabetes care and education
                              specialist
                            </a>{" "}
                            about creating a care plan can alleviate the burden
                            of handling diabetes alone. For more ideas on
                            establishing social connections with family,
                            friends, and diabetes support groups, take a look at
                            the{" "}
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href="/creating-community"
                            >
                              module on Creating Community Support
                            </a>
                            .
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <p>
                      <strong>Ending Disclaimer Again:</strong> If you ever
                      experience stress that feels beyond what can be addressed
                      with mental health counselor, need more emotional support,
                      or think about suicide, call or text 988 to reach the{" "}
                      <ExternalLink
                        url="https://988lifeline.org/"
                        cname="org-button"
                        aLabel="Suicide & Crisis Lifeline"
                      >
                        Suicide & Crisis Lifeline
                      </ExternalLink>
                      . It’s available free, 24/7, across the United States.
                    </p>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
          </Modal>
        </div>
      </>
    );
  }
}
export default MutipleButtonsWithModalInSameComponent;
