import Tooltip from '@mui/material/Tooltip/Tooltip'
import PrintIcon from '@mui/icons-material/Print';

export default function Print() {

  const Print = () => {
    window.print()
  }

  return (
    <div role="button" className="print-btn-container">
      <Tooltip
        title="Click to print the quiz results"
        arrow
        componentsProps={{
          tooltip: {
            sx: {
              bgcolor: 'var(--black)',
              '& .MuiTooltip-arrow': {
                color: 'var(--black)',
              },
              color: 'var(--white)',
              width: '122px',
            },
          },
        }}
      >
        <button
          aria-label="Print"
          type="button"
          className="print-btn"
          onClick={Print}
        >
          <PrintIcon/>
        </button>
      </Tooltip>
    </div>
  )
}
