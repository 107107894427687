import { useState } from 'react'
import Dialog from '@mui/material/Dialog'
import CloseIcon from '@mui/icons-material/Close'
import heTipBgImage01 from '../../../assets/images/healthyEating/he-card-img-01.jpg'
import heTipBgImage02 from '../../../assets/images/healthyEating/he-card-img-02.jpg'
import heTipBgImage03 from '../../../assets/images/healthyEating/he-card-img-03.jpg'
import heTipBgImage04 from '../../../assets/images/healthyEating/he-card-img-04.jpg'
import heTipBgImage04b from '../../../assets/images/healthyEating/he-card-img-04b.jpg'
import heTipBgImage05 from '../../../assets/images/healthyEating/he-card-img-05.jpg'
import heTipBgImage06 from '../../../assets/images/healthyEating/he-card-img-06.jpg'
import heTipBgImage07 from '../../../assets/images/healthyEating/he-card-img-07.jpg'
import heTipBgImage08 from '../../../assets/images/healthyEating/he-card-img-08.jpg'
import { HealthyEatingModalContent02 } from './HealthyEatingModalContent02'
import { HealthyEatingModalContent03 } from './HealthyEatingModalContent03'
import { HealthyEatingModalContent04 } from './HealthyEatingModalContent04'
import { HealthyEatingModalContent05 } from './HealthyEatingModalContent05'
import { HealthyEatingModalContent06 } from './HealthyEatingModalContent06'
import { HealthyEatingModalContent07 } from './HealthyEatingModalContent07'
import { HealthyEatingModalContent08 } from './HealthyEatingModalContent08'
import styles from './HealthyEatingModalCards.module.scss'

export const HealthyEatingModalCards = () => {
  const [tipModels, setTipModels] = useState({
    tipModelOne: false,
    tipModelTwo: false,
    tipModelThree: false,
    tipModelFour: false,
    tipModelFive: false,
    tipModelSix: false,
    tipModelSeven: false,
    tipModelEight: false,
  })

  const getTipModelHandler = (tipModelName) => {
    return {
      isOpen: tipModels[tipModelName],
      open: () =>
        setTipModels((state) => ({ ...state, [tipModelName]: true })),
      close: () =>
        setTipModels((state) => ({ ...state, [tipModelName]: false })),
    }
  }

  const tipModelOne = getTipModelHandler('tipModelOne')
  const tipModelTwo = getTipModelHandler('tipModelTwo')
  const tipModelThree = getTipModelHandler('tipModelThree')
  const tipModelFour = getTipModelHandler('tipModelFour')
  const tipModelFive = getTipModelHandler('tipModelFive')
  const tipModelSix = getTipModelHandler('tipModelSix')
  const tipModelSeven = getTipModelHandler('tipModelSeven')
  const tipModelEight = getTipModelHandler('tipModelEight')

  const nextModal = (current, next) => {
    current.close();
    next.open();
  }


  return (
    <section className={styles["he-tips-section"]}>
      <div className={styles["he-tips-flex-wrapper"]}>
        <div className={styles["tip-button-wrapper"]}>
          <button
            style={{backgroundImage: `url(${heTipBgImage01})`}}
            className={ styles.tip }
            onClick={tipModelOne.open}
          >
            <h2>What to Eat and What to Limit</h2>
          </button>
        </div>
        <div className={styles["tip-button-wrapper"]}>
          <button
            style={{backgroundImage: `url(${heTipBgImage02})`}}
            className={ styles.tip }
            onClick={tipModelTwo.open}
          >
            <h2>Better-For-You Carbs</h2>
          </button>
        </div>
        <div className={styles["tip-button-wrapper"]}>
          <button
            style={{backgroundImage: `url(${heTipBgImage03})`}}
            className={ styles.tip }
            onClick={tipModelThree.open}
          >
            <h2>Better-For-You Proteins</h2>
          </button>
        </div>
        <div className={styles["tip-button-wrapper"]}>
          <button
            style={{backgroundImage: `url(${heTipBgImage04})`}}
            className={ styles.tip }
            onClick={tipModelFour.open}
          >
            <h2>Better-For-You Fats</h2>
          </button>
        </div>
        <div className={styles["tip-button-wrapper"]}>
          <button
            style={{backgroundImage: `url(${heTipBgImage05})`}}
            className={ styles.tip }
            onClick={tipModelFive.open}
          >
            <h2>Better-For-You Meals</h2>
          </button>
        </div>
        <div className={styles["tip-button-wrapper"]}>
          <button
            style={{backgroundImage: `url(${heTipBgImage06})`}}
            className={ styles.tip }
            onClick={tipModelSix.open}
          >
            <h2>Reading Nutrition Labels</h2>
          </button>
        </div>
        <div className={styles["tip-button-wrapper"]}>
          <button
            style={{backgroundImage: `url(${heTipBgImage07})`}}
            className={ styles.scale }
            onClick={tipModelSeven.open}
          >
            <h2>Better-For-You Goals</h2>
          </button>
        </div>
        <div className={styles["tip-button-wrapper"]}>
          <button
            style={{backgroundImage: `url(${heTipBgImage08})`}}
            className={ styles.tip }
            onClick={tipModelEight.open}
          >
            <h2>Measuring Portion Sizes</h2>
          </button>
        </div>
      </div>
      <div className={styles["dialog-wrapper"]}>
        <Dialog
          role="dialog"
          open={tipModelOne.isOpen}
          onClose={tipModelOne.close}
          className="dsmes-modal modal-theme-yellow"
          aria-label="What To Eat And What To Limit"
        >
          <button 
           className="modal-close-btn"
           onClick={tipModelOne.close}
           aria-label="Close"
          >
            <CloseIcon className="modal-close-icon" />
          </button>
          <div className="modal-header bcPhys" style={{backgroundImage: `url(${heTipBgImage01})`}}>&nbsp;</div> 
          <div id="modalTitle-1" className="modal-title">
            <h3 tabIndex={0} className="strong">What To Eat And What To Limit</h3>
          </div>
          <div id="modalContent-1" className="modal-content">
            <p>Healthy eating emphasizes fruits, vegetables, whole grains, dairy, and protein.  Some of the foods you know and like may fall in the unhealthy category. Making some changes to your diet can be tough. You’re not alone. There are ways to make change a little easier. Understanding these three important nutrients can help: </p>
            <ul>
              <li>Carbs (carbohydrates)</li>
              <li>Proteins</li>
              <li>Fats</li>
            </ul>
            <p>Check out the next cards for more detailed information about each nutrient.</p>
          </div>
          <div className="modal-footer">
            <button 
              onClick={() => nextModal(tipModelOne, tipModelTwo)}
              className="next"
              aria-label="Next"
            >
              <svg width="17" height="28" viewBox="0 0 17 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 26L14 14L2 2" stroke="white" strokeWidth="3" strokeLinecap="round"/>
              </svg>
            </button>
          </div>
        </Dialog>
        <Dialog
          open={tipModelTwo.isOpen}
          role="dialog"
          onClose={tipModelTwo.close}
          className="dsmes-modal modal-theme-yellow"
          aria-label="Better-For-You Carbs"
        >
          <button 
           className="modal-close-btn"
           onClick={tipModelTwo.close}
           aria-label="Close"
          >
            <CloseIcon className="modal-close-icon" />
          </button>
          <div className="modal-header bcPhys" style={{backgroundImage: `url(${heTipBgImage02})`}}>&nbsp;</div>
          <div id="modalTitle-2" className="modal-title">
            <h3 tabIndex={0} className="strong">Better-For-You Carbs</h3>
          </div>
          <div id="modalContent-2" className="modal-content">
            <HealthyEatingModalContent02 />
          </div>
          <div className="modal-footer">
          <button 
              onClick={() => nextModal(tipModelTwo, tipModelOne)}
              className="prev"
              aria-label="Previous"
            >
              <svg width="17" height="28" viewBox="0 0 17 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15 2L3 14L15 26" stroke="white" strokeWidth="3" strokeLinecap="round"/>
              </svg>
            </button>
            <button 
              onClick={() => nextModal(tipModelTwo, tipModelThree)}
              className="next"
              aria-label="Next"
            >
              <svg width="17" height="28" viewBox="0 0 17 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 26L14 14L2 2" stroke="white" strokeWidth="3" strokeLinecap="round"/>
              </svg>
            </button>
          </div>
        </Dialog>
        <Dialog
          role="dialog"
          open={tipModelThree.isOpen}
          onClose={tipModelThree.close}
          className="dsmes-modal modal-theme-yellow"
          aria-labelledby="Better-For-You Proteins"
        >
          <button 
           className="modal-close-btn"
           onClick={tipModelThree.close}
           aria-label="Close"
          >
            <CloseIcon className="modal-close-icon" />
          </button>
          <div className="modal-header bcPhys" style={{backgroundImage: `url(${heTipBgImage03})`}}>&nbsp;</div>
          <div id="modalTitle-3" className="modal-title">
            <h3 tabIndex={0} className="strong">Better-For-You Proteins</h3>
          </div>
          <div id="modalContent-3" className="modal-content">
            <HealthyEatingModalContent03 />
          </div>
          <div className="modal-footer">
          <button 
              onClick={() => nextModal(tipModelThree, tipModelTwo)}
              className="prev"
              aria-label="Previous"
            >
              <svg width="17" height="28" viewBox="0 0 17 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15 2L3 14L15 26" stroke="white" strokeWidth="3" strokeLinecap="round"/>
              </svg>
            </button>
            <button 
              onClick={() => nextModal(tipModelThree, tipModelFour)}
              className="next"
              aria-label="Next"
            >
              <svg width="17" height="28" viewBox="0 0 17 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 26L14 14L2 2" stroke="white" strokeWidth="3" strokeLinecap="round"/>
              </svg>
            </button>
          </div>
        </Dialog>
        <Dialog
          role="dialog"
          open={tipModelFour.isOpen}
          onClose={tipModelFour.close}
          className="dsmes-modal modal-theme-yellow"
          aria-label="Better-For-You Fats"
        >
          <button 
           className="modal-close-btn"
           onClick={tipModelFour.close}
           aria-label="Close"
          >
            <CloseIcon className="modal-close-icon" />
          </button>
          <div className="modal-header bcPhys" style={{backgroundImage: `url(${heTipBgImage04b})`}}>&nbsp;</div>
          <div id="modalTitle-4" className="modal-title">
            <h3 tabIndex={0} className="strong">Better-For-You Fats</h3>
          </div>
          <div id="modalContent-4" className="modal-content">
            <HealthyEatingModalContent04 />
          </div>
          <div className="modal-footer">
          <button 
              onClick={() => nextModal(tipModelFour, tipModelThree)}
              className="prev"
              aria-label="Previous"
            >
              <svg width="17" height="28" viewBox="0 0 17 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15 2L3 14L15 26" stroke="white" strokeWidth="3" strokeLinecap="round"/>
              </svg>
            </button>
            <button 
              onClick={() => nextModal(tipModelFour, tipModelFive)}
              className="next"
              aria-label="Next"
            >
              <svg width="17" height="28" viewBox="0 0 17 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 26L14 14L2 2" stroke="white" strokeWidth="3" strokeLinecap="round"/>
              </svg>
            </button>
          </div>
        </Dialog>
        <Dialog
          role="dialog"
          open={tipModelFive.isOpen}
          onClose={tipModelFive.close}
          className="dsmes-modal modal-theme-yellow"
          aria-label="Better-For-You Meals"
        >
          <button 
           className="modal-close-btn"
           onClick={tipModelFive.close}
           aria-label="Close"
          >
            <CloseIcon className="modal-close-icon" />
          </button>
          <div className="modal-header bcPhys" style={{backgroundImage: `url(${heTipBgImage05})`}}>&nbsp;</div>
          <div id="modalTitle-5" className="modal-title">
            <h3 tabIndex={0} className="strong">Better-For-You Meals</h3>
          </div>
          <div id="modalContent-5" className="modal-content">
            <HealthyEatingModalContent05 />
          </div>
          <div className="modal-footer">
          <button 
              onClick={() => nextModal(tipModelFive, tipModelFour)}
              className="prev"
              aria-label="Previous"
            >
              <svg width="17" height="28" viewBox="0 0 17 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15 2L3 14L15 26" stroke="white" strokeWidth="3" strokeLinecap="round"/>
              </svg>
            </button>
            <button 
              onClick={() => nextModal(tipModelFive, tipModelSix)}
              className="next"
              aria-label="Next"
            >
              <svg width="17" height="28" viewBox="0 0 17 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 26L14 14L2 2" stroke="white" strokeWidth="3" strokeLinecap="round"/>
              </svg>
            </button>
          </div>
        </Dialog>
        <Dialog
          role="dialog"
          open={tipModelSix.isOpen}
          onClose={tipModelSix.close}
          className="dsmes-modal modal-theme-yellow"
          aria-labelledby="modalTitle-6"
          aria-describedby="modalContent-6"
        >
          <button 
           className="modal-close-btn"
           onClick={tipModelSix.close}
           aria-label="Close"
          >
            <CloseIcon className="modal-close-icon" />
          </button>
          <div className="modal-header bcPhys" style={{backgroundImage: `url(${heTipBgImage06})`}}>&nbsp;</div>
          <div id="modalTitle-6" className="modal-title">
            <h3 tabIndex={0} className="strong">Reading Nutrition Labels</h3>
          </div>
          <div id="modalContent-6" className="modal-content">
            <HealthyEatingModalContent06 />
          </div>
          <div className="modal-footer">
          <button 
              onClick={() => nextModal(tipModelSix, tipModelFive)}
              className="prev"
              aria-label="Previous"
            >
              <svg width="17" height="28" viewBox="0 0 17 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15 2L3 14L15 26" stroke="white" strokeWidth="3" strokeLinecap="round"/>
              </svg>
            </button>
            <button 
              onClick={() => nextModal(tipModelSix, tipModelSeven)}
              className="next"
              aria-label="Next"
            >
              <svg width="17" height="28" viewBox="0 0 17 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 26L14 14L2 2" stroke="white" strokeWidth="3" strokeLinecap="round"/>
              </svg>
            </button>
          </div>
        </Dialog>
        <Dialog
          role="dialog"
          open={tipModelSeven.isOpen}
          onClose={tipModelSeven.close}
          className="dsmes-modal modal-theme-yellow"
          aria-labelledby="modalTitle-7"
          aria-describedby="modalContent-7"
        >
          <button 
           className="modal-close-btn"
           onClick={tipModelSeven.close}
           aria-label="Close"
          >
            <CloseIcon className="modal-close-icon" />
          </button>
          <div className="modal-header bcPhys" style={{backgroundColor: `var(--topaz);`}}>&nbsp;</div>{/*{backgroundImage: `url(${heTipBgImage07})`}*/}
          <div id="modalTitle-7" className="modal-title">
            <h3 tabIndex={0} className="strong">Better-For-You Goals</h3>
          </div>
          <div id="modalContent-7" className="modal-content">
            <HealthyEatingModalContent07 />
          </div>
          <div className="modal-footer">
            <button 
              onClick={() => nextModal(tipModelSeven, tipModelSix)}
              className="prev"
              aria-label="Previous"
            >
              <svg width="17" height="28" viewBox="0 0 17 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15 2L3 14L15 26" stroke="white" strokeWidth="3" strokeLinecap="round"/>
              </svg>
            </button>
            <button 
              onClick={() => nextModal(tipModelSeven, tipModelEight)}
              className="next"
              aria-label="Next"
            >
              <svg width="17" height="28" viewBox="0 0 17 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 26L14 14L2 2" stroke="white" strokeWidth="3" strokeLinecap="round"/>
              </svg>
            </button>
          </div>
        </Dialog>
        <Dialog
          role="dialog"
          open={tipModelEight.isOpen}
          onClose={tipModelEight.close}
          className="dsmes-modal modal-theme-yellow"
          aria-labelledby="modalTitle-8"
          aria-describedby="modalContent-8"
        >
          <button 
           className="modal-close-btn"
           onClick={tipModelEight.close}
           aria-label="Close"
          >
            <CloseIcon className="modal-close-icon" />
          </button>
          <div className="modal-header bcPhys mtPortion" style={{backgroundImage: `url(${heTipBgImage08})`}}>&nbsp;</div>
          <div id="modalTitle-8" className="modal-title">
            <h3 tabIndex={0} className="strong">Measuring Portion Sizes</h3>
          </div>
          <div id="modalContent-8" className="modal-content">
            <HealthyEatingModalContent08 />
          </div>
          <div className="modal-footer">
            <button 
              onClick={() => nextModal(tipModelEight, tipModelSix)}
              className="prev"
              aria-label="Previous"
            >
              <svg width="17" height="28" viewBox="0 0 17 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15 2L3 14L15 26" stroke="white" strokeWidth="3" strokeLinecap="round"/>
              </svg>
            </button>
            <button 
              onClick={() => nextModal(tipModelEight, tipModelOne)}
              className="next"
              aria-label="Next"
            >
              <svg width="17" height="28" viewBox="0 0 17 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 26L14 14L2 2" stroke="white" strokeWidth="3" strokeLinecap="round"/>
              </svg>
            </button>
          </div>
        </Dialog>
      </div>
    </section>
  )
}