import RoutePaths from "../../../routes";
import useComponentVisible from "./useComponentVisible";
import Collapse from "@mui/material/Collapse";
import { useLocation } from "react-router-dom";

export default function NavMenu() {
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);
  const location = useLocation();

  const toggleMainMenu = (e) => {
    setIsComponentVisible(!isComponentVisible);
  };

  return (
    <div ref={ref} className="main-menu-wrapper">
      <button
        tabIndex="2"
        aria-expanded={isComponentVisible ? "true" : "false"}
        onClick={(e) => toggleMainMenu(e)}
        aria-label="Main Menu"
        className={`${"main-menu-btn"} ${isComponentVisible ? "active" : ""}`}
      >
        <span className="topics-label">Topics</span>
        <svg
          role="menu"
          className="menu-icon"
          xmlns="http://www.w3.org/2000/svg"
          width="48"
          height="48"
          viewBox="0 0 200 200"
        >
          <g strokeWidth="6.5" strokeLinecap="round">
            <path
              d="M72 82.286h28.75"
              fill="#009100"
              fillRule="evenodd"
              stroke="var(--dark-charcoal)"
            />
            <path
              d="M100.75 103.714l72.482-.143c.043 39.398-32.284 71.434-72.16 71.434-39.878 0-72.204-32.036-72.204-71.554"
              fill="none"
              stroke="var(--dark-charcoal)"
            />
            <path
              d="M72 125.143h28.75"
              fill="#009100"
              fillRule="evenodd"
              stroke="var(--dark-charcoal)"
            />
            <path
              d="M100.75 103.714l-71.908-.143c.026-39.638 32.352-71.674 72.23-71.674 39.876 0 72.203 32.036 72.203 71.554"
              fill="none"
              stroke="var(--dark-charcoal)"
            />
            <path
              d="M100.75 82.286h28.75"
              fill="#009100"
              fillRule="evenodd"
              stroke="var(--dark-charcoal)"
            />
            <path
              d="M100.75 125.143h28.75"
              fill="#009100"
              fillRule="evenodd"
              stroke="var(--dark-charcoal)"
            />
          </g>
        </svg>
      </button>
      <Collapse in={isComponentVisible} className="main-menu-panel">
        <h2 className="h4 bold topics-title">Topics</h2>
        <menu className="main-menu">
          <li
            className={
              location.pathname.includes("/monitoring-diabetes")
                ? "current"
                : ""
            }
          >
            <a href={RoutePaths.MonitoringDiabetes}>Monitoring Diabetes</a>
          </li>
          <li
            className={
              location.pathname.includes("/reducing-risk") ? "current" : ""
            }
          >
            <a href={RoutePaths.ReducingRisk}>Reducing Risk of Complications</a>
          </li>
          <li
            className={
              location.pathname.includes("/healthy-eating-cooking")
                ? "current"
                : ""
            }
          >
            <a href={RoutePaths.HealthyEating}>Creative Eating and Cooking</a>
          </li>
          <li
            className={
              location.pathname.includes("/increasing-physical-activity")
                ? "current"
                : ""
            }
          >
            <a href={RoutePaths.PhysicalActivity}>
              Increasing Physical Activity
            </a>
          </li>
          <li
            className={
              location.pathname.includes("/quality-care") ? "current" : ""
            }
          >
            <a href={RoutePaths.QualityCare}>Getting Quality Care</a>
          </li>
          <li
            className={
              location.pathname.includes("/finding-provider") ? "current" : ""
            }
          >
            <a href={RoutePaths.FindingProvider}>Finding the Right Provider</a>
          </li>
          <li
            className={
              location.pathname.includes("/creating-community") ? "current" : ""
            }
          >
            <a href={RoutePaths.CreatingCommunity}>
              Creating Community Support
            </a>
          </li>
          <li
            className={
              location.pathname.includes("/living-diabetes") ? "current" : ""
            }
          >
            <a href={RoutePaths.CopingDiabetes}>Living with Diabetes</a>
          </li>
          <li
            className={
              location.pathname.includes("/connecting-to-DSMES")
                ? "current"
                : ""
            }
          >
            <a href={RoutePaths.ConnectingToDSMES}>Connect to a Specialist</a>
          </li>
        </menu>
      </Collapse>
    </div>
  );
}
