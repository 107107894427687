import SupportTopics from './SupportTopics/SupportTopics'
import './PageNotFound.scss'

export default function PageNotFound() {
  return (
    <div className="page-not-found">
      <div className="page-not-found-container">
        <h1>Page Not Found</h1>
        <p>Oops! We can't seem to find the page you were looking for. Please try one of the Support Topics below.</p>
      </div>
      <SupportTopics />
    </div>
  )
}
