import { useState, useEffect, useRef } from 'react'
import Fade from '@mui/material/Fade';
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import PauseIcon from '@mui/icons-material/Pause'
import image1 from '../../../../assets/images/home-quote-1.jpg'
import image2 from '../../../../assets/images/home-quote-2.jpg'
import image3 from '../../../../assets/images/home-quote-3.jpg'
import image4 from '../../../../assets/images/home-quote-4.jpg'
import image5 from '../../../../assets/images/home-quote-5.jpg'
import style from './Quotes.module.scss'

function Quotes() {
  const [value, setValue] = useState('1')
  const [scroll, setScroll] = useState('play')

  useEffect(() => {
    if(scroll === 'play') {
      const interval = setInterval(() => {
        if (parseInt(value) + 1 === 6)
          setValue('1')
        else
          setValue((parseInt(value) + 1).toString())
      }, 5000)
      return () => clearInterval(interval)
    }
    if (scroll === 'paused') return
  })

  const PauseScroll = (e) => {
    if (scroll === 'paused') {
      setScroll('play')
      e.target.setAttribute("aria-label", "currently playing, click to pause")
      e.target.setAttribute("aria-pressed", "true")
    }
    if (scroll === 'play') {
      setScroll('paused')
      e.target.setAttribute("aria-label", "currently paused, click to play")
      e.target.setAttribute("aria-pressed", "false")
    }
  }

  const containerRef = useRef(null);

  return (
    <>
      <div className={style["quote-card"]}>        
        <div className={style["fade-container"]} ref={containerRef}>
          <Fade in={value === "1"}>
            <div className={style["quote-slide-wrapper"]}>
              <img role="presentation" src={image1} alt="" />
              <p><svg width="30" height="26" viewBox="0 0 30 26" fill="none" xmlns="http://www.w3.org/2000/svg" className={style["open-quote"]}>
          <path d="M28.1234 0C29.3773 0 30 0.588779 30 1.77487C30 2.39778 29.7612 2.88417 29.275 3.23402C25.6668 5.94753 23.756 9.10475 23.5428 12.7142C25.6241 12.8508 27.2192 13.5505 28.3367 14.7963C29.4456 15.9738 30 17.4671 30 19.2761C30 21.0851 29.3773 22.5443 28.1234 23.8584C26.8695 25.1127 25.3426 25.7356 23.5428 25.7356C21.743 25.7356 20.1734 25.0103 18.8513 23.5512C17.5291 22.092 16.8723 19.8308 16.8723 16.7759C16.8723 13.7211 17.913 10.5895 19.9943 7.39814C22.0756 4.20679 24.302 1.9114 26.6648 0.520515C27.2192 0.170661 27.7054 0 28.1234 0V0ZM11.2511 0C12.505 0 13.1277 0.588779 13.1277 1.77487C13.1277 2.39778 12.8888 2.88417 12.4026 3.23402C8.79443 5.94753 6.88371 9.10475 6.67046 12.7142C8.75178 12.8508 10.3469 13.5505 11.4643 14.7963C12.5732 15.9738 13.1277 17.4671 13.1277 19.2761C13.1277 21.0851 12.505 22.5443 11.2511 23.8584C9.99716 25.1127 8.47029 25.7356 6.67046 25.7356C4.87063 25.7356 3.30111 25.0103 1.97896 23.5512C0.65681 22.092 0 19.8308 0 16.7759C0 13.7211 1.04066 10.5895 3.12198 7.39814C5.21183 4.20679 7.42963 1.9114 9.79244 0.520515C10.3469 0.170661 10.8331 0 11.2511 0V0Z" />
        </svg>
                <span>You are your best thing.<br/><br/><strong>-Toni Morrison</strong></span>
          <svg width="30" height="26" viewBox="0 0 30 26" fill="none" xmlns="http://www.w3.org/2000/svg" className={style["close-quote"]}>
          <path d="M1.87661 25.7356C0.6227 25.7356 0 25.1468 0 23.9607C0 23.3378 0.238819 22.8515 0.725029 22.5016C4.33322 19.7881 6.24397 16.6309 6.45722 13.0214C4.3759 12.8849 2.78079 12.1852 1.66336 10.9393C0.554461 9.76179 0 8.26851 0 6.4595C0 4.6505 0.6227 3.19135 1.87661 1.87726C3.13052 0.622909 4.65739 0 6.45722 0C8.25705 0 9.82657 0.725306 11.1487 2.18445C12.4709 3.6436 13.1277 5.90486 13.1277 8.95968C13.1277 12.0145 12.087 15.1461 10.0057 18.3375C7.92435 21.5288 5.69802 23.8242 3.33521 25.2151C2.78076 25.565 2.29458 25.7356 1.87661 25.7356ZM18.7489 25.7356C17.495 25.7356 16.8724 25.1468 16.8724 23.9607C16.8724 23.3378 17.1112 22.8515 17.5974 22.5016C21.2056 19.7881 23.1163 16.6309 23.3295 13.0214C21.2482 12.8849 19.6531 12.1852 18.5357 10.9393C17.4268 9.76179 16.8724 8.26851 16.8724 6.4595C16.8724 4.6505 17.495 3.19135 18.7489 1.87726C20.0028 0.622909 21.5297 0 23.3295 0C25.1294 0 26.6989 0.725306 28.021 2.18445C29.3432 3.6436 30 5.90486 30 8.95968C30 12.0145 28.9594 15.1461 26.878 18.3375C24.7967 21.5288 22.5704 23.8242 20.2076 25.2151C19.6531 25.565 19.1669 25.7356 18.7489 25.7356Z" />
        </svg>
              </p>
            </div>
          </Fade>
          <Fade in={value === "2"}>
            <div className={style["quote-slide-wrapper"]}>
              <img role="presentation" src={image2} alt="" />
              <p>
              <svg width="30" height="26" viewBox="0 0 30 26" fill="none" xmlns="http://www.w3.org/2000/svg" className={style["open-quote"]}>
          <path d="M28.1234 0C29.3773 0 30 0.588779 30 1.77487C30 2.39778 29.7612 2.88417 29.275 3.23402C25.6668 5.94753 23.756 9.10475 23.5428 12.7142C25.6241 12.8508 27.2192 13.5505 28.3367 14.7963C29.4456 15.9738 30 17.4671 30 19.2761C30 21.0851 29.3773 22.5443 28.1234 23.8584C26.8695 25.1127 25.3426 25.7356 23.5428 25.7356C21.743 25.7356 20.1734 25.0103 18.8513 23.5512C17.5291 22.092 16.8723 19.8308 16.8723 16.7759C16.8723 13.7211 17.913 10.5895 19.9943 7.39814C22.0756 4.20679 24.302 1.9114 26.6648 0.520515C27.2192 0.170661 27.7054 0 28.1234 0V0ZM11.2511 0C12.505 0 13.1277 0.588779 13.1277 1.77487C13.1277 2.39778 12.8888 2.88417 12.4026 3.23402C8.79443 5.94753 6.88371 9.10475 6.67046 12.7142C8.75178 12.8508 10.3469 13.5505 11.4643 14.7963C12.5732 15.9738 13.1277 17.4671 13.1277 19.2761C13.1277 21.0851 12.505 22.5443 11.2511 23.8584C9.99716 25.1127 8.47029 25.7356 6.67046 25.7356C4.87063 25.7356 3.30111 25.0103 1.97896 23.5512C0.65681 22.092 0 19.8308 0 16.7759C0 13.7211 1.04066 10.5895 3.12198 7.39814C5.21183 4.20679 7.42963 1.9114 9.79244 0.520515C10.3469 0.170661 10.8331 0 11.2511 0V0Z" /></svg>
          <span>Caring for myself is not self-indulgent; caring for myself is an act of survival.<br/><br/><strong>Audre Lorde</strong></span>
              <svg width="30" height="26" viewBox="0 0 30 26" fill="none" xmlns="http://www.w3.org/2000/svg" className={style["close-quote"]}>
          <path d="M1.87661 25.7356C0.6227 25.7356 0 25.1468 0 23.9607C0 23.3378 0.238819 22.8515 0.725029 22.5016C4.33322 19.7881 6.24397 16.6309 6.45722 13.0214C4.3759 12.8849 2.78079 12.1852 1.66336 10.9393C0.554461 9.76179 0 8.26851 0 6.4595C0 4.6505 0.6227 3.19135 1.87661 1.87726C3.13052 0.622909 4.65739 0 6.45722 0C8.25705 0 9.82657 0.725306 11.1487 2.18445C12.4709 3.6436 13.1277 5.90486 13.1277 8.95968C13.1277 12.0145 12.087 15.1461 10.0057 18.3375C7.92435 21.5288 5.69802 23.8242 3.33521 25.2151C2.78076 25.565 2.29458 25.7356 1.87661 25.7356ZM18.7489 25.7356C17.495 25.7356 16.8724 25.1468 16.8724 23.9607C16.8724 23.3378 17.1112 22.8515 17.5974 22.5016C21.2056 19.7881 23.1163 16.6309 23.3295 13.0214C21.2482 12.8849 19.6531 12.1852 18.5357 10.9393C17.4268 9.76179 16.8724 8.26851 16.8724 6.4595C16.8724 4.6505 17.495 3.19135 18.7489 1.87726C20.0028 0.622909 21.5297 0 23.3295 0C25.1294 0 26.6989 0.725306 28.021 2.18445C29.3432 3.6436 30 5.90486 30 8.95968C30 12.0145 28.9594 15.1461 26.878 18.3375C24.7967 21.5288 22.5704 23.8242 20.2076 25.2151C19.6531 25.565 19.1669 25.7356 18.7489 25.7356Z" />
        </svg>
              </p>
            </div>
          </Fade>
          <Fade in={value === "3"}>
            <div className={style["quote-slide-wrapper"]}>
              <img role="presentation" src={image3} alt="" />
          <p>
              <svg width="30" height="26" viewBox="0 0 30 26" fill="none" xmlns="http://www.w3.org/2000/svg" className={style["open-quote"]}>
          <path d="M28.1234 0C29.3773 0 30 0.588779 30 1.77487C30 2.39778 29.7612 2.88417 29.275 3.23402C25.6668 5.94753 23.756 9.10475 23.5428 12.7142C25.6241 12.8508 27.2192 13.5505 28.3367 14.7963C29.4456 15.9738 30 17.4671 30 19.2761C30 21.0851 29.3773 22.5443 28.1234 23.8584C26.8695 25.1127 25.3426 25.7356 23.5428 25.7356C21.743 25.7356 20.1734 25.0103 18.8513 23.5512C17.5291 22.092 16.8723 19.8308 16.8723 16.7759C16.8723 13.7211 17.913 10.5895 19.9943 7.39814C22.0756 4.20679 24.302 1.9114 26.6648 0.520515C27.2192 0.170661 27.7054 0 28.1234 0V0ZM11.2511 0C12.505 0 13.1277 0.588779 13.1277 1.77487C13.1277 2.39778 12.8888 2.88417 12.4026 3.23402C8.79443 5.94753 6.88371 9.10475 6.67046 12.7142C8.75178 12.8508 10.3469 13.5505 11.4643 14.7963C12.5732 15.9738 13.1277 17.4671 13.1277 19.2761C13.1277 21.0851 12.505 22.5443 11.2511 23.8584C9.99716 25.1127 8.47029 25.7356 6.67046 25.7356C4.87063 25.7356 3.30111 25.0103 1.97896 23.5512C0.65681 22.092 0 19.8308 0 16.7759C0 13.7211 1.04066 10.5895 3.12198 7.39814C5.21183 4.20679 7.42963 1.9114 9.79244 0.520515C10.3469 0.170661 10.8331 0 11.2511 0V0Z" /></svg>
                <span>I always try to remember that I’ll be the best me I can be if I prioritize myself.<br/><br/><strong>Michelle Obama</strong></span>
              <svg width="30" height="26" viewBox="0 0 30 26" fill="none" xmlns="http://www.w3.org/2000/svg" className={style["close-quote"]}>
          <path d="M1.87661 25.7356C0.6227 25.7356 0 25.1468 0 23.9607C0 23.3378 0.238819 22.8515 0.725029 22.5016C4.33322 19.7881 6.24397 16.6309 6.45722 13.0214C4.3759 12.8849 2.78079 12.1852 1.66336 10.9393C0.554461 9.76179 0 8.26851 0 6.4595C0 4.6505 0.6227 3.19135 1.87661 1.87726C3.13052 0.622909 4.65739 0 6.45722 0C8.25705 0 9.82657 0.725306 11.1487 2.18445C12.4709 3.6436 13.1277 5.90486 13.1277 8.95968C13.1277 12.0145 12.087 15.1461 10.0057 18.3375C7.92435 21.5288 5.69802 23.8242 3.33521 25.2151C2.78076 25.565 2.29458 25.7356 1.87661 25.7356ZM18.7489 25.7356C17.495 25.7356 16.8724 25.1468 16.8724 23.9607C16.8724 23.3378 17.1112 22.8515 17.5974 22.5016C21.2056 19.7881 23.1163 16.6309 23.3295 13.0214C21.2482 12.8849 19.6531 12.1852 18.5357 10.9393C17.4268 9.76179 16.8724 8.26851 16.8724 6.4595C16.8724 4.6505 17.495 3.19135 18.7489 1.87726C20.0028 0.622909 21.5297 0 23.3295 0C25.1294 0 26.6989 0.725306 28.021 2.18445C29.3432 3.6436 30 5.90486 30 8.95968C30 12.0145 28.9594 15.1461 26.878 18.3375C24.7967 21.5288 22.5704 23.8242 20.2076 25.2151C19.6531 25.565 19.1669 25.7356 18.7489 25.7356Z" />
        </svg>
              </p>
            </div>
          </Fade>
          <Fade in={value === "4"}>
            <div className={style["quote-slide-wrapper"]}>
              <img role="presentation" src={image4} alt="" />
              <p>
              <svg width="30" height="26" viewBox="0 0 30 26" fill="none" xmlns="http://www.w3.org/2000/svg" className={style["open-quote"]}>
          <path d="M28.1234 0C29.3773 0 30 0.588779 30 1.77487C30 2.39778 29.7612 2.88417 29.275 3.23402C25.6668 5.94753 23.756 9.10475 23.5428 12.7142C25.6241 12.8508 27.2192 13.5505 28.3367 14.7963C29.4456 15.9738 30 17.4671 30 19.2761C30 21.0851 29.3773 22.5443 28.1234 23.8584C26.8695 25.1127 25.3426 25.7356 23.5428 25.7356C21.743 25.7356 20.1734 25.0103 18.8513 23.5512C17.5291 22.092 16.8723 19.8308 16.8723 16.7759C16.8723 13.7211 17.913 10.5895 19.9943 7.39814C22.0756 4.20679 24.302 1.9114 26.6648 0.520515C27.2192 0.170661 27.7054 0 28.1234 0V0ZM11.2511 0C12.505 0 13.1277 0.588779 13.1277 1.77487C13.1277 2.39778 12.8888 2.88417 12.4026 3.23402C8.79443 5.94753 6.88371 9.10475 6.67046 12.7142C8.75178 12.8508 10.3469 13.5505 11.4643 14.7963C12.5732 15.9738 13.1277 17.4671 13.1277 19.2761C13.1277 21.0851 12.505 22.5443 11.2511 23.8584C9.99716 25.1127 8.47029 25.7356 6.67046 25.7356C4.87063 25.7356 3.30111 25.0103 1.97896 23.5512C0.65681 22.092 0 19.8308 0 16.7759C0 13.7211 1.04066 10.5895 3.12198 7.39814C5.21183 4.20679 7.42963 1.9114 9.79244 0.520515C10.3469 0.170661 10.8331 0 11.2511 0V0Z" /></svg>
          <span>I finally realized that being grateful to my body was key to giving more love to myself! <br/><br/><strong>Oprah Winfrey</strong></span>
              <svg width="30" height="26" viewBox="0 0 30 26" fill="none" xmlns="http://www.w3.org/2000/svg" className={style["close-quote"]}>
          <path d="M1.87661 25.7356C0.6227 25.7356 0 25.1468 0 23.9607C0 23.3378 0.238819 22.8515 0.725029 22.5016C4.33322 19.7881 6.24397 16.6309 6.45722 13.0214C4.3759 12.8849 2.78079 12.1852 1.66336 10.9393C0.554461 9.76179 0 8.26851 0 6.4595C0 4.6505 0.6227 3.19135 1.87661 1.87726C3.13052 0.622909 4.65739 0 6.45722 0C8.25705 0 9.82657 0.725306 11.1487 2.18445C12.4709 3.6436 13.1277 5.90486 13.1277 8.95968C13.1277 12.0145 12.087 15.1461 10.0057 18.3375C7.92435 21.5288 5.69802 23.8242 3.33521 25.2151C2.78076 25.565 2.29458 25.7356 1.87661 25.7356ZM18.7489 25.7356C17.495 25.7356 16.8724 25.1468 16.8724 23.9607C16.8724 23.3378 17.1112 22.8515 17.5974 22.5016C21.2056 19.7881 23.1163 16.6309 23.3295 13.0214C21.2482 12.8849 19.6531 12.1852 18.5357 10.9393C17.4268 9.76179 16.8724 8.26851 16.8724 6.4595C16.8724 4.6505 17.495 3.19135 18.7489 1.87726C20.0028 0.622909 21.5297 0 23.3295 0C25.1294 0 26.6989 0.725306 28.021 2.18445C29.3432 3.6436 30 5.90486 30 8.95968C30 12.0145 28.9594 15.1461 26.878 18.3375C24.7967 21.5288 22.5704 23.8242 20.2076 25.2151C19.6531 25.565 19.1669 25.7356 18.7489 25.7356Z" />
        </svg></p>
            </div>
          </Fade>
          <Fade in={value === "5"}>
            <div className={style["quote-slide-wrapper"]}>
              <img role="presentation" src={image5} alt="" />
              <p>
              <svg width="30" height="26" viewBox="0 0 30 26" fill="none" xmlns="http://www.w3.org/2000/svg" className={style["open-quote"]}>
          <path d="M28.1234 0C29.3773 0 30 0.588779 30 1.77487C30 2.39778 29.7612 2.88417 29.275 3.23402C25.6668 5.94753 23.756 9.10475 23.5428 12.7142C25.6241 12.8508 27.2192 13.5505 28.3367 14.7963C29.4456 15.9738 30 17.4671 30 19.2761C30 21.0851 29.3773 22.5443 28.1234 23.8584C26.8695 25.1127 25.3426 25.7356 23.5428 25.7356C21.743 25.7356 20.1734 25.0103 18.8513 23.5512C17.5291 22.092 16.8723 19.8308 16.8723 16.7759C16.8723 13.7211 17.913 10.5895 19.9943 7.39814C22.0756 4.20679 24.302 1.9114 26.6648 0.520515C27.2192 0.170661 27.7054 0 28.1234 0V0ZM11.2511 0C12.505 0 13.1277 0.588779 13.1277 1.77487C13.1277 2.39778 12.8888 2.88417 12.4026 3.23402C8.79443 5.94753 6.88371 9.10475 6.67046 12.7142C8.75178 12.8508 10.3469 13.5505 11.4643 14.7963C12.5732 15.9738 13.1277 17.4671 13.1277 19.2761C13.1277 21.0851 12.505 22.5443 11.2511 23.8584C9.99716 25.1127 8.47029 25.7356 6.67046 25.7356C4.87063 25.7356 3.30111 25.0103 1.97896 23.5512C0.65681 22.092 0 19.8308 0 16.7759C0 13.7211 1.04066 10.5895 3.12198 7.39814C5.21183 4.20679 7.42963 1.9114 9.79244 0.520515C10.3469 0.170661 10.8331 0 11.2511 0V0Z" /></svg>
          <span>You are the sum total of everything you've ever seen, heard, eaten, smelled, been told, forgot—it's all there. Everything influences each of us, and because of that I try to make sure that my experiences are positive. <br/><br/><strong>Maya Angelou</strong></span>
              <svg width="30" height="26" viewBox="0 0 30 26" fill="none" xmlns="http://www.w3.org/2000/svg" className={style["close-quote"]}>
          <path d="M1.87661 25.7356C0.6227 25.7356 0 25.1468 0 23.9607C0 23.3378 0.238819 22.8515 0.725029 22.5016C4.33322 19.7881 6.24397 16.6309 6.45722 13.0214C4.3759 12.8849 2.78079 12.1852 1.66336 10.9393C0.554461 9.76179 0 8.26851 0 6.4595C0 4.6505 0.6227 3.19135 1.87661 1.87726C3.13052 0.622909 4.65739 0 6.45722 0C8.25705 0 9.82657 0.725306 11.1487 2.18445C12.4709 3.6436 13.1277 5.90486 13.1277 8.95968C13.1277 12.0145 12.087 15.1461 10.0057 18.3375C7.92435 21.5288 5.69802 23.8242 3.33521 25.2151C2.78076 25.565 2.29458 25.7356 1.87661 25.7356ZM18.7489 25.7356C17.495 25.7356 16.8724 25.1468 16.8724 23.9607C16.8724 23.3378 17.1112 22.8515 17.5974 22.5016C21.2056 19.7881 23.1163 16.6309 23.3295 13.0214C21.2482 12.8849 19.6531 12.1852 18.5357 10.9393C17.4268 9.76179 16.8724 8.26851 16.8724 6.4595C16.8724 4.6505 17.495 3.19135 18.7489 1.87726C20.0028 0.622909 21.5297 0 23.3295 0C25.1294 0 26.6989 0.725306 28.021 2.18445C29.3432 3.6436 30 5.90486 30 8.95968C30 12.0145 28.9594 15.1461 26.878 18.3375C24.7967 21.5288 22.5704 23.8242 20.2076 25.2151C19.6531 25.565 19.1669 25.7356 18.7489 25.7356Z" />
        </svg></p>
            </div>
          </Fade>
        </div>
        
        <div className={style["button-row"]}>
          <button 
            aria-label="view quote 1" 
            onClick={() => setValue('1')} 
            className={style.btnQuote}
            data-active-quote={(value === "1")}
          >
            &#8226;
          </button>
          <button 
            aria-label="view quote 2" 
            onClick={() => setValue('2')} 
            className={style.btnQuote}
            data-active-quote={(value === "2")}
          >
            &#8226;
          </button>
          <button 
            aria-label="view quote 3" 
            onClick={() => setValue('3')} 
            className={style.btnQuote}
            data-active-quote={(value === "3")}
          >
            &#8226;
          </button>
          <button 
            aria-label="view quote 4" 
            onClick={() => setValue('4')} 
            className={style.btnQuote}
            data-active-quote={(value === "4")}
          >
            &#8226;
          </button>
          <button 
            aria-label="view quote 5" 
            onClick={() => setValue('5')} 
            className={style.btnQuote}
            data-active-quote={(value === "5")}
          >
            &#8226;
          </button>
          <button
            aria-label="play and pause"
            aria-pressed="true"
            onClick={PauseScroll}
          >
            {scroll === 'play' ? (
              <PauseIcon />
            ) : (
              <PlayArrowIcon />
            )}
          </button>
        </div>
      </div>
    </>
  )
}

export default Quotes
