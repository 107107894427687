import { useState } from "react";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import tipModalHeaderImage01 from "../../../assets/images/monitoringDiabetes/monitoringD-modal-takingCharge.jpg";
import tipModalHeaderImage02 from "../../../assets/images/monitoringDiabetes/monitoringD-modal-monitoring.jpg";
import tipModalHeaderImage03 from "../../../assets/images/monitoringDiabetes/monitoringD-modal-payingAttention.jpg";
import tipModalHeaderImage04 from "../../../assets/images/monitoringDiabetes/monitoringD-modal-keepingTrack.jpg";
import tipModalHeaderImage05 from "../../../assets/images/monitoringDiabetes/monitoringD-modal-checkingBP.jpg";
import tipModalHeaderImage06 from "../../../assets/images/monitoringDiabetes/monitoringD-modal-overcoming.jpg";
import tipModalHeaderImage07 from "../../../assets/images/monitoringDiabetes/monitoringD-modal-CGM.jpeg";
import { MonitoringDiabetesModalContent04 } from "./MonitoringDiabetesModalContent04";
import { ExternalLink } from "../../ExternalLink/ExternalLink";
export const MonitoringDiabetesTips = () => {
  const [tipModels, setTipModels] = useState({
    tipModelOne: false,
    tipModelTwo: false,
    tipModelThree: false,
    tipModelFour: false,
    tipModelFive: false,
    tipModelSix: false,
    tipModelSeven: false,
  });

  const getTipModelHandler = (tipModelName) => {
    return {
      isOpen: tipModels[tipModelName],
      open: () => setTipModels((state) => ({ ...state, [tipModelName]: true })),
      close: () =>
        setTipModels((state) => ({ ...state, [tipModelName]: false })),
    };
  };

  const tipModelOne = getTipModelHandler("tipModelOne");
  const tipModelTwo = getTipModelHandler("tipModelTwo");
  const tipModelThree = getTipModelHandler("tipModelThree");
  const tipModelFour = getTipModelHandler("tipModelFour");
  const tipModelFive = getTipModelHandler("tipModelFive");
  const tipModelSix = getTipModelHandler("tipModelSix");
  const tipModelSeven = getTipModelHandler("tipModelSeven");

  const specialBGStyle = {
    // backgroundPosition: '0 -163px',
    backgroundImage: `url(${tipModalHeaderImage06})`,
  };
  const nextModal = (current, next) => {
    current.close();
    next.open();
  };

  return (
    <section className="md-tips-section">
      <div className="md-tips-flex-wrapper">
        <div className="tip-button-wrapper">
          <button
            style={{ backgroundImage: `url(${tipModalHeaderImage01})` }}
            className="tip"
            onClick={tipModelOne.open}
          >
            <h2>Taking Charge of Your Blood Sugar</h2>
          </button>
        </div>
        <div className="tip-button-wrapper">
          <button
            style={{ backgroundImage: `url(${tipModalHeaderImage02})` }}
            className="tip"
            onClick={tipModelTwo.open}
          >
            <h2>Monitoring Your A1C</h2>
          </button>
        </div>
        <div className="tip-button-wrapper">
          <button
            style={{ backgroundImage: `url(${tipModalHeaderImage03})` }}
            className="tip"
            onClick={tipModelThree.open}
          >
            <h2>Paying Attention to Your Stress Level</h2>
          </button>
        </div>
        <div className="tip-button-wrapper">
          <button
            style={{ backgroundImage: `url(${tipModalHeaderImage04})` }}
            className="tip"
            onClick={tipModelFour.open}
          >
            <h2>Keeping Track of What You Eat</h2>
          </button>
        </div>
        <div className="tip-button-wrapper">
          <button
            style={{ backgroundImage: `url(${tipModalHeaderImage05})` }}
            className="tip"
            onClick={tipModelFive.open}
          >
            <h2>Checking Your Blood Pressure and Cholesterol</h2>
          </button>
        </div>
        <div className="tip-button-wrapper">
          <button
            style={specialBGStyle}
            className="tip"
            onClick={tipModelSix.open}
          >
            <h2>Overcoming Challenges</h2>
          </button>
        </div>
        <div className="tip-button-wrapper">
          <button
            style={{ backgroundImage: `url(${tipModalHeaderImage07})` }}
            className="tip"
            onClick={tipModelSeven.open}
          >
            <h2>Continuous Glucose Monitors (CGM)</h2>
          </button>
        </div>
      </div>
      <div className="dialog-wrapper">
        <Dialog
          tabIndex={1}
          role="dialog"
          aria-label="Taking Charge of Your Blood Sugar"
          open={tipModelOne.isOpen}
          onClose={tipModelOne.close}
          className="dsmes-modal md-modal modal-theme-blue"
        >
          <button
            tabIndex={2}
            aria-label="close"
            className="modal-close-btn"
            onClick={tipModelOne.close}
          >
            <CloseIcon role="decorative" className="modal-close-icon" />
          </button>
          <div
            aria-label="header"
            className="modal-header mtm1"
            style={{ backgroundImage: `url(${tipModalHeaderImage01})` }}
          >
            &nbsp;
          </div>
          <div id="modalTitle-1" className="modal-title">
            <h3 tabIndex={3} className="strong">
              Taking Charge of Your Blood Sugar
            </h3>
          </div>
          <div tabIndex={4} id="modalContent-1" className="modal-content">
            <p>
              What’s “blood sugar,” and why is it important? Blood sugar, or
              just “sugar” as some might say, is the sugar (glucose) in your
              bloodstream from foods you eat. To make sure that you maintain a
              healthy blood sugar range, it’s important to monitor it daily.
              Typical times to check your blood sugar include when you first
              wake up, before a meal, two hours after a meal, and at bedtime.
            </p>
            <p>
              How do you check your blood sugar levels? Use a blood sugar meter
              (glucometer), which tells you the amount of sugar in your blood by
              taking a drop of blood, often from your fingertip. You can buy a
              glucometer at your local pharmacy.
            </p>
            <p>
              Get instructions and tips on{" "}
              <a
                class="blueLink"
                href="https://www.cdc.gov/diabetes/managing/manage-blood-sugar.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                monitoring your blood sugar.
              </a>
              Your health-care provider can give you more tips on which
              glucometer is right for you as well as on using your glucometer.
              They can also help you know what your blood sugar goal should be
              and how often you should check it.
            </p>
          </div>
          <div className="modal-footer">
            <button
              onClick={() => nextModal(tipModelOne, tipModelTwo)}
              className="next"
              aria-label="Next"
              tabIndex={5}
            >
              <svg
                role="decorative"
                width="17"
                height="28"
                viewBox="0 0 17 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2 26L14 14L2 2"
                  stroke="white"
                  strokeWidth="3"
                  strokeLinecap="round"
                />
              </svg>
            </button>
          </div>
        </Dialog>
        <Dialog
          tabIndex={1}
          role="dialog"
          open={tipModelTwo.isOpen}
          onClose={tipModelTwo.close}
          aria-label="Monitoring Your A1C"
          className="dsmes-modal md-modal modal-theme-blue"
        >
          <button
            tabIndex={2}
            className="modal-close-btn"
            onClick={tipModelTwo.close}
            aria-label="Close"
          >
            <CloseIcon className="modal-close-icon" />
          </button>
          <div
            className="modal-header"
            style={{ backgroundImage: `url(${tipModalHeaderImage02})` }}
          >
            &nbsp;
          </div>
          <div id="modalTitle-2" className="modal-title">
            <h3 tabIndex={3} className="strong">
              Monitoring Your A1C
            </h3>
          </div>
          <div tabIndex={4} id="modalContent-2" className="modal-content">
            <p>
              Another way to check your blood sugar levels is an A1C test. An
              A1C test shows your blood sugar levels over the past three months.{" "}
            </p>
            <p>
              The A1C test is done for you in a health-care provider's office. A
              staff member will get a small sample of your blood from your
              finger or arm. Try to get an A1C test at least twice a year and
              more often if you change medicines or have other health issues.{" "}
            </p>
            <p>
              The goal for many people with diabetes is an A1C below 7%. The
              best way to understand your normal range is to talk to your
              health-care provider or diabetes care and education specialist
              about what your A1C goal should be.
            </p>
            <p>
              Learn more about{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.cdc.gov/diabetes/managing/managing-blood-sugar/a1c.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                your A1C.
              </a>
            </p>
          </div>
          <div className="modal-footer">
            <button
              tabIndex={5}
              onClick={() => nextModal(tipModelTwo, tipModelOne)}
              className="prev"
              aria-label="Previous"
            >
              <svg
                width="17"
                height="28"
                viewBox="0 0 17 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15 2L3 14L15 26"
                  stroke="white"
                  strokeWidth="3"
                  strokeLinecap="round"
                />
              </svg>
            </button>
            <button
              tabIndex={6}
              onClick={() => nextModal(tipModelTwo, tipModelThree)}
              className="next"
              aria-label="Next"
            >
              <svg
                width="17"
                height="28"
                viewBox="0 0 17 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2 26L14 14L2 2"
                  stroke="white"
                  strokeWidth="3"
                  strokeLinecap="round"
                />
              </svg>
            </button>
          </div>
        </Dialog>
        <Dialog
          tabIndex={1}
          role="dialog"
          open={tipModelThree.isOpen}
          aria-label="Paying Attention to Your Stress Level"
          onClose={tipModelThree.close}
          className="dsmes-modal md-modal modal-theme-blue"
        >
          <button
            tabIndex={2}
            className="modal-close-btn"
            onClick={tipModelThree.close}
            aria-label="Close"
          >
            <CloseIcon className="modal-close-icon" />
          </button>
          <div
            className="modal-header mtm3"
            style={{ backgroundImage: `url(${tipModalHeaderImage03})` }}
          >
            &nbsp;
          </div>
          <div id="modalTitle-3" className="modal-title">
            <h3 tabIndex={3} className="strong">
              Paying Attention to Your Stress Level
            </h3>
          </div>
          <div tabIndex={4} id="modalContent-3" className="modal-content">
            <p>
              Stress is a normal, natural response to certain life events. But
              constant stress can affect how you manage diabetes. Monitoring
              your stress can help you understand the causes of your stress and
              help you make changes.
            </p>
            <p>When you feel yourself getting stressed, ask yourself: </p>
            <ul>
              <li>
                Am I having physical symptoms such as shaking, crying, or pain?
              </li>
              <li>Do I feel overwhelmed?</li>
              <li>
                Am I having trouble sleeping or having trouble staying asleep?
              </li>
            </ul>
            <p>
              If you answered yes to any of these questions, try these tips for
              coping with stress.{" "}
            </p>
            <ul>
              <li>Pause and take a few deep breaths.</li>
              <li>Try taking a walk or meditating.</li>
              <li>Talk to someone you trust.</li>
            </ul>
            <p>
              <strong>Pause, Reflect, Act:</strong> Think about ways you cope
              with stress. Write down your go-to de-stress activity and keep
              this reminder with you when you are having a stressful moment
            </p>
          </div>
          <div className="modal-footer">
            <button
              tabIndex={5}
              onClick={() => nextModal(tipModelThree, tipModelTwo)}
              className="prev"
              aria-label="Previous"
            >
              <svg
                width="17"
                height="28"
                viewBox="0 0 17 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15 2L3 14L15 26"
                  stroke="white"
                  strokeWidth="3"
                  strokeLinecap="round"
                />
              </svg>
            </button>
            <button
              onClick={() => nextModal(tipModelThree, tipModelFour)}
              className="next"
              tabIndex={6}
              aria-label="Next"
            >
              <svg
                width="17"
                height="28"
                viewBox="0 0 17 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2 26L14 14L2 2"
                  stroke="white"
                  strokeWidth="3"
                  strokeLinecap="round"
                />
              </svg>
            </button>
          </div>
        </Dialog>
        <Dialog
          tabIndex={1}
          role="dialog"
          open={tipModelFour.isOpen}
          onClose={tipModelFour.close}
          className="dsmes-modal md-modal modal-theme-blue"
          aria-label="Keeping Track of What You Eat"
        >
          <button
            tabIndex={2}
            className="modal-close-btn"
            onClick={tipModelFour.close}
            aria-label="Close"
          >
            <CloseIcon className="modal-close-icon" />
          </button>
          <div
            className="modal-header"
            style={{ backgroundImage: `url(${tipModalHeaderImage04})` }}
          >
            &nbsp;
          </div>
          <div id="modalTitle-4" className="modal-title">
            <h3 tabIndex={3} className="strong">
              Keeping Track of What You Eat
            </h3>
          </div>
          <div tabIndex={4} id="modalContent-4" className="modal-content">
            <MonitoringDiabetesModalContent04 />
          </div>
          <div className="modal-footer">
            <button
              tabIndex={5}
              onClick={() => nextModal(tipModelFour, tipModelThree)}
              className="prev"
              aria-label="Previous"
            >
              <svg
                width="17"
                height="28"
                viewBox="0 0 17 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15 2L3 14L15 26"
                  stroke="white"
                  strokeWidth="3"
                  strokeLinecap="round"
                />
              </svg>
            </button>
            <button
              tabIndex={6}
              onClick={() => nextModal(tipModelFour, tipModelFive)}
              className="next"
              aria-label="Next"
            >
              <svg
                width="17"
                height="28"
                viewBox="0 0 17 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2 26L14 14L2 2"
                  stroke="white"
                  strokeWidth="3"
                  strokeLinecap="round"
                />
              </svg>
            </button>
          </div>
        </Dialog>
        <Dialog
          tabIndex={1}
          role="dialog"
          open={tipModelFive.isOpen}
          onClose={tipModelFive.close}
          className="dsmes-modal md-modal modal-theme-blue"
          aria-label="Checking Your Blood Pressure and Cholesterol"
        >
          <button
            tabIndex={2}
            className="modal-close-btn"
            onClick={tipModelFive.close}
            aria-label="Close"
          >
            <CloseIcon className="modal-close-icon" />
          </button>
          <div
            className="modal-header mtm2"
            style={{ backgroundImage: `url(${tipModalHeaderImage05})` }}
          >
            &nbsp;
          </div>
          <div id="modalTitle-5" className="modal-title">
            <h3 tabIndex={3} className="strong">
              Checking Your Blood Pressure and Cholesterol
            </h3>
          </div>
          <div tabIndex={4} id="modalContent-5" className="modal-content">
            <p>
              Adults with diabetes are at an increased risk of other health
              issues such as high blood pressure and high cholesterol. In
              addition, black women are nearly 60% more likely to have high
              blood pressure as compared to non-Hispanic white women.{" "}
            </p>
            <p>
              High blood pressure makes your heart work too hard. The goal blood
              pressure for most people with diabetes is below 140/90. Ask your
              health-care provider what your blood pressure goal should be and
              how often you should check it.
            </p>
            <p>
              Cholesterol is a sticky substance that’s normally in your blood.
              But high LDL-C or “bad” cholesterol levels make it harder for
              blood to pass through your system and increases the risk of heart
              disease. Work with your health-care provider to determine what
              your cholesterol number should be and how often you should check
              it.
            </p>
          </div>
          <div className="modal-footer">
            <button
              tabIndex={5}
              onClick={() => nextModal(tipModelFive, tipModelFour)}
              className="prev"
              aria-label="Previous"
            >
              <svg
                width="17"
                height="28"
                viewBox="0 0 17 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15 2L3 14L15 26"
                  stroke="white"
                  strokeWidth="3"
                  strokeLinecap="round"
                />
              </svg>
            </button>
            <button
              tabIndex={6}
              onClick={() => nextModal(tipModelFive, tipModelSix)}
              className="next"
              aria-label="Next"
            >
              <svg
                width="17"
                height="28"
                viewBox="0 0 17 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2 26L14 14L2 2"
                  stroke="white"
                  strokeWidth="3"
                  strokeLinecap="round"
                />
              </svg>
            </button>
          </div>
        </Dialog>
        <Dialog
          tabIndex={1}
          role="dialog"
          open={tipModelSix.isOpen}
          onClose={tipModelSix.close}
          className="dsmes-modal md-modal modal-theme-blue"
          aria-label="Overcoming Monitoring Challenges"
        >
          <button
            tabIndex={2}
            className="modal-close-btn"
            onClick={tipModelSix.close}
            aria-label="Close"
          >
            <CloseIcon className="modal-close-icon" />
          </button>
          <div
            className="modal-header centered"
            style={{ backgroundImage: `url(${tipModalHeaderImage06})` }}
          >
            &nbsp;
          </div>
          <div id="modalTitle-6" className="modal-title">
            <h3 tabIndex={3} className="strong">
              Overcoming Monitoring Challenges
            </h3>
          </div>
          <div tabIndex={4} id="modalContent-6" className="modal-content">
            <p>
              Getting the right glucose monitoring devices and tests while
              managing costs can be stressful.{" "}
            </p>
            <p>
              It can feel overwhelming, but it’s not impossible. Take your time.
              Here are a few key steps you can take:
            </p>
            <ul>
              <li>
                Call your insurance provider (or go online) and have them walk
                you through what is covered.{" "}
              </li>
              <li>
                Call your insurance provider (or go online) to find a
                health-care provider in your insurance network. Ask your health
                care provider which glucose monitor is right for you.{" "}
              </li>
              <li>
                Call your insurance provider (or go online) and check to see if
                they cover any of your glucose monitoring devices. Talk with
                your health care provider or diabetes care and education
                specialist about how they can help you learn to monitor your
                diabetes.
              </li>
            </ul>
          </div>
          <div className="modal-footer">
            <button
              tabIndex={5}
              onClick={() => nextModal(tipModelSix, tipModelFive)}
              className="prev"
              aria-label="Previous"
            >
              <svg
                width="17"
                height="28"
                viewBox="0 0 17 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15 2L3 14L15 26"
                  stroke="white"
                  strokeWidth="3"
                  strokeLinecap="round"
                />
              </svg>
            </button>
            <button
              tabIndex={6}
              onClick={() => nextModal(tipModelSix, tipModelSeven)}
              className="next"
              aria-label="Next"
            >
              <svg
                width="17"
                height="28"
                viewBox="0 0 17 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2 26L14 14L2 2"
                  stroke="white"
                  strokeWidth="3"
                  strokeLinecap="round"
                />
              </svg>
            </button>
          </div>
        </Dialog>
        <Dialog
          tabIndex={1}
          role="dialog"
          open={tipModelSeven.isOpen}
          onClose={tipModelSeven.close}
          className="dsmes-modal md-modal modal-theme-blue"
          aria-label="Continuous Glucose Monitors (CGM)"
        >
          <button
            tabIndex={2}
            className="modal-close-btn"
            onClick={tipModelSeven.close}
            aria-label="Close"
          >
            <CloseIcon className="modal-close-icon" />
          </button>
          <div
            className="modal-header mtm4"
            style={{ backgroundImage: `url(${tipModalHeaderImage07})` }}
          >
            &nbsp;
          </div>
          <div id="modalTitle-7" className="modal-title">
            <h3 tabIndex={3} className="strong">
              Continuous Glucose Monitors (CGM)
            </h3>
          </div>
          <div tabIndex={4} id="modalContent-7" className="modal-content">
            <p>
              Continuous glucose monitors (CGMs) are small devices that
              automatically track your blood sugar while attached to your body.
              They can be a great tool for helping you easily monitor your blood
              sugar levels. Sometimes your blood sugar can spike or fall between
              tests. Real-time blood sugar monitoring with a CGM can help ensure
              you are within the target blood sugar range throughout the day.
              Talk to your health-care provider or diabetes care specialist
              about your target blood sugar range and CGMs.{" "}
            </p>
            <p>
              Learn more about{" "}
              <ExternalLink url="https://www.diabetes.org/tools-support/devices-technology/cgm-time-in-range">
                continuous glucose monitoring.
              </ExternalLink>
            </p>
          </div>
          <div className="modal-footer">
            <button
              tabIndex={5}
              onClick={() => nextModal(tipModelSeven, tipModelSix)}
              className="prev"
              aria-label="Previous"
            >
              <svg
                width="17"
                height="28"
                viewBox="0 0 17 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15 2L3 14L15 26"
                  stroke="white"
                  strokeWidth="3"
                  strokeLinecap="round"
                />
              </svg>
            </button>
          </div>
        </Dialog>
      </div>
    </section>
  );
};
