import portionSizeImg from "../../../assets/images/healthyEating/Diabetes-Manage-Eat-Well-Plate-Graphic_600px 1.png";
import styles from "./HealthyEatingModalCards.module.scss";

export const HealthyEatingModalContent08 = () => {
  return (
    <>
      <p>
        It’s easy to eat more food than you need without realizing it. Ever
        heard of the Diabetes Plate Method? This method is a simple way to help
        you portion your food while eating the good nutrients you need. See the
        picture below for an idea of how to use the Diabetes Plate Method to
        portion your foods.
      </p>
      <div className={styles["plate-method-tips"]}>
        <div className={styles["img-wrapper"]}>
          <img src={portionSizeImg} alt="Diabetes Plate Method" />
        </div>
        <ul>
          <li>
            <span className="strong">Step 1:</span> Fill half of the plate with
            non-starchy vegetables like salad, green beans, broccoli, collard
            greens, cauliflower, cabbage, and okra.{" "}
          </li>
          <li>
            <span className="strong">Step 2:</span> Fill one quarter with a lean
            protein such as chicken, turkey, beans, tofu, or eggs.{" "}
          </li>
          <li>
            <span className="strong">Step 3:</span> Fill one quarter with
            better-for-you carb foods. Foods that are higher in carbs include
            grains, starchy vegetables (such as potatoes, peas, corn and yams),
            rice, pasta, beans, fruit, and yogurt.{" "}
          </li>
        </ul>
      </div>
      <p>
        To learn more about ways to portion your food, visit this{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.cdc.gov/diabetes/managing/eat-well/diabetes-and-carbs/carbohydrate-choice-lists.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          portions guide
        </a>
        .
      </p>
    </>
  );
};
