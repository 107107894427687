import ReactPlayer from 'react-player'

function VideoPlayer() {
  return (
    <div tabIndex={0} aria-label="Getting Ready for your Diabetes Care Visit video" aria-roledescription="video player" className="video-wrapper">
      <ReactPlayer
        width="100%"
        height="100%"
        controls
        url="https://www.youtube.com/watch?v=eQWigI3lYJc"
      />
    </div>
  )
}

export default VideoPlayer
