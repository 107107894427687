// import portionSizeImg from '../../../assets/images/SelfCare/Diabetes-Manage-Eat-Well-Plate-Graphic_600px 1.png'
import headerUnderline from "../../../../assets/images/modal-header-underline.png";
import spiritImg from "../../../../assets/images/copingDiabetes/spirit-self-care-head.png";
import styles from "./SelfCareModals.scss";

export const SelfCareModalContent08 = () => {
  return (
    <>
      <div id="modalTitle-1" className="modal-title">
        <h2 className="display-h2">Spirit - Spiritual Self Care</h2>
        <img
          className="headerUnderline"
          src={headerUnderline}
          alt="decorative underline"
        />
      </div>
      <div id="modalContent-1" className="modal-content">
        <img
          className="headImg"
          src={spiritImg}
          alt="woman outside at sunset"
        />
        <p>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.cdc.gov/diabetes/professional-info/toolkits/new-beginnings/pdf/spirituality-guide-508.pdf"
          >
            Spiritual self-care can mean different things to different people
          </a>
          . For some people, spiritual self-care means joining a religious
          institution, praying, reading religious texts, or attending religious
          services. For others, spiritual self-care might include connecting
          with your deepest self by appreciating nature, art, or music. These
          spiritual self-care practices can provide valuable ways to cope with
          diabetes. Here are some activities you can consider adding to your
          regimen of spiritual self-care:{" "}
        </p>
      </div>
    </>
  );
};
