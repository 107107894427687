import { useId } from "react";
import flipCardImg01 from "../../../assets/images/healthyEating/bfy-good-carbs-01.png";
import flipCardImg02 from "../../../assets/images/healthyEating/bfy-good-carbs-02.png";
import flipCardImg03 from "../../../assets/images/healthyEating/bfy-good-carbs-03.png";
import flipCardImg04 from "../../../assets/images/healthyEating/bfy-bad-carbs-01.png";
import flipCardImg05 from "../../../assets/images/healthyEating/bfy-bad-carbs-02.png";
import flipCardImg06 from "../../../assets/images/healthyEating/bfy-bad-carbs-03.png";
import styles from "./HealthyEatingModalCards.module.scss";

export const HealthyEatingModalContent02 = () => {
  const id = useId();
  return (
    <>
      <p>
        While carbs can give you energy, not all carbs are created equal. Eating
        carbs that are better for you and minimizing the others is key to
        staying in a healthy blood sugar range. Better-for-you carbs raise blood
        sugar slowly, while other carbs raise it more quickly and can cause your
        blood sugar to rise too high. See examples below of better-for-you carbs
        and carbs to avoid. To learn more about carbs, read this{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.cdc.gov/diabetes/prevention/pdf/t2/Participant-Module-22_More_About_Carbs.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          carb guide.
        </a>{" "}
      </p>
      <div className={styles["flip-card-grid"]}>
        <h5 className={styles["bfy-title"]}>Better-for-you Carbs</h5>
        <div
          className={styles.card}
          tabIndex={0}
          aria-roledescription="flipping-card"
        >
          <div className={styles["card-body"]}>
            <div role="button" className={styles["card-front"]}>
              <img
                src={flipCardImg01}
                alt=""
                aria-describedby={id + "-cardCaption01"}
              />
            </div>
            <div role="button" className={styles["card-back"]}>
              <p id={id + "-cardCaption01"} className={styles.bfy}>
                Brown Rice
              </p>
            </div>
          </div>
        </div>
        <div
          className={styles.card}
          tabIndex={0}
          aria-roledescription="flipping-card"
        >
          <div className={styles["card-body"]}>
            <div role="button" className={styles["card-front"]}>
              <img
                src={flipCardImg02}
                alt=""
                aria-describedby={id + "cardCaption02"}
              />
            </div>
            <div role="button" className={styles["card-back"]}>
              <p id={id + "-cardCaption02"} className={styles.bfy}>
                Whole Wheat Bread
              </p>
            </div>
          </div>
        </div>
        <div
          className={styles.card}
          tabIndex={0}
          aria-roledescription="flipping-card"
        >
          <div className={styles["card-body"]}>
            <div role="button" className={styles["card-front"]}>
              <img
                src={flipCardImg03}
                alt=""
                aria-describedby={id + "cardCaption03"}
              />
            </div>
            <div role="button" className={styles["card-back"]}>
              <p id={id + "-cardCaption03"} className={styles.bfy}>
                Whole Fruit
              </p>
            </div>
          </div>
        </div>
        <h5 className={styles["avoid-title"]}>Carbs to Avoid</h5>
        <div
          className={styles.card}
          tabIndex={0}
          aria-roledescription="flipping-card"
        >
          <div className={styles["card-body"]}>
            <div role="button" className={styles["card-front"]}>
              <img
                src={flipCardImg04}
                alt=""
                aria-describedby={id + "cardCaption04"}
              />
            </div>
            <div role="button" className={styles["card-back"]}>
              <p id={id + "-cardCaption04"} className={styles.avoid}>
                White Rice
              </p>
            </div>
          </div>
        </div>
        <div
          className={styles.card}
          tabIndex={0}
          aria-roledescription="flipping-card"
        >
          <div className={styles["card-body"]}>
            <div role="button" className={styles["card-front"]}>
              <img
                src={flipCardImg05}
                alt=""
                aria-describedby={id + "cardCaption05"}
              />
            </div>
            <div role="button" className={styles["card-back"]}>
              <p id={id + "-cardCaption05"} className={styles.avoid}>
                White Bread
              </p>
            </div>
          </div>
        </div>
        <div
          className={styles.card}
          tabIndex={0}
          aria-roledescription="flipping-card"
        >
          <div className={styles["card-body"]}>
            <div role="button" className={styles["card-front"]}>
              <img
                src={flipCardImg06}
                alt=""
                aria-describedby={id + "cardCaption06"}
              />
            </div>
            <div role="button" className={styles["card-back"]}>
              <p id={id + "-cardCaption06"} className={styles.avoid}>
                Cakes and Sweet Treats
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
