
import nutritionLabel from '../../../assets/images/healthyEating/NutritionLabel.png'
import styles from './HealthyEatingModalCards.module.scss'

export const HealthyEatingModalContent06 = () => {
  return (
    <>
      <p>Using nutrition labels can help you choose foods for a healthy diet. Let’s review how to read them and how they can help.</p>
      <div className={styles["nutrition-grid"]}>
        <div className={styles.card}>
          <p>1. <strong>Check the serving size first.</strong> All numbers on this label are for a 2/3-cup serving.</p>
        </div>
        <div className={styles.card}>
          <p>2. <strong>This item’s container has eight servings.</strong> That means if you eat the whole container, you’re eating <strong className="u">eight times</strong> the number of calories, carbs, fat, and nutrients shown on the label.</p>
        </div>
        <div className={styles.card}>
          <p>3. <strong>“Total Carbohydrate”</strong> shows you types of carbs, including sugar and fiber.</p>
        </div>
        <div className={styles.card}>
          <p>4. Eat foods higher in: </p>
          <ul>
            <li>Fiber</li>
            <li>Vitamins</li>
            <li>Minerals</li>
          </ul>
        </div>
        <div className={styles.card}>
          <p>5. Eat foods lower in: </p>
          <ul>
            <li>Calories</li>
            <li>Saturated fat </li>
            <li>Salt (sodium)</li>
            <li>Added sugars </li>
          </ul>
        </div>
        <img src={nutritionLabel} alt="" className={styles["nutrition-facts"]} />
      </div>
    </>
  )
}