import image1 from '../../../assets/images/careGivers1.jpg'
import image2 from '../../../assets/images/careGivers2.jpg'
import image3 from '../../../assets/images/careGivers3.jpg'
import image4 from '../../../assets/images/careGivers4.jpg'
import image5 from '../../../assets/images/careGivers5.jpg'
import { ExternalLink } from '../../ExternalLink/ExternalLink'
function CareGivers() {
  return (
    <>
      <div className="rqc-care-team">
        <div className="rqc-care-team-title">
          <h2 className="h3 italic">Read more about each teammate here:</h2>
        </div>
        <div className="rqc-care-team-container">
          <div className="rqc-care-team-card">
            <div className="img-wrapper">
              <img role="presentation" className="care-image" src={image1} alt="woman smiling" />
            </div>
            <h3 className="strong h4">Primary Care Provider</h3>
            <p>
            Your primary care provider is a good place to start your journey with diabetes. They can help you learn the basics of diabetes care and help support you in managing your diabetes.
            </p>
          </div>
          <div className="rqc-care-team-card">
            <div className="img-wrapper">
              <img role="presentation" className="care-image" src={image2} alt="woman smiling" />
            </div>
            <h3 className="strong h4">Endocrinologist</h3>
            <p>
            A type of health-care provider that specializes in diabetes treatment and care is an endocrinologist. If you do not have an endocrinologist on your team, ask your primary care provider if seeing an endocrinologist would be right for you. Adding an endocrinologist to your team can be helpful when you are having diabetes-related issues (for example, foot sores or problems with your vision), or when other treatments are not helping you. 
            </p>
          </div>
          <div className="rqc-care-team-card">
            <div className="img-wrapper">
              <img role="presentation" className="care-image" src={image3} alt="woman smiling" />
            </div>
            <h3 className="strong h4">Diabetes Care and Education Specialist</h3>
            <p>
            A diabetes care and education specialist can be another great teammate. They can help you create a diabetes management plan that works for your life. They can also help you find solutions to problems you are facing and help you make sense of all the diabetes information out there.
            </p>
          </div>
          <div className="rqc-care-team-card">
            <div className="img-wrapper">
              <img role="presentation" className="care-image" src={image4} alt="woman smiling" />
            </div>
            <h3 className="strong h4">Registered Dietician Nutritionist</h3>
            <p>
            You may also want to add a registered dietitian nutritionist (RDN) to your team. RDNs can help you create a food and nutrition plan that fits your lifestyle and fuels your body. They can provide tips for healthy eating and for handling challenges along the way. You can also talk to your RDN about your favorite recipes and small adjustments that help them fit into your meal plan.
            </p>
          </div>
        </div>
        <div className="rqc-best-care-team">
          <div className="img-wrapper">
            <img role="presentation" src={image5} alt="smiling healthcare professional" />
          </div>
          <div className="text-wrapper">
            <h3>The Best Health Care Team for You </h3>
            <p>
            You should feel supported, understood, and cared for by your health care team. If you don’t, you may want to explore different providers. If you’d prefer to see a Black health care provider, options are available. Below are a few resources:
            </p>  
            <p><ExternalLink url="https://blackdoctorsusa.com/">Black Doctors USA</ExternalLink> connects individuals with Black health-care providers and other health resources. </p>
            <p><ExternalLink url="https://www.melanin-rx.com/">Melanin-RX</ExternalLink> is a platform for finding culturally-aware medical professionals and alliances.  </p>
            <p><ExternalLink url="https://www.blackwomenphysicians.org/">The Association of Black Women Physicians</ExternalLink> is a 501(c)(3) nonprofit organization committed to the improvement of public health and welfare. </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default CareGivers
