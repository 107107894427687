import { useState, useId } from 'react'
import flipCardImg01 from '../../../assets/images/healthyEating/bfy-good-meal-01.png'
import flipCardImg02 from '../../../assets/images/healthyEating/bfy-good-meal-02.png'
import flipCardImg03 from '../../../assets/images/healthyEating/bfy-good-meal-03.png'
import flipCardImg04 from '../../../assets/images/healthyEating/bfy-good-meal-04.png'
import flipCardImg05 from '../../../assets/images/healthyEating/bfy-bad-meal-01.png'
import flipCardImg06 from '../../../assets/images/healthyEating/bfy-bad-meal-02.png'
import flipCardImg07 from '../../../assets/images/healthyEating/bfy-bad-meal-03.png'
import flipCardImg08 from '../../../assets/images/healthyEating/bfy-bad-meal-04.png'
import { ExternalLink } from '../../ExternalLink/ExternalLink'
import styles from './HealthyEatingModalCards.module.scss'
import flipIcon from '../../../assets/images/healthyEating/flip-icon.svg'
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';

export const HealthyEatingModalContent05 = () => {
  const [flipCards, setFlipCards] = useState({
    flipCardOne: false,
    flipCardTwo: false,
    flipCardThree: false,
    flipCardFour: false,
  });

  const id = useId();

  return (
    <>
      <p>Now that you have an idea of healthy food options, let’s take a look at the different meal options to avoid or limit.  Click the breakfast, lunch, dinner or snack  meal options to see examples of better-for-you meals.</p>
      <div className={`${styles["flip-card-grid-layout-v2"]}`}>
        <button
          className={`${styles.card} ${!(flipCards.flipCardOne) ? `${styles.avoid}` : `${styles.bfy}`}`}  
          onClick={() => {setFlipCards((state) => ({...state, flipCardOne: !flipCards.flipCardOne}))}}
          tabIndex={0} 
          aria-roledescription="flipping-card"
          aria-pressed="false"
        >
          <div className={styles["card-body"]}>
            <div aria-label="Avoid or limit waffles and sausage." className={styles["card-front"]}>
              <div className={styles["card-img-wrapper"]}>
                <img src={flipCardImg05} alt="" aria-describedby={id + '-cardCaption01'} />
                <img className={styles["flip-icon"]} src={flipIcon} alt="" />
                <SentimentDissatisfiedIcon className={styles["icon-avoid"]} />
              </div>
              <p id={id + '-cardCaption01'} className={styles["front-card-caption"]}>Waffles and Sausage</p>
            </div>
            <div aria-label="A better-for-you meal is oatmeal." className={styles["card-back"]}>
              <div className={styles["card-img-wrapper"]}>
                <img src={flipCardImg01} alt="" aria-describedby={id + '-cardCaption02'} />
                <img className={styles["flip-icon"]} src={flipIcon} alt="" />
                <SentimentVerySatisfiedIcon className={styles["icon-bfy"]} />
              </div>
              <p id={id + '-cardCaption02'} className={styles["back-card-caption"]}>Oatmeal</p>
            </div>
          </div>
        </button>
        <button
          className={`${styles.card} ${!(flipCards.flipCardTwo) ? `${styles.avoid}` : `${styles.bfy}`}`}  
          onClick={() => {setFlipCards((state) => ({...state, flipCardTwo: !flipCards.flipCardTwo}))}}
          tabIndex={0} 
          aria-roledescription="flipping-card"
          aria-pressed="false"
        >
          <div className={styles["card-body"]}>
            <div aria-label="Avoid or limit fried chicken tenders." className={styles["card-front"]}>
              <div className={styles["card-img-wrapper"]}>
                <img src={flipCardImg06} alt="" aria-describedby={id + '-cardCaption03'} />
                <img className={styles["flip-icon"]} src={flipIcon} alt="" />
                <SentimentDissatisfiedIcon className={styles["icon-avoid"]} />
              </div>
              <p id={id + '-cardCaption03'} className={styles["front-card-caption"]}>Fried Chicken Tenders</p>
            </div>
            <div aria-label="A better-for-you meal is turkey sandwich." className={styles["card-back"]}>
              <div className={styles["card-img-wrapper"]}>
                <img src={flipCardImg02} alt="" aria-describedby={id + '-cardCaption04'} />
                <img className={styles["flip-icon"]} src={flipIcon} alt="" />
                <SentimentVerySatisfiedIcon className={styles["icon-bfy"]} />
              </div>
              <p id={id + '-cardCaption04'} className={styles["back-card-caption"]}>Turkey Sandwich</p>
            </div>
          </div>
        </button>
        <button
          className={`${styles.card} ${!(flipCards.flipCardThree) ? `${styles.avoid}` : `${styles.bfy}`}`}  
          onClick={() => {setFlipCards((state) => ({...state, flipCardThree: !flipCards.flipCardThree}))}}
          tabIndex={0} 
          aria-roledescription="flipping-card"
          aria-pressed="false"
        >
          <div className={styles["card-body"]}>
            <div aria-label="Avoid or limit pepperoni pizza." className={styles["card-front"]}>
              <div className={styles["card-img-wrapper"]}>
                <img src={flipCardImg07} alt="" />
                <img className={styles["flip-icon"]} src={flipIcon} alt="" aria-describedby={id + '-cardCaption05'} />
                <SentimentDissatisfiedIcon className={styles["icon-avoid"]} />
              </div>
              <p id={id + '-cardCaption05'} className={styles["front-card-caption"]}>Pepperoni Pizza</p>
            </div>
            <div aria-label="A better-for-you meal is chicken tortilla soup." className={styles["card-back"]}>
              <div className={styles["card-img-wrapper"]}>
                <img src={flipCardImg03} alt="" aria-describedby={id + '-cardCaption06'} />
                <img className={styles["flip-icon"]} src={flipIcon} alt="" />
                <SentimentVerySatisfiedIcon className={styles["icon-bfy"]} />
              </div>
              <p id={id + '-cardCaption06'} className={styles["back-card-caption"]}>Chicken Tortilla Soup</p>
            </div>
          </div>
        </button>
        <button
          className={`${styles.card} ${!(flipCards.flipCardFour) ? `${styles.avoid}` : `${styles.bfy}`}`}  
          onClick={() => {setFlipCards((state) => ({...state, flipCardFour: !flipCards.flipCardFour}))}}
          tabIndex={0} 
          aria-roledescription="flipping-card"
          aria-pressed="false"
        >
          <div className={styles["card-body"]}>
            <div aria-label="Avoid or limit cupcakes." className={styles["card-front"]}>
              <div className={styles["card-img-wrapper"]}>
                <img src={flipCardImg08} alt="" aria-describedby={id + '-cardCaption07'} />
                <img className={styles["flip-icon"]} src={flipIcon} alt="" />
                <SentimentDissatisfiedIcon className={styles["icon-avoid"]} />
              </div>
              <p id={id + '-cardCaption07'} className={styles["front-card-caption"]}>Cupcakes</p>
            </div>
            <div aria-label="A better-for-you meal is yogurt parfait." className={styles["card-back"]}>
              <div className={styles["card-img-wrapper"]}>
                <img src={flipCardImg04} alt="" aria-describedby={id + '-cardCaption08'} />
                <img className={styles["flip-icon"]} src={flipIcon} alt="" />
                <SentimentVerySatisfiedIcon className={styles["icon-bfy"]} />
              </div>
              <p id={id + '-cardCaption08'} className={styles["back-card-caption"]}>Yogurt Parfait</p>
            </div>
          </div>
        </button>
      </div>
      <p>Living a healthy life with diabetes doesn’t mean you don’t get to enjoy food. Remember that YOU are in control. Try different foods to see what best fits your needs. You will start to feel more confident in your choices. </p>
    </>
  )
}