import { useState, useId } from "react";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import styles from "./ExternalLink.module.scss";

export const ExternalLink = (props) => {
  const [disclaimerOpen, setDisclaimerOpen] = useState(false);
  const id = useId();

  const handleContinue = (e) => {
    window.open(props.url, "_blank");
    setDisclaimerOpen(false);
  };

  return (
    <>
      <a
        href={props.url}
        tabIndex={props.tabindex}
        onClick={(e) => {
          e.preventDefault();
          setDisclaimerOpen(true);
        }}
        className={`${styles["external-link"]} ${props.cname} xyz`}
        target="_blank"
        rel="noopener noreferrer"
        aria-label={props.aLabel}
        tabindex={props.tabIndex}
      >
        {props.children}&nbsp;
        <span className={styles["external-link-icon"]}>
          <OpenInNewIcon alt="external link" />
        </span>
      </a>
      <Dialog
        className={`${"dsmes-modal"} ${styles["external-link-modal"]}`}
        open={disclaimerOpen}
        onClose={() => setDisclaimerOpen(false)}
        aria-labelledby={"disclaimerTitle-" + id}
        aria-describedby={"disclaimerContent-" + id}
      >
        <button
          className="modal-close-btn"
          onClick={() => setDisclaimerOpen(false)}
          aria-label="Close"
        >
          <CloseIcon className="modal-close-icon" />
        </button>
        <div
          id={"disclaimerTitle-" + id}
          className={`${"modal-title"} ${styles["modal-title"]}`}
        >
          <h3>Exit Notification/Disclaimer Policy</h3>
        </div>
        <div
          id={"disclaimerContent-" + id}
          className={`${"modal-content"} ${styles["modal-content"]}`}
        >
          <p>
            Links with this icon{" "}
            <span className={styles["external-link-icon"]}>
              <OpenInNewIcon />
            </span>{" "}
            indicate that you are leaving the CDC website
          </p>
          <ul>
            <li>
              The Centers for Disease Control and Prevention (CDC) cannot attest
              to the accuracy of a non-federal website.
            </li>
            <li>
              Linking to a non-federal website does not constitute an
              endorsement by CDC or any of its employees of the sponsors of the
              information and products presented on the website.
            </li>
            <li>
              You will be subject to the destination website's privacy policy
              when you follow the link.
            </li>
            <li>
              CDC is not responsible for Section 508 compliance (accessibility)
              on other federal or private website.
            </li>
          </ul>
          <p>
            For more information on CDC's web notification policies, see{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.cdc.gov/other/disclaimer.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              Website Disclaimers
            </a>
            .
          </p>
        </div>
        <div className={`${"modal-footer"} ${styles["modal-footer"]}`}>
          <button
            onClick={() => setDisclaimerOpen(false)}
            className={styles["btn-cancel"]}
          >
            Cancel
          </button>
          <button onClick={handleContinue} className={styles["btn-continue"]}>
            Continue
          </button>
        </div>
      </Dialog>
    </>
  );
};
