import iconQuestion from "../../../../assets/images/icon-question.svg";
import iconLocation from "../../../../assets/images/icon-location.svg";
import iconMedications from "../../../../assets/images/icon-medications.svg";
import iconPrograms from "../../../../assets/images/icon-programs.svg";
import iconSupport from "../../../../assets/images/icon-support.svg";
import iconTerms from "../../../../assets/images/icon-terms.svg";
import { ExternalLink } from "../../../ExternalLink/ExternalLink";
import styles from "./PreparingMeet.module.scss";

export default function PreparingMeet() {
  return (
    <section className={styles["fp-preparing-meet-section"]}>
      <h1>
        Preparing to Meet With a <span className="wsnw">Health Care</span>{" "}
        Provider
      </h1>
      <p>
        When you are meeting with your health care provider, be ready. There are
        easy steps you can take to prepare for a visit.
      </p>
      <p>
        <strong>Check out the steps below:</strong>
      </p>
      <div className={styles["steps-list"]}>
        <div className={`${styles["step-card"]} ${styles.questions}`}>
          <img
            role="presentation"
            className={styles["step-icon"]}
            src={iconQuestion}
            alt=""
          />
          <h2>Questions</h2>
          <p>
            Come with a list of questions. You DON’T need permission to speak up
            for your health. You are the person in charge of managing your
            diabetes. You may want to ask:
          </p>
          <ul>
            <li>
              Do you have experience caring for Black women with diabetes? If
              so, what have you learned that might be important to me?{" "}
            </li>
            <li>
              How do you know if patients are satisfied with the service you
              provide?{" "}
            </li>
            <li>
              What is your communication style? Will you tell me what to do or
              do you give me options and describe the pros and cons?{" "}
            </li>
            <li>
              Are there times set aside for a phone call, or do you only accept
              emailed questions?{" "}
            </li>
            <li>
              Will you talk with specialists on my behalf to coordinate my care?{" "}
            </li>
          </ul>
          <p>
            Responses to these questions may give you a better idea if the
            health care provider is right for you.{" "}
          </p>
        </div>
        <div className={`${styles["step-card"]} ${styles.medications}`}>
          <img
            role="presentation"
            className={styles["step-icon"]}
            src={iconMedications}
            alt=""
          />
          <h2>Medications</h2>
          <p>
            Bring a list of medications to your visit. Questions you may want to
            ask:
          </p>

          <ul>
            <li>How long will I need to take it? </li>
            <li>What if I have side effects?</li>
          </ul>
        </div>
        <div className={`${styles["step-card"]} ${styles.support}`}>
          <img
            role="presentation"
            className={styles["step-icon"]}
            src={iconSupport}
            alt=""
          />
          <h2>Support</h2>
          <p>
            If you’d like, ask a family member or friend to come with you for
            support.{" "}
          </p>
        </div>
        <div className={`${styles["step-card"]} ${styles.terms}`}>
          <img
            role="presentation"
            className={styles["step-icon"]}
            src={iconTerms}
            alt=""
          />
          <h2>Medical Terms</h2>
          <p>
            {" "}
            Write down any medical terms you find confusing and share them with
            your health care provider. You can also visit the{" "}
            <ExternalLink
              url="https://medlineplus.gov/medwords/medicalwords.html"
              rel="noopener noreferrer"
            >
              Understanding Medical Words
            </ExternalLink>{" "}
            web page.
          </p>
        </div>
        <div className={`${styles["step-card"]} ${styles.transportation}`}>
          <img
            role="presentation"
            className={styles["step-icon"]}
            src={iconLocation}
            alt=""
          />
          <h2>Transportation</h2>
          <p>
            If it’s hard to find a ride to your appointment, try using the
            website{" "}
            <ExternalLink url="https://adces.findhelp.com/">
              FindHelp.org
            </ExternalLink>{" "}
            for transportation options in your area — some may be free!
          </p>
        </div>
        <div
          className={`${styles["step-card"]} ${styles["diabetes-programs"]}`}
        >
          <img
            role="presentation"
            className={styles["step-icon"]}
            src={iconPrograms}
            alt=""
          />
          <h2>Diabetes Education Programs</h2>
          <p>
            Try using the{" "}
            <ExternalLink url="https://www.diabeteseducator.org/living-with-diabetes/find-an-education-program">
              Association of Diabetes Care & Education Specialist (ADCES)
            </ExternalLink>{" "}
            or the{" "}
            <ExternalLink url="https://www.diabetes.org/tools-support/diabetes-education-program">
              American Diabetes Association
            </ExternalLink>{" "}
            online search tools to find education programs in your area.
          </p>
        </div>
      </div>
      <p className={styles["more-info"]}>
        Looking for more questions to ask your health care team? Check out the
        website below:{" "}
      </p>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.cdc.gov/diabetes/managing/5-questions-health-care.html"
        className={styles["btn-patient-tips"]}
        target="_blank"
        rel="noopener noreferrer"
      >
        5 Questions to Ask Your Health Care Team
      </a>
    </section>
  );
}
