import { useId } from "react";
import headerUnderline from "../../../../assets/images/modal-header-underline.png";
import bodyRestHeadImg from "../../../../assets/images/copingDiabetes/body-rest-head.png";
// import { ExternalLink } from '../../ExternalLink/ExternalLink'
import styles from "./SelfCareModals.scss";

export const SelfCareModalContent04 = () => {
  const id = useId();
  return (
    <>
      <div id="modalTitle-4" className="modal-title">
        <h2 className="display-h2">BODY-Rest</h2>
        <img
          className="headerUnderline"
          src={headerUnderline}
          alt="decorative underline"
        />
      </div>
      <div id="modalContent-4" className="modal-content">
        <img
          className="headImg"
          src={bodyRestHeadImg}
          alt="woman sleeping on a pillow"
        />
        <p>
          <a
            target="_blank"
            rel="noreferrer noopener"
            class="org-button xyz"
            href="https://www.cdc.gov/diabetes/library/features/diabetes-sleep.html"
          >
            If you have diabetes, too little sleep negatively affects every area
            of your health
          </a>
          , including how much you eat, what you choose to eat, and how you
          respond to insulin. Proper rest isn’t just important for reducing
          stress — it may also put you in a better mood and give you more
          energy. Stick to a nighttime routine, and make sure you’re getting at
          least seven hours of sleep. Light from devices and screens can make it
          harder to fall asleep, so{" "}
          <a
            target="_blank"
            rel="noreferrer noopener"
            class="org-button xyz"
            href="https://www.cdc.gov/niosh/emres/longhourstraining/tips.html#:~:text=Keep%20light%20levels%20low%20for,a%20warm%20bath%20or%20shower"
          >
            try to turn down device brightness and cut screen time one to two
            hours before bedtime.
          </a>
        </p>
      </div>
    </>
  );
};
