import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import StressTabs from '../StressTabs/StressTabs'
import './StressOption.css'

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    ><strong>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component="span">{children}</Typography>
        </Box>
      )}</strong>
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  var tabIndex = 0
  index === 0 ? tabIndex=11 :
    index === 1 ? tabIndex=14 :
      index === 2 ? tabIndex=17 : tabIndex=0
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
    tabindex: tabIndex
  };
}

export const BasicTabs = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  return (
    <Box sx={{ width: '100%' }}>
      <div className="StressOptions">
        <Box sx={{ borderBottom: 0, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" centered>
          <Tab label="Anxiety Disorder" {...a11yProps(0)} />
          <Tab label="Depression" {...a11yProps(1)} />
          <Tab label="Diabetes Burnout (Also called Diabetes Distress)" {...a11yProps(2)} />
        </Tabs>
      </Box>
      </div>
      <CustomTabPanel value={value} index={0}>
        <span className="StressTabs"><StressTabs title={"anxiety"} option={"0"} /></span>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <span className="StressTabs"><StressTabs title={"depression"} option={"1"} /></span>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <span className="StressTabs"><StressTabs title={"distress"} option={"2"} /></span>
      </CustomTabPanel>
    </Box>
  );
}
export default BasicTabs
