import { useState } from 'react'
import Dialog from '@mui/material/Dialog'
import CloseIcon from '@mui/icons-material/Close'
import { SelfCareModalContent01 } from './SelfCareModalContent01'
import { SelfCareModalContent02 } from './SelfCareModalContent02'
import { SelfCareModalContent03 } from './SelfCareModalContent03'
import { SelfCareModalContent04 } from './SelfCareModalContent04'
import { SelfCareModalContent05 } from './SelfCareModalContent05'
import { SelfCareModalContent06 } from './SelfCareModalContent06'
import { SelfCareModalContent07 } from './SelfCareModalContent07'
import { SelfCareModalContent08 } from './SelfCareModalContent08'
import { SelfCareModalContent09 } from './SelfCareModalContent09'
import { SelfCareModalContent10 } from './SelfCareModalContent10'
import { SelfCareModalContent11 } from './SelfCareModalContent11'
import { SelfCareModalContent12 } from './SelfCareModalContent12'
import { SelfCareModalContent13 } from './SelfCareModalContent13'
import { SelfCareModalContent14 } from './SelfCareModalContent14'
import image7 from '../../../../assets/images/copingDiabetes/self-care-body.png'
import image8 from '../../../../assets/images/copingDiabetes/self-care-mind.png'
import image9 from '../../../../assets/images/copingDiabetes/self-care-spirit.png'
import image10 from '../../../../assets/images/copingDiabetes/self-care-general.png'
import headerUnderline from '../../../../assets/images/modal-header-underline.png'
import spiritSelfCareImg from '../../../../assets/images/copingDiabetes/spirit-self-care.png'
import spiritSelfCareRemindersImg from '../../../../assets/images/copingDiabetes/spirit-self-care-reminders.png'
import { ExternalLink } from '../../../ExternalLink/ExternalLink'
import styles from './SelfCareModals.scss'

export const SelfCareModals = () => {
  const [tipModels, setTipModels] = useState({
    tipModelOne: false,
    tipModelTwo: false,
    tipModelThree: false,
    tipModelFour: false,
    tipModelFive: false,
    tipModelSix: false,
    tipModelSeven: false,
    tipModelEight: false,
    tipModelNine: false,
    tipModelTen: false,
    tipModelEleven: false,
    tipModelTwelve: false,
    tipModelThirteen: false,
    tipModelFourteen: false,
  })

  const getTipModelHandler = (tipModelName) => {
    return {
      isOpen: tipModels[tipModelName],
      open: () =>
        setTipModels((state) => ({ ...state, [tipModelName]: true })),
      close: () =>
        setTipModels((state) => ({ ...state, [tipModelName]: false })),
    }
  }

  const tipModelOne = getTipModelHandler('tipModelOne')
  const tipModelTwo = getTipModelHandler('tipModelTwo')
  const tipModelThree = getTipModelHandler('tipModelThree')
  const tipModelFour = getTipModelHandler('tipModelFour')
  const tipModelFive = getTipModelHandler('tipModelFive')
  const tipModelSix = getTipModelHandler('tipModelSix')
  const tipModelSeven = getTipModelHandler('tipModelSeven')
  const tipModelEight = getTipModelHandler('tipModelEight')
  const tipModelNine = getTipModelHandler('tipModelNine')
  const tipModelTen = getTipModelHandler('tipModelTen')
  const tipModelEleven = getTipModelHandler('tipModelEleven')
  const tipModelTwelve = getTipModelHandler('tipModelTwelve')
  const tipModelThirteen = getTipModelHandler('tipModelThirteen')
  const tipModelFourteen = getTipModelHandler('tipModelFourteen')

  const nextModal = (current, next) => {
    current.close();
    next.open();
  }


  return (
    <>
      <div className="self-care-row">
        <div className="self-care-card">
            <button tabindex="19" className="button"
              onClick={tipModelOne.open}>
                <img src={image7} alt="Self-care Body "/>
          </button>
        </div>                 
        <div className="self-care-card">
          <button tabindex="20" className="button"
            onClick={tipModelFive.open}
          >
              <img src={image8} alt="Self-care Mind"/>
          </button>
        </div>
      </div>
      <div className="self-care-row">
        <div className="self-care-card">
          <button tabindex="21" className="button"
            onClick={tipModelEight.open}>
              <img src={image9} alt="Self-care Spirit"/>
          </button>
        </div>                 
        <div className="self-care-card"> 
          <button tabindex="22" className="button"
            onClick={tipModelFourteen.open}>
              <img src={image10} alt="General Self-care"/>
          </button>
        </div>
      </div>
      <div className={styles["dialog-wrapper"]}>
        <Dialog
          role="dialog"
          open={tipModelOne.isOpen}
          onClose={tipModelOne.close}
          className="dsmes-modal modal-theme-yellow"
          aria-label="Self-Care Body"
        >
          <button 
           className="modal-close-btn"
           onClick={tipModelOne.close}
           aria-label="Close"
          >
            <CloseIcon className="modal-close-icon" />
          </button>
          <SelfCareModalContent01/>
          <div className="modal-footer">
            <button 
              onClick={() => nextModal(tipModelOne, tipModelTwo)}
              className="next"
              aria-label="Next"
            >
              <svg width="17" height="28" viewBox="0 0 17 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 26L14 14L2 2" stroke="white" strokeWidth="3" strokeLinecap="round"/>
              </svg>
            </button>
          </div>
        </Dialog>
        <Dialog
          open={tipModelTwo.isOpen}
          role="dialog"
          onClose={tipModelTwo.close}
          className="dsmes-modal modal-theme-yellow"
          aria-label="Self-Care Move"
        >
          <button 
           className="modal-close-btn"
           onClick={tipModelTwo.close}
           aria-label="Close"
          >
            <CloseIcon className="modal-close-icon" />
          </button>
          <SelfCareModalContent02/>
          <div className="modal-footer">
          <button 
              onClick={() => nextModal(tipModelTwo, tipModelOne)}
              className="prev"
              aria-label="Previous"
            >
              <svg width="17" height="28" viewBox="0 0 17 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15 2L3 14L15 26" stroke="white" strokeWidth="3" strokeLinecap="round"/>
              </svg>
            </button>
            <button 
              onClick={() => nextModal(tipModelTwo, tipModelThree)}
              className="next"
              aria-label="Next"
            >
              <svg width="17" height="28" viewBox="0 0 17 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 26L14 14L2 2" stroke="white" strokeWidth="3" strokeLinecap="round"/>
              </svg>
            </button>
          </div>
        </Dialog>
        <Dialog
          role="dialog"
          open={tipModelThree.isOpen}
          onClose={tipModelThree.close}
          className="dsmes-modal modal-theme-yellow"
          aria-labelledby="Self-Care Refuel"
        >
          <button 
           className="modal-close-btn"
           onClick={tipModelThree.close}
           aria-label="Close"
          >
            <CloseIcon className="modal-close-icon" />
          </button>
          <SelfCareModalContent03/>
          <div className="modal-footer">
          <button 
              onClick={() => nextModal(tipModelThree, tipModelTwo)}
              className="prev"
              aria-label="Previous"
            >
              <svg width="17" height="28" viewBox="0 0 17 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15 2L3 14L15 26" stroke="white" strokeWidth="3" strokeLinecap="round"/>
              </svg>
            </button>
            <button 
              onClick={() => nextModal(tipModelThree, tipModelFour)}
              className="next"
              aria-label="Next"
            >
              <svg width="17" height="28" viewBox="0 0 17 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 26L14 14L2 2" stroke="white" strokeWidth="3" strokeLinecap="round"/>
              </svg>
            </button>
          </div>
        </Dialog>
        <Dialog
          role="dialog"
          open={tipModelFour.isOpen}
          onClose={tipModelFour.close}
          className="dsmes-modal modal-theme-yellow"
          aria-label="Self-Care Rest"
        >
          <button 
           className="modal-close-btn"
           onClick={tipModelFour.close}
           aria-label="Close"
          >
            <CloseIcon className="modal-close-icon" />
          </button>

          <SelfCareModalContent04/>
          <div className="modal-footer">
          <button 
              onClick={() => nextModal(tipModelFour, tipModelThree)}
              className="prev"
              aria-label="Previous"
            >
              <svg width="17" height="28" viewBox="0 0 17 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15 2L3 14L15 26" stroke="white" strokeWidth="3" strokeLinecap="round"/>
              </svg>
            </button>
            <button 
              onClick={() => nextModal(tipModelFour, tipModelFive)}
              className="next"
              aria-label="Next"
            >
              <svg width="17" height="28" viewBox="0 0 17 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 26L14 14L2 2" stroke="white" strokeWidth="3" strokeLinecap="round"/>
              </svg>
            </button>
          </div>
        </Dialog>
        <Dialog
          role="dialog"
          open={tipModelFive.isOpen}
          onClose={tipModelFive.close}
          className="dsmes-modal modal-theme-yellow"
          aria-label="Mind - Positive self-talk as self-care"
        >
          <button 
           className="modal-close-btn"
           onClick={tipModelFive.close}
           aria-label="Close"
          >
            <CloseIcon className="modal-close-icon" />
          </button>

          <SelfCareModalContent05/>
          <div className="modal-footer">
          <button 
              onClick={() => nextModal(tipModelFive, tipModelFour)}
              className="prev"
              aria-label="Previous"
            >
              <svg width="17" height="28" viewBox="0 0 17 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15 2L3 14L15 26" stroke="white" strokeWidth="3" strokeLinecap="round"/>
              </svg>
            </button>
            <button 
              onClick={() => nextModal(tipModelFive, tipModelSix)}
              className="next"
              aria-label="Next"
            >
              <svg width="17" height="28" viewBox="0 0 17 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 26L14 14L2 2" stroke="white" strokeWidth="3" strokeLinecap="round"/>
              </svg>
            </button>
          </div>
        </Dialog>
        <Dialog
          role="dialog"
          open={tipModelSix.isOpen}
          onClose={tipModelSix.close}
          className="dsmes-modal modal-theme-yellow"
          aria-label="Mind - Mindfulness and Meditation"
        >
          <button 
           className="modal-close-btn"
           onClick={tipModelSix.close}
           aria-label="Close"
          >
            <CloseIcon className="modal-close-icon" />
          </button>

          <SelfCareModalContent06/>
          <div className="modal-footer">
          <button 
              onClick={() => nextModal(tipModelSix, tipModelFive)}
              className="prev"
              aria-label="Previous"
            >
              <svg width="17" height="28" viewBox="0 0 17 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15 2L3 14L15 26" stroke="white" strokeWidth="3" strokeLinecap="round"/>
              </svg>
            </button>
            <button 
              onClick={() => nextModal(tipModelSix, tipModelSeven)}
              className="next"
              aria-label="Next"
            >
              <svg width="17" height="28" viewBox="0 0 17 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 26L14 14L2 2" stroke="white" strokeWidth="3" strokeLinecap="round"/>
              </svg>
            </button>
          </div>
        </Dialog>
        <Dialog
          role="dialog"
          open={tipModelSeven.isOpen}
          onClose={tipModelSeven.close}
          className="dsmes-modal modal-theme-yellow"
          aria-label="Mind - Therapy"
        >
          <button 
           className="modal-close-btn"
           onClick={tipModelSeven.close}
           aria-label="Close"
          >
            <CloseIcon className="modal-close-icon" />
          </button>

          <SelfCareModalContent07/>
          <div className="modal-footer">
          <button 
              onClick={() => nextModal(tipModelSeven, tipModelSix)}
              className="prev"
              aria-label="Previous"
            >
              <svg width="17" height="28" viewBox="0 0 17 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15 2L3 14L15 26" stroke="white" strokeWidth="3" strokeLinecap="round"/>
              </svg>
            </button>
            <button 
              onClick={() => nextModal(tipModelSeven, tipModelEight)}
              className="next"
              aria-label="Next"
            >
              <svg width="17" height="28" viewBox="0 0 17 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 26L14 14L2 2" stroke="white" strokeWidth="3" strokeLinecap="round"/>
              </svg>
            </button>
          </div>
        </Dialog>
        <Dialog
          role="dialog"
          open={tipModelEight.isOpen}
          onClose={tipModelEight.close}
          className="dsmes-modal modal-theme-yellow"
          aria-label="Mind - Therapy"
        >
          <button 
           className="modal-close-btn"
           onClick={tipModelEight.close}
           aria-label="Close"
          >
            <CloseIcon className="modal-close-icon" />
          </button>

          <SelfCareModalContent08/>
          <div className="modal-footer">
          <button 
              onClick={() => nextModal(tipModelEight, tipModelSeven)}
              className="prev"
              aria-label="Previous"
            >
              <svg width="17" height="28" viewBox="0 0 17 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15 2L3 14L15 26" stroke="white" strokeWidth="3" strokeLinecap="round"/>
              </svg>
            </button>
            <button 
              onClick={() => nextModal(tipModelEight, tipModelNine)}
              className="next"
              aria-label="Next"
            >
              <svg width="17" height="28" viewBox="0 0 17 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 26L14 14L2 2" stroke="white" strokeWidth="3" strokeLinecap="round"/>
              </svg>
            </button>
          </div>
        </Dialog>
        <Dialog
          role="dialog"
          open={tipModelNine.isOpen}
          onClose={tipModelNine.close}
          className="dsmes-modal modal-theme-yellow"
          aria-label="Mind - Therapy"
        >
          <button 
           className="modal-close-btn"
           onClick={tipModelNine.close}
           aria-label="Close"
          >
            <CloseIcon className="modal-close-icon" />
          </button>

          <SelfCareModalContent09/>
          <div className="modal-footer">
          <button 
              onClick={() => nextModal(tipModelNine, tipModelEight)}
              className="prev"
              aria-label="Previous"
            >
              <svg width="17" height="28" viewBox="0 0 17 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15 2L3 14L15 26" stroke="white" strokeWidth="3" strokeLinecap="round"/>
              </svg>
            </button>
            <button 
              onClick={() => nextModal(tipModelNine, tipModelTen)}
              className="next"tipModelNine
              aria-label="Next"
            >
              <svg width="17" height="28" viewBox="0 0 17 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 26L14 14L2 2" stroke="white" strokeWidth="3" strokeLinecap="round"/>
              </svg>
            </button>
          </div>
        </Dialog>
        <Dialog
          role="dialog"
          open={tipModelTen.isOpen}
          onClose={tipModelTen.close}
          className="dsmes-modal modal-theme-yellow"
          aria-label="Mind - Therapy"
        >
          <button 
           className="modal-close-btn"
           onClick={tipModelTen.close}
           aria-label="Close"
          >
            <CloseIcon className="modal-close-icon" />
          </button>

          <SelfCareModalContent10/>
          <div className="modal-footer">
          <button 
              onClick={() => nextModal(tipModelTen, tipModelNine)}
              className="prev"
              aria-label="Previous"
            >
              <svg width="17" height="28" viewBox="0 0 17 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15 2L3 14L15 26" stroke="white" strokeWidth="3" strokeLinecap="round"/>
              </svg>
            </button>
            <button 
              onClick={() => nextModal(tipModelTen, tipModelEleven)}
              className="next"tipModelTen
              aria-label="Next"
            >
              <svg width="17" height="28" viewBox="0 0 17 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 26L14 14L2 2" stroke="white" strokeWidth="3" strokeLinecap="round"/>
              </svg>
            </button>
          </div>
        </Dialog>
        <Dialog
          role="dialog"
          open={tipModelEleven.isOpen}
          onClose={tipModelEleven.close}
          className="dsmes-modal modal-theme-yellow"
          aria-label="Mind - Therapy"
        >
          <button 
           className="modal-close-btn"
           onClick={tipModelEleven.close}
           aria-label="Close"
          >
            <CloseIcon className="modal-close-icon" />
          </button>

          <SelfCareModalContent11/>
          <div className="modal-footer">
          <button 
              onClick={() => nextModal(tipModelEleven, tipModelTen)}
              className="prev"
              aria-label="Previous"
            >
              <svg width="17" height="28" viewBox="0 0 17 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15 2L3 14L15 26" stroke="white" strokeWidth="3" strokeLinecap="round"/>
              </svg>
            </button>
            <button 
              onClick={() => nextModal(tipModelEleven, tipModelTwelve)}
              className="next"tipModelEleven
              aria-label="Next"
            >
              <svg width="17" height="28" viewBox="0 0 17 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 26L14 14L2 2" stroke="white" strokeWidth="3" strokeLinecap="round"/>
              </svg>
            </button>
          </div>
        </Dialog>
        <Dialog
          role="dialog"
          open={tipModelTwelve.isOpen}
          onClose={tipModelTwelve.close}
          className="dsmes-modal modal-theme-yellow"
          aria-label="Mind - Therapy"
        >
          <button 
           className="modal-close-btn"
           onClick={tipModelTwelve.close}
           aria-label="Close"
          >
            <CloseIcon className="modal-close-icon" />
          </button>

          <SelfCareModalContent12/>
          <div className="modal-footer">
          <button 
              onClick={() => nextModal(tipModelTwelve, tipModelEleven)}
              className="prev"
              aria-label="Previous"
            >
              <svg width="17" height="28" viewBox="0 0 17 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15 2L3 14L15 26" stroke="white" strokeWidth="3" strokeLinecap="round"/>
              </svg>
            </button>
            <button 
              onClick={() => nextModal(tipModelTwelve, tipModelThirteen)}
              className="next"tipModelTwelve
              aria-label="Next"
            >
              <svg width="17" height="28" viewBox="0 0 17 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 26L14 14L2 2" stroke="white" strokeWidth="3" strokeLinecap="round"/>
              </svg>
            </button>
          </div>
        </Dialog>
        <Dialog
          role="dialog"
          open={tipModelThirteen.isOpen}
          onClose={tipModelThirteen.close}
          className="dsmes-modal modal-theme-yellow"
          aria-label="Mind - Therapy"
        >
          <button 
           className="modal-close-btn"
           onClick={tipModelThirteen.close}
           aria-label="Close"
          >
            <CloseIcon className="modal-close-icon" />
          </button>

          <SelfCareModalContent13/>
          <div className="modal-footer">
          <button 
              onClick={() => nextModal(tipModelThirteen, tipModelTwelve)}
              className="prev"
              aria-label="Previous"
            >
              <svg width="17" height="28" viewBox="0 0 17 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15 2L3 14L15 26" stroke="white" strokeWidth="3" strokeLinecap="round"/>
              </svg>
            </button>
            <button 
              onClick={() => nextModal(tipModelThirteen, tipModelFourteen)}
              className="next"tipModelThirteen
              aria-label="Next"
            >
              <svg width="17" height="28" viewBox="0 0 17 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 26L14 14L2 2" stroke="white" strokeWidth="3" strokeLinecap="round"/>
              </svg>
            </button>
          </div>
        </Dialog>
        <Dialog
          role="dialog"
          open={tipModelFourteen.isOpen}
          onClose={tipModelFourteen.close}
          className="dsmes-modal modal-theme-yellow"
          aria-label="Mind - Therapy"
        >
          <button 
           className="modal-close-btn"
           onClick={tipModelFourteen.close}
           aria-label="Close"
          >
            <CloseIcon className="modal-close-icon" />
          </button>

          <SelfCareModalContent14/>
          <div className="modal-footer">
          <button 
              onClick={() => nextModal(tipModelFourteen, tipModelThirteen)}
              className="prev"
              aria-label="Previous"
            >
              <svg width="17" height="28" viewBox="0 0 17 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15 2L3 14L15 26" stroke="white" strokeWidth="3" strokeLinecap="round"/>
              </svg>
            </button>
            <button 
              onClick={() => nextModal(tipModelFourteen, tipModelOne)}
              className="next"tipModelFourteen
              aria-label="Next"
            >
              <svg width="17" height="28" viewBox="0 0 17 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 26L14 14L2 2" stroke="white" strokeWidth="3" strokeLinecap="round"/>
              </svg>
            </button>
          </div>
        </Dialog>
      </div>
    </>
  )
}

export default SelfCareModals