import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import './StressTabs.css'
import tabData from './tabData'


function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  const childrenObj = {__html:children};
  return (
        <div
            role="tabpanel"
            className="tabWhiteBack"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
            >
            {value === index && (
                <Box sx={{ p: 3 }}>
                <Typography component="span" dangerouslySetInnerHTML={childrenObj}></Typography>
                </Box>
            )}
        </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function a11yProps(index) {
  var tabIndex = index+15;
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`, 
    tabIndex: tabIndex
  };
}

export const BasicTabs = (props) => {
  const { title, option} = props ;
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  
  

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Description" {...a11yProps(0, 'description')} />
          <Tab label="Symptoms" {...a11yProps(1, 'symptoms')} />
          <Tab label="Treatment" {...a11yProps(2, 'treatment')} />
        </Tabs>
      </Box>
      
      {/* data output */
      }
      <CustomTabPanel value={value} index={0} children={tabData[option][title][0]['description']}>
       {/* This is where the "Description" tab panel data will display */       }
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1} children={tabData[option][title][1]['symptom']}>
      {/* This is where the "Symptom" tab panel data for will display */}
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2} children={tabData[option][title][2]['treatment']}>
      {/* This is where "Treatment" the tab panel data for distress will display */}
      </CustomTabPanel>
      
    </Box>
  );
}

export default BasicTabs
