
import Vimeo from '@u-wave/react-vimeo'
// import YouTube from 'react-youtube';
import { ExternalLink } from '../ExternalLink/ExternalLink'
import homeThumb from '../../assets/images/Home Page_Michelle.jpg'
import monitorThumb from '../../assets/images/Monitoring Your Diabetes_Kineka.jpg'
import livingThumb from '../../assets/images/Living with Diabetes_Marcella.jpg'
import supportThumb from '../../assets/images/double-dutch.png'
import qualityThumb from '../../assets/images/Getting Quality Care_Kineka.jpg'
import physicalThumb from '../../assets/images/Increasing Physical Activity.jpg'
import findingThumb from '../../assets/images/Finding the Right Provide_Mia.jpg'
import cookingThumb from '../../assets/images/Creative Cooking and Eating_Velma.jpg'
import altLinkQuality from '../../assets/DAT Kineka\'s Story Getting Quality Care.pdf'
import altLinkMonitor from '../../assets/DAT Kineka\'s Story Monitoring Diabetes.pdf'
import altLinkLiving from '../../assets/DAT Marcella\'s Story LivingWithDiabetes.pdf'
import altLinkFinding from '../../assets/DAT Mia\'s Story Finding the Right Healthcare Provider.pdf'
import altLinkSelfCare from '../../assets/DAT Michelle\'s Story Importance of Self-Care.pdf'
import altLinkCreative from '../../assets/DAT Velma\'s Story Creative Eating and Cooking.pdf'
import altLinkPhysical from '../../assets/DAT Velma\'s Story IncreasingPhysicalActivity.pdf'
import ReactPlayer from 'react-player'
import './FindSisterhood.scss'

export const FindSisterhood = (props) => {
  var thumb = homeThumb
  var text = ""
  var link = ""
  var linkText = ""
  var text2 = ""
  var head = ""
  var alt = ""
  var audioDesc = ""
  var videoLink = ""
  var altLink = ""
  var altDesc = ""

 if (props.topic == 'home') {
  thumb = homeThumb
  head = "Finding Sisterhood"
  text = "<div><b>Did you know one in every four Black women over age 55 is living with type 2 diabetes?</b></div><div>You are not alone. There are other women who share your experience. There’s sisterhood and support on this journey! Hear from Michelle, a diabetes care and education specialist, about the importance of self-care.</div>"
  alt="Michelle sitting at a desk in front a computer and talking into a headset."
  videoLink = "https://www.youtube.com/watch?v=kBqSlTz6op0"
  altDesc = "Audio described PDF"
  altLink=altLinkSelfCare
 }

 if (props.topic == "monitor") {
  thumb = monitorThumb
  head = "Kineka's Story"
  text="Learn more about how Kineka monitors her diabetes in a professional setting.<br /><br />*Unlike the other Black women living with type 2 diabetes featured in our series of short stories throughout this site, Kineka was diagnosed with latent autoimmune adult diabetes (LADA), also known as diabetes 1.5."
  link = "https://www.mayoclinic.org/diseases-conditions/type-1-diabetes/expert-answers/lada-diabetes/faq-20057880"
  linkText = "Click here to learn more"
  text2 = "about this fairly new type of diabetes."
  alt="Kineka sitting at a desk with a laptop open and a mobile phone in her hand."
  videoLink = "https://www.youtube.com/watch?v=JqC6ioq4dF8"
  altDesc = "Audio described PDF"
  altLink=altLinkMonitor
 }

 if (props.topic == "cooking") {  
  thumb = cookingThumb
  head = "Velma’s Story"
  text="Check out Velma’s journey to gaining healthier cooking and eating habits."
  alt="Velma in a home kitchen preparing a meal."
  videoLink = "https://www.youtube.com/watch?v=PvhRw1UAJ08"
  altDesc = "Audio described PDF"
  altLink=altLinkCreative
 }
 if (props.topic == "physical") {  
  thumb = physicalThumb
  head = "Velma’s Story #2"
  text="Sisters are doing it for themselves! Take a look at how Velma and her sister are pounding the pavement and staying active to help manage their diabetes."
  alt="Velma and another woman exercising."
  videoLink = "https://www.youtube.com/watch?v=7lQ_KdoLvrM"
  altDesc = "Audio described PDF"
  altLink=altLinkPhysical
 }
 if (props.topic == "quality") {  
  thumb = qualityThumb
  head = "Kineka’s Story #2"
  text="Listen to Kineka share more on the ups and downs of her diagnosis story and pathway to quality care."
  alt="Kineka giving an interview."
  videoLink = "https://www.youtube.com/watch?v=B403OP2Td84"
  altDesc = "Audio described PDF"
  altLink=altLinkQuality
 }
 if (props.topic == "finding") {  
  thumb = findingThumb
  head = "Mia’s Story"
  text="Looking for the right health care provider? Click here to learn more about Mia’s relationship with her personal physician."
  alt="Mia sitting in a park."
  videoLink = "https://www.youtube.com/watch?v=4c50TFOrtHY"
  altDesc = "Audio described PDF"
  altLink=altLinkFinding
 }
 if (props.topic == "support") {  
  thumb = supportThumb
  head = "Alexis’s Story"
  text="Seeking community? Click here to learn more about Alexis and her road to finding sisterly support and fun through the "
  link="https://www.40plusdoubledutchclub.org/"
  linkText = "40+ Double Dutch Club."
  text2 = ""
  alt="Women playing double dutch."
  videoLink = "https://www.youtube.com/watch?v=4g5J5J7cuSo"
  altLink = "https://www.cdc.gov/diabetes/videos/your-diabetes-compass/Alexis-Story-Creating-Community-Support-Audio%20DescriptionsHighRes.mp4"
  altDesc = "Audio described version of video."
 }
 if (props.topic == "living") {  
  thumb = livingThumb
  head = "Marcela’s Story"
  text="Watch as Marcela finds purpose in serving her community, while living with diabetes."
  alt="Marcela loading a grocery bag with food donations."
  videoLink = "https://www.youtube.com/watch?v=MGu2LWjJIBI"
  altDesc = "Audio described PDF"
  altLink=altLinkLiving
 }
    return(
        <div className='h-container section-sisterhood'>
            <div className="gradient-bg-container">
              <div className="home-banner-container"> 
              
              <h2 className='display-h2'>{head}</h2>
              <div>
              <span dangerouslySetInnerHTML={{__html: text}}/>&nbsp;
                {<ExternalLink url={link} cname="org-button" aLabel={linkText}>{linkText}</ExternalLink>}&nbsp;
                <span dangerouslySetInnerHTML={{__html: text2}}/>
              </div>
                <div className="large-capsule">
                  {/*<img src={thumb} alt={alt}/>*/}
                  <div className="section-video video-wrapper" tabIndex={0} aria-label="Meet your Diabetes Compass Guide" aria-roledescription="video player" >
                    <ReactPlayer
                      width="100%"
                      height="100%"
                      controls
                      url={videoLink}
                    />
                    <p><a target="_blank" href={altLink}>{altDesc}</a></p>
                </div>
                </div>
            </div>
        </div>
    </div>
    )
}

export default FindSisterhood;
