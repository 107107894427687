import { useId } from 'react'
import flipCardImg01 from '../../../assets/images/healthyEating/bfy-good-fats-01.png'
import flipCardImg02 from '../../../assets/images/healthyEating/bfy-good-fats-02.png'
import flipCardImg03 from '../../../assets/images/healthyEating/bfy-good-fats-03.png'
import flipCardImg04 from '../../../assets/images/healthyEating/bfy-bad-fats-01.png'
import flipCardImg05 from '../../../assets/images/healthyEating/bfy-bad-fats-02.png'
import flipCardImg06 from '../../../assets/images/healthyEating/bfy-bad-fats-03.png'
import { ExternalLink } from '../../ExternalLink/ExternalLink'
import styles from './HealthyEatingModalCards.module.scss'

export const HealthyEatingModalContent04 = () => {
  const id = useId();
  return (
    <>
      <p>Fats are essential because they help your body absorb important vitamins. Too much fat in your diet can raise your cholesterol levels and increase your risk for diabetes complications, like heart disease and stroke. See examples below of better-for-you fats and fats to avoid. To learn more, read this <ExternalLink url="https://www.diabetes.org/healthy-living/recipes-nutrition/eating-well/fats">fats guide.</ExternalLink></p>
      <div className={styles["flip-card-grid"]}>
        <h5 className={styles["bfy-title"]}>Better-for-you Fats</h5>
        <div className={styles.card} tabIndex={0} aria-roledescription="flipping-card">
          <div className={styles["card-body"]}>
            <div className={styles["card-front"]}>
              <img src={flipCardImg01} alt="" aria-describedby={id + '-cardCaption01'} />
            </div>
            <div className={styles["card-back"]}>
              <p id={id + '-cardCaption01'} className={styles.bfy}>Avocados</p>
            </div>
          </div>
        </div>
        <div className={styles.card} tabIndex={0} aria-roledescription="flipping-card">
          <div className={styles["card-body"]}>
            <div className={styles["card-front"]}>
              <img src={flipCardImg02} alt="" aria-describedby={id + 'cardCaption02'} />
            </div>
            <div className={styles["card-back"]}>
              <p id={id + '-cardCaption02'} className={styles.bfy}>Salmon</p>
            </div>
          </div>
        </div>
        <div className={styles.card} tabIndex={0} aria-roledescription="flipping-card">
          <div className={styles["card-body"]}>
            <div className={styles["card-front"]}>
              <img src={flipCardImg03} alt="" aria-describedby={id + 'cardCaption03'} />
            </div>
            <div className={styles["card-back"]}>
              <p id={id + '-cardCaption03'} className={styles.bfy}>Olive Oil</p>
            </div>
          </div>
        </div>
        <h5 className={styles["avoid-title"]}>Fats to Avoid</h5>
        <div className={styles.card} tabIndex={0} aria-roledescription="flipping-card">
          <div className={styles["card-body"]}>
            <div className={styles["card-front"]}>
              <img src={flipCardImg04} alt="" aria-describedby={id + 'cardCaption04'} />
            </div>
            <div className={styles["card-back"]}>
              <p id={id + '-cardCaption04'} className={styles.avoid}>Whole Milk</p>
            </div>
          </div>
        </div>
        <div className={styles.card} tabIndex={0} aria-roledescription="flipping-card">
          <div className={styles["card-body"]}>
            <div className={styles["card-front"]}>
              <img src={flipCardImg05} alt="" aria-describedby={id + 'cardCaption05'} />
            </div>
            <div className={styles["card-back"]}>
              <p id={id + '-cardCaption05'} className={styles.avoid}>Deep Fried Foods</p>
            </div>
          </div>
        </div>
        <div className={styles.card} tabIndex={0} aria-roledescription="flipping-card">
          <div className={styles["card-body"]}>
            <div className={styles["card-front"]}>
              <img src={flipCardImg06} alt="" aria-describedby={id + 'cardCaption06'} />
            </div>
            <div className={styles["card-back"]}>
              <p id={id + '-cardCaption06'} className={styles.avoid}>Margarine</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}