import "./VideoLink.scss";
import { ExternalLink } from "../ExternalLink/ExternalLink";
import image from "../../../src/assets/images/videohead.png";

export const VideoLink = (props) => {
  return (
    <div id="videoRight">
      <ExternalLink
        tabindex="3"
        url={props.url}
        target="_blank"
        aLabel="YouTube Video Link"
      >
        <h3>
          Play
          <br />
          Video
        </h3>
        <img src={image} alt="Deborah, your guide to the diabetes compass." />
        <p>
          Click here so our navigator Deborah can give you a preview on the
          resources in this module.
        </p>
      </ExternalLink>
    </div>
  );
};
