import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

export const MonitoringDiabetesModalContent04 = () => {
  return (
    <>
      <p>Keeping track of what you eat is a great way to manage diabetes. Keeping a food journal will help you become more intentional about your food choices, and it’s the single best predictor of weight loss success.  Start by tracking what and when you eat – aim for at least three journal entries a day.  When you get comfortable with this, you can start tracking how much you eat and other information such as the number of calories you take in.  To get started, use this handy <a class="blueLink" href="https://www.cdc.gov/healthyweight/pdf/food_diary_cdc.pdf" target="_blank" rel="noopener noreferrer">food diary.</a></p>
      <p>Tracking your food can be challenging.  Below are some tips for how to overcome the challenges you might face. Select each challenge to see ways to overcome.</p>
      <div className="ideas-list">
        <Accordion className="md-modal-accordion">
          <AccordionSummary
            expandIcon={<ArrowRightIcon/>}
            id="modal05panel01-header"
            aria-controls="modal05panel01-content"
            className="md-modal-accordion-summary"
          >
            <h5 className="strong">I’m too busy</h5>
          </AccordionSummary>
          <AccordionDetails className="md-modal-accordion-details">
            <ul>
              <li>Make time to track. It takes just a couple minutes after each meal. </li>
              <li>Remember why you are tracking—to help manage your diabetes!</li>
              <li>Use a voice assistant to track it for you, like Apple Siri or Amazon Alexa.</li>
            </ul>
          </AccordionDetails>
        </Accordion>
        <Accordion className="md-modal-accordion">
          <AccordionSummary
            expandIcon={<ArrowRightIcon/>}
            id="modal05panel02-header"
            aria-controls="modal05panel02-content"
            className="md-modal-accordion-summary"
          >
            <h5 className="strong">I’m not a fan of writing things down</h5>
          </AccordionSummary>
          <AccordionDetails className="md-modal-accordion-details">
            <ul>
              <li>Record your voice with a smart phone or other device.</li>
              <li>Take a photo of your food.</li>
              <li>Ask your friends, or family members to help</li>
            </ul>
          </AccordionDetails>
        </Accordion>
        <Accordion className="md-modal-accordion">
          <AccordionSummary
            expandIcon={<ArrowRightIcon/>}
            id="modal05panel03-header"
            aria-controls="modal05panel03-content"
            className="md-modal-accordion-summary"
          >
            <h5 className="strong">I keep forgetting</h5>
          </AccordionSummary>
          <AccordionDetails className="md-modal-accordion-details">
            <ul>
              <li>Make tracking part of your daily routine.  </li>
              <li>Put a reminder on your phone or computer.  </li>
              <li>Set a timer.  </li>
              <li>Leave yourself notes where you’ll see them.  </li>
              <li>Ask friends and family to remind you. </li>
            </ul>
          </AccordionDetails>
        </Accordion>
        <Accordion className="md-modal-accordion">
          <AccordionSummary
            expandIcon={<ArrowRightIcon/>}
            id="modal05panel04-header"
            aria-controls="modal05panel04-content"
            className="md-modal-accordion-summary"
          >
            <h5 className="strong">I don’t like to track </h5>
          </AccordionSummary>
          <AccordionDetails className="md-modal-accordion-details">
            <ul>
              <li>Share your results with others.  </li>
              <li>Give yourself a small (nonfood) reward for meeting your food tracking goals.  </li>
              <li>Ask a friend or family member to track their food too.  </li>
              <li>Try using a smartphone or computer app that can track for you. </li>
            </ul>
          </AccordionDetails>
        </Accordion>
      </div>
      <p><strong>Pause, Reflect, Act: </strong> Think about the ways to help you track what you eat.</p>
    </>
  )
}
