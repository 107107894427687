import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Image from "../../../assets/images/hec2.jpeg";
import Image1 from "../../../assets/images/hec3.jpeg";
import Image2 from "../../../assets/images/hec4.jpeg";
import Image3 from "../../../assets/images/hec5.jpeg";
import { ExternalLink } from "../../ExternalLink/ExternalLink";
import "./Accordions.css";

export default function Accordions() {
  return (
    <>
      <hr className="healthy-accordion-section-hr" />
      <div className="healthy-accordion-section">
        <div className="healthy-accordion-section-block">
          <div tabIndex={0}>
            <img
              src={Image}
              className="healthy-accordion-section-block-img"
              alt="woman cooking"
            />
          </div>
          <div className="healthy-accordion-section-block-text">
            <h1 tabIndex={0}>Eating With Diabetes Pro Tips</h1>
            <p tabIndex={0}>
              Eating better-for-you foods doesn’t have to be boring. Check out
              some tips in the sections below to learn more about cooking
              heathy, measuring portion sizes, shopping at grocery stores,
              eating healthy while traveling, and getting support on how to eat
              well when you have diabetes.
            </p>
            <div className="healthy-accordion-section-accordions">
              <Accordion
                sx={{
                  backgroundColor: "#ffffff",
                  color: "#000000",
                  boxShadow: "none",
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <h4
                    tabIndex={0}
                    sx={{ fontWeight: "600", textAlign: "left" }}
                  >
                    Cooking
                  </h4>
                </AccordionSummary>
                <AccordionDetails>
                  <div
                    tabIndex={0}
                    className="healthy-accordion-section-cooking-section"
                  >
                    <img
                      src={Image1}
                      className="healthy-accordion-section-cooking-section-img"
                      alt="woman cooking"
                    />
                    <div>
                      <p
                        tabIndex={0}
                        className="healthy-accordion-section-cooking-section-text"
                      >
                        Soul food is delicious but can have too much fat and
                        salt. For example, you can swap out deep-fried chicken
                        for baked chicken.
                      </p>
                      <p>
                        For more ideas on how to make common soul foods better
                        for you, download{" "}
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://www.nhlbi.nih.gov/sites/default/files/media/docs/Session8-Recipe6Traditional-508.pdf"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          “Soul Food Makeover for African American Recipes,”
                        </a>{" "}
                        which includes recipes such as better-for-you greens
                        with lean meat, cornbread, and sweet potato pie.
                      </p>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
              <hr className="healthy-accordion-sections-hr" />

              <Accordion
                sx={{
                  backgroundColor: "#ffffff",
                  color: "#000000",
                  boxShadow: "none",
                  "&:before": { display: "none" },
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2bh-content"
                  id="panel2bh-header"
                >
                  <h4
                    tabIndex={0}
                    sx={{ fontWeight: "600", textAlign: "left" }}
                  >
                    Shopping Smart at Grocery Stores
                  </h4>
                </AccordionSummary>
                <AccordionDetails>
                  <div
                    tabIndex={0}
                    className="healthy-accordion-section-shopping-section"
                  >
                    <img
                      src={Image2}
                      className="healthy-accordion-section-shopping-section-img"
                      alt="woman shopping"
                    />
                    <p
                      tabIndex={0}
                      className="healthy-accordion-section-shopping-section-text"
                    >
                      How do you pick the right food at the grocery store? Read
                      this{" "}
                      <a
                        href="https://www.cdc.gov/diabetes/managing/eat-well/grocery-shopping.html"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Grocery Shopping Guide
                      </a>{" "}
                      to help you find the best foods, by shopping the outside
                      aisles for fresh fruits and vegetables.
                    </p>
                  </div>
                </AccordionDetails>
              </Accordion>
              <hr className="healthy-accordion-sections-hr" />

              <Accordion
                sx={{
                  backgroundColor: "#ffffff",
                  color: "#000000",
                  boxShadow: "none",
                  "&:before": { display: "none" },
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel3bh-content"
                  id="panel3bh-header"
                >
                  <h4
                    tabIndex={0}
                    sx={{ fontWeight: "600", textAlign: "left" }}
                  >
                    Eating Healthy While Traveling
                  </h4>
                </AccordionSummary>
                <AccordionDetails>
                  <div
                    tabIndex={0}
                    className="healthy-accordion-section-eating-section"
                  >
                    <img
                      src={Image3}
                      className="healthy-accordion-section-eating-section-img"
                      alt="woman shopping"
                    />
                    <span
                      tabIndex={0}
                      className="healthy-accordion-section-eating-section-text"
                    >
                      Traveling and not sure how to eat healthily?
                      <br />
                      <br />
                      Try the following tips on eating healthy while traveling.
                      <ul>
                        <li>
                          Pick healthy options at the airport such as fruit,
                          nuts, or salads with lean protein.
                        </li>
                        <li>
                          Bring a small bag you can place inside your carry-on
                          to hold snacks.
                        </li>
                        <li>
                          Be mindful when you’re on vacation of how much you
                          eat. For example, on a cruise, try viewing the
                          low-carb menu or portioning out your meals at the
                          buffets.
                        </li>
                        <li>
                          Swap out sodas and other sugary drinks for sparkling
                          flavored water or unsweetened tea.
                        </li>
                      </ul>
                      Check out{" "}
                      <a
                        href="https://www.cdc.gov/diabetes/library/features/traveling-with-diabetes.html"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Tips for Traveling with Diabetes
                      </a>{" "}
                      to learn more suggestions about how to keep healthy eating
                      in mind while on the go.
                    </span>
                  </div>
                </AccordionDetails>
              </Accordion>
              <hr className="healthy-accordion-sections-hr" />

              <Accordion
                sx={{
                  backgroundColor: "#ffffff",
                  color: "#000000",
                  boxShadow: "none",
                  "&:before": { display: "none" },
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel4bh-content"
                  id="panel4bh-header"
                >
                  <h4
                    tabIndex={0}
                    sx={{ fontWeight: "600", textAlign: "left" }}
                  >
                    Getting Help
                  </h4>
                </AccordionSummary>
                <AccordionDetails>
                  <p
                    tabIndex={0}
                    className="healthy-accordion-section-help-section"
                  >
                    Not sure where to start with healthy eating? Ask your health
                    care team to refer you to{" "}
                    <a
                      href="https://www.cdc.gov/diabetes/managing/education.html"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {" "}
                      diabetes self-management education and support (DSMES)
                    </a>{" "}
                    services or{" "}
                    <ExternalLink url="https://www.diabeteseducator.org/living-with-diabetes/find-an-education-program">
                      find a diabetes education program
                    </ExternalLink>{" "}
                    in your area.
                  </p>
                </AccordionDetails>
              </Accordion>
              <hr className="healthy-accordion-sections-hr" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
