import { Grid } from '@mui/material'
import SupportTopics from '../SupportTopics/SupportTopics'
import BannerImg from '../../assets/images/connectingHeader.jpeg'
import QuoteImg from '../../assets/images/connectingQuote.png'
import FooterImage from '../../assets/images/connectingFooter.jpeg'
import Carousel from './components/Carousel/Carousel'
import image from '../../assets/images/connectingChallenges.jpeg'
import icon1 from '../../assets/images/connecting-icon.png'
import icon2 from '../../assets/images/connecting-icon2.png'
import iconRide from '../../assets/images/connectingRide.png'
import icon4 from '../../assets/images/connecting-icon4.png'
import iconCost from '../../assets/images/connecting-cost.png'
import { ExternalLink } from '../ExternalLink/ExternalLink'
import { VideoLink } from '../VideoLink/VideoLink'
import RoutePaths from '../../routes'
import './ConnectingDSMES.css'

function ConnectingDSMES() {
  return (
    <>
      <div className="connecting-gradient-container">
        <div className="connecting-banner">
          <div className="connecting-banner-container">
            <div className="connecting-banner-text">
              <h1 class="display-h1"><span>Connecting</span> to Personalized Support Services - Diabetes Self-Management Education and Support</h1>
              <p>Personalized support services - diabetes self-management education and support (DSMES) are services to empower you and help you feel supported on your diabetes journey.</p>
              <p>When you participate in DSMES services, you’ll work with a diabetes care and education specialist. Diabetes care and education specialists are trained to help, listen, and support you. DSMES can help you on your journey to:</p>
              <ul>
                <li>Manage your diabetes to live a balanced and healthy life </li>
                <li>Find healthy ways to cope with stress </li>
                <li>Provide dedicated specialists to talk about diabetes </li>
                <li>Answer questions about diabetes that haven’t been answered </li>
              </ul>
              <p>Read on below to learn more about DSMES.</p>
            </div>
            <div className='connecting-banner-image-container'>
              <div className="connecting-banner-image-wrapper">
                <img role="presentation"  className="connecting-banner-img" src={BannerImg} alt="black woman smiling while working at a laptop " />
              </div>
            </div>
          </div>
        </div>
          <div className="connecting-testimonial-section">
            <div className="blockquote-wrapper">
              <blockquote className="quote EN">
                <h2 className="italic strong">Diabetes Self-Management Education and Support (DSMES) Insight</h2>
                <p>DSMES gives you the knowledge, skills and support to manage your diabetes. It provides you with the foundation to help you navigate your daily self-care with confidence. Studies even show that diabetes education helps you lower your blood sugar, blood pressure and cholesterol levels. This helps you stay healthier and reduce the risk of diabetes complications.</p>
              </blockquote>
            </div>
          </div> 
       
        <Carousel />
        <hr className="connecting-hr" />

        <div className="connecting-image-vector connecting-mobile-show">
          <img role="presentation"  src={image} className="connecting-block-img" alt="woman and child working together on a laptop" />
        </div>
        <div className="connection-challenges-section">
          <div className="connecting-section-block">
            <div className="connecting-image-vector connecting-mobile-no-show">
              <img role="presentation"  src={image} className="connecting-block-img" alt="woman and child working together on a laptop" />
            </div>
            <div className="connecting-block-text">
              <h2>Adding a new commitment isn’t always easy but you can overcome obstacles to stay in control of your health and well-being </h2>
                <div className="connecting-block-text-points"> 
                  <Grid container spacing={0}>
                    <Grid className=''  item xs={0} sm={0} md={1} lg={1}>
                      <></>
                    </Grid>
                    <Grid className=''  item xs={11} sm={11} md={11} lg={11}>
                      <p>Read on to learn about common challenges and potential ways to overcome them.</p> 
                    </Grid>
                  </Grid>
                  <Grid container spacing={4}>
                    <Grid className=''  item xs={1} sm={1} md={1} lg={1}>
                      <img role="presentation"  src={icon1} alt="" />
                    </Grid>
                    <Grid item xs={11} sm={11} md={11} lg={11}>
                      <p>
                      <strong>Getting a Referral:</strong> You will need a referral from a health care provider to participate in DSMES. Talk to your primary care provider or another member of your diabetes health care team. If you don’t have a health care provider who can refer you, call the DSMES program you’re interested in and ask about next steps.  
                      </p>
                    </Grid>
                  </Grid>
                  <Grid container spacing={4}>
                    <Grid className=''  item xs={1} sm={1} md={1} lg={1}>
                      <img role="presentation"  src={icon4} alt="" />
                    </Grid>
                    <Grid item xs={11} sm={11} md={11} lg={11}>
                      <p>
                      <strong>Finding a Program:</strong> You can use a program locator to find a DSMES program near you. Try the diabetes education program locators from the American Diabetes Association (<ExternalLink url="https://www.diabetes.org/tools-support/diabetes-education-program">ADA</ExternalLink>) or the Association of Diabetes Care & Education Specialists (<ExternalLink url="https://www.diabeteseducator.org/living-with-diabetes/find-an-education-program">ADCES</ExternalLink>). The program locators also allow you to search for programs that offer a telemedicine option. If you’re still having trouble finding a program, talk to your health care provider. Remember, you will need a referral from a health care provider to participate in DSMES.
                      </p>
                    </Grid>
                  </Grid>

                  <Grid container spacing={4}>
                    <Grid className=''  item xs={1} sm={1} md={1} lg={1}>
                      <img role="presentation"  src={iconCost} alt="" />
                    </Grid>
                    <Grid item xs={11} sm={11} md={11} lg={11}>
                      <p>
                      <strong>Cost:</strong> Check with your insurance provider to learn what is covered. Most private insurance plans cover DSMES. Your state’s Medicaid program may   cover DSMES for certain individuals. If you are 65 and older and have Medicare Part B, you may be eligible for up to 10 hours of  <ExternalLink url="https://www.diabetes.org/tools-support/diabetes-education-program">diabetes self-management training.</ExternalLink>
                      </p>
                    </Grid>
                  </Grid>

                  <Grid container spacing={4}>
                    <Grid className=''  item xs={1} sm={1} md={1} lg={1}>
                      <img role="presentation"  src={icon2} alt="" />
                    </Grid>
                    <Grid item xs={11} sm={11} md={11} lg={11}>
                      <p>
                      <strong>Making Time:</strong> Your schedule may already be full — it can be hard to imagine adding another activity. But remember, DSMES is here to help make things easier for you. The time you spend learning more about how to manage your diabetes will improve your health, well-being, and ability to do more of the things you love.  Think of how you spend the day. How can you fit DSMES into your daily routine so that you can start doing something good for your health?
                      </p>
                    </Grid>
                  </Grid>
                  <Grid container spacing={4}>
                    <Grid className=''  item xs={1} sm={1} md={1} lg={1}>
                      <img role="presentation"  src={iconRide} alt="" />
                    </Grid>
                    <Grid item xs={11} sm={11} md={11} lg={11}>
                      <p>
                      <strong>Getting a Ride:</strong> If driving yourself isn’t an option, check your public transportation routes. You can often get to DSMES using public transportation. Some local or state health departments offer free rides to and from certain health appointments including to and from DSMES services. Local community or religious groups may also offer rides to and from appointments. You can also ask a friend or family member for a ride to class — this makes them an active member of your diabetes support team.</p>
                    </Grid>
                  </Grid>
                </div>
            </div>
          </div>
        </div>
      </div>
      <div className="connecting-banner-bottom">
        <div className="connecting-banner-container-bottom">
          <div className="connecting-banner-image-wrapper">
            <img role="presentation"  className="connecting-banner-img connecting-banner-img-bottom" src={FooterImage} alt="black woman smiling at camera" />
          </div>
          <div >
            <p>You got this! Just by reviewing this site, you are one step closer to managing diabetes and living life to the fullest.</p>
          </div>
        </div>
      </div>
          <div id='supportTopics' className="SupportTopics">
          <SupportTopics/>
          </div>
    </>
  )
}

export default ConnectingDSMES
