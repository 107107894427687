import { useState } from 'react'

function Questions() {
  const [questions, setQuestions] = useState([
    { id: 1,
      question:   'Does my health care provider feel like a teammate on my journey with diabetes?',
      yesAnswer:  'Yes, I feel like my health care provider supports me and speaks to me honestly and with respect.',
      noAnswer:   'No, I may need to talk with my health care provider about how they might better support me on my journey with diabetes.',
      selectedAnswer: 'none',
    },
    { id: 2,
      question:   'Am I comfortable with my health care providers?',
      yesAnswer:  'Yes, I am comfortable being myself and asking questions.',
      noAnswer:   'No, I may need to talk with my health care providers to help them better understand me.',
      selectedAnswer: 'none',
    },
    { id: 3,
      question:   'When I ask my health care provider questions, do they answer in a way I can understand?',
      yesAnswer:  'Yes, my health care provider always gives me clear answers.',
      noAnswer:   'No, I may need to talk with my health care provider about ways they can more clearly answer my questions.',
      selectedAnswer: 'none',
    },
    { id: 4,
      question:   'Does my health care provider support my best interests?',
      yesAnswer:  'Yes, I trust my health care provider to support what’s best for me.',
      noAnswer:   'No, I may need to talk with my health care provider to help them understand what and how to support what’s best for me',
      selectedAnswer: 'none',
    },
    { id: 5,
      question:   'Does my health care provider understand my background and experience and what is important to me?',
      yesAnswer:  'Yes, my health care provider shows interest in understanding how my background and experience impact me.',
      noAnswer:   'No, I may need to talk with my health care provider to help them better understand my background and experience and what is important to me.',
      selectedAnswer: 'none',
    },
  ])
  const [currentQuestion, setCurrentQuestion] = useState(1);
  const handleQuestions = (id, answer) => {
    setQuestions(
      questions.map((question) => 
        question.id === id ? {...question, selectedAnswer: answer} : question
      )
    )
  }
  const handleTransitionEnd = () => {
    if(document.querySelector(".rqc-question-card.current button")) {
      document.querySelector(".rqc-question-card.current button").focus()
    }
  }
  const toggleYes = (event) => {
    if (event.target.getAttribute('aria-pressed') === "true") event.target.setAttribute("aria-pressed", "false")
    else event.target.setAttribute("aria-pressed", "true")
    if(event.target.parentElement.dataset.answer === 'yes') {
      handleQuestions(~~event.target.parentElement.dataset.id, 'none')
    } else {
      handleQuestions(~~event.target.parentElement.dataset.id, 'yes')
      setCurrentQuestion(currentQuestion + 1)
    }
  }
  const toggleNo = (event) => {
    if (event.target.getAttribute('aria-pressed') === "true") event.target.setAttribute("aria-pressed", "false")
    else event.target.setAttribute("aria-pressed", "true")
    if(event.target.parentElement.dataset.answer === 'no') {
      handleQuestions(~~event.target.parentElement.dataset.id, 'none')
    } else {
      handleQuestions(~~event.target.parentElement.dataset.id, 'no')
      setCurrentQuestion(currentQuestion + 1)
    }
  }
  const moveBack = () => {
    setCurrentQuestion(currentQuestion - 1)
  }
  return (
    <>
      <div className="rqc-questionnaire-container">
        <div className="rqc-questionnaire-title">
          <h2 className="display-h1">Start By Answering These Questions</h2>
        </div>
        <div className="rqc-question-wrapper">
          { questions.map((question) => (
            <div 
              className={`rqc-question-card${
                question.id === currentQuestion ? ' current' : ''
              }${
                question.id < currentQuestion ? ' answered' : ''
              }`}
              key={question.id}
              data-id={question.id}
              data-answer={question.selectedAnswer}
              onTransitionEnd={handleTransitionEnd}
            >
              <h3 className="h4 strong">{question.question}</h3>
              <button
                aria-pressed="false"
                onClick={toggleYes}
                className={`${ question.selectedAnswer === 'yes' ? 'selected' : ''}`}
                tabIndex={`${question.id === currentQuestion ? '0' : '-1'}`}
              >
                {question.yesAnswer}
              </button>
              <button
                aria-pressed="false"
                onClick={toggleNo}
                className={`${ question.selectedAnswer === 'no' ? 'selected' : ''}`}
                tabIndex={`${question.id === currentQuestion ? '0' : '-1'}`}
              >
                {question.noAnswer}
              </button>
            </div>
          ))}
          <div className={`rqc-question-card${ currentQuestion > questions.length ? ' current' : ''}`}>
            <div className={`response${ questions.every( o => (o.selectedAnswer === "yes")) ? ' show' : ''}`}>
              <p>
              That’s great! Your health care team should make you feel supported, understood, and cared for. Remember, it’s a team effort, continue reading below on the different members of your health care team.
              </p>
            </div>
            <div className={`response${ questions.every( o => (o.selectedAnswer === "yes")) ? '' : ' show'}`}>
              <p>
              You may want to talk to your current health care provider about how you feel. Your health care team should make you feel supported, understood, and cared for.  Be clear and honest and share your concerns and questions.  If you don’t understand something, ask questions until you do. health care provider need to know a lot about you, your family, and your lifestyle to give you the best medical care.  Speaking up and sharing your concerns can help you both make smart choices about your health.
              </p>
              <p>
              If you still do not feel like you vibe with your health care providers, you may need to find a diabetes care team that is a better fit for you. 
              </p>
            </div>
          </div>
        </div>
        <button 
          onClick={moveBack}
          aria-label="Back"
          className={`btn-back${currentQuestion === 1 ? ' inactive' : ''}`}
        >
          <svg width="17" height="28" viewBox="0 0 17 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15 2L3 14L15 26" stroke="white" strokeWidth="3" strokeLinecap="round"/>
          </svg>
        </button>
      </div>
    </>
  )
}

export default Questions
