import image1 from '../../../../assets/images/anxiety-description.png'
import image2 from '../../../../assets/images/anxiety-symptoms.png'
import image3 from '../../../../assets/images/anxiety-treatment.png'
import image4 from '../../../../assets/images/copingDiabetes/Depression-description.png'
import image5 from '../../../../assets/images/copingDiabetes/Depression-symptoms.png'
import image6 from '../../../../assets/images/copingDiabetes/Depression-treatment.png'
import image7 from '../../../../assets/images/copingDiabetes/Burnout-description.png'
import image8 from '../../../../assets/images/copingDiabetes/Burnout-symptoms.png'
import image9 from '../../../../assets/images/copingDiabetes/Burnout-treatment.png'

const tabData = [{
    anxiety:[{
      description:
        "<h3><strong>Anxiety Disorder</strong></h3><div class=\"panelFlex\">While everyone experiences some degree of anxiety from time to time, a person with an anxiety disorder feels an inappropriate amount of worry and fear. Needle phobia is one example. Panic attacks are the physical manifestation of acute anxiety.<img src="+image1+" alt=\"Cartoon depiction of a woman with anxiety disorder.\"/></div>"
      },{
        symptom:
        "<h3><strong>Anxiety Disorder</strong></h3><div class=\"panelFlex\"><ul><li>Frequent feelings of impending danger</li><li>Panic attacks may include trembling, sweating, pounding heart, and other physical symptoms.</li></ul><img src="+image2+" alt=\"Cartoon depiction of a woman with anxiety disorder.\"/></div>"
      },{
        treatment:
        "<h3><strong>Anxiety Disorder</strong></h3><div class=\"panelFlex\"><ul><li>Anxiety disorders should be treated by a qualified mental health professional. Cognitive behavioral therapy, mindfulness training and systematic desensitization can be effective.</li><li>Also, look for ways to improve your glucose control, as this may help to reduce symptoms.</li><li>If at any time you have thoughts of harming yourself, contact the National Suicide Prevention Hotline at 800-273-8255.</li></ul><img src="+image3+" alt=\"Cartoon depiction of a woman sitting at a table with her physician.\"/></div>"
      }
      ]
      },{
        depression:[{
          description: 
          "<h3><strong>Depression</strong></h3><div class=\"panelFlex\">Depression is more than just a bout of the blues and is different from diabetes burnout. Depression is a mood disorder that causes a persistent feeling of sadness and loss of interest in usual activities. It affects how you feel, think and behave and can lead to a variety of emotional and physical problems.<img src="+image4+" alt=\"Cartoon depiction of a woman with depression.\"/></div>"
        },{
          symptom:
          "<h3><strong>Depression</strong></h3><div class=\"panelFlex\"><ul><li>Difficulty concentrating, remembering details, and making decisions</li><li>Decreased energy</li><li>Feelings of guilt, worthlessness, or helplessness</li><li>Difficulty sleeping or excessive sleeping</li><li>Irritability, restlessness</li><li>Loss of interest in pleasurable activities</li><li>Overeating or appetite loss</li><li>Persistent digestive problems that do not ease, even with treatment</li><li>Persistent sad, anxious, or \"empty\" feelings</li><li>Thoughts of suicide or self-harm</li></ul><img src="+image5+" alt=\"Cartoon depiction of a woman with depression.\"/></div>"
        },{
          treatment:
          "<h3><strong>Depression</strong></h3><div class=\"panelFlex\"><ul><li>Depression isn't a weakness, and you can't simply \"snap out of it.\" Most people with depression feel better with medication, cognitive behavioral therapy (counseling), or both.</li><li>Find a qualified provider to lead the treatment process, and don’t hesitate to try various forms of treatment until you find one that works.</li><li>Also, look for ways to improve your glucose control, as this may decrease depression symptoms.</li></ul><img src="+image6+" alt=\"Cartoon depiction of a woman sitting with her counselor.\"/></div>"
        }
      ]
      },{
      distress:[{
        description:
        "<h3><strong>Diabetes burnout</strong></h3><div class=\"panelFlex\">Diabetes burnout is related to how you feel about diabetes and the tasks required to manage it. This doesn’t mean you’ve completely stopped caring for yourself, just that you’re really tired of managing diabetes.<img src="+image7+" alt=\"Cartoon depiction of a woman with diabetes burnout.\"/></div>"        
      },{
        symptom:
        "<h3><strong>Diabetes burnout</strong></h3><div class=\"panelFlex\"><ul><li>Eating more of the foods that you know mess up your blood sugar levels</li><li>Lying to others about your blood sugar levels</li><li>Intentionally skipping insulin or blood sugar checks</li><li>Intentionally letting your blood sugar run high</li><li>\"Winging it\" when it comes to insulin doses</li><li>Feeling full of anger or exhausted by diabetes</li></ul><img src="+image8+" alt=\"Cartoon depiction of a woman with diabetes burnout.\"/></div>"
      },{
        treatment:
        "<h3><strong>Diabetes burnout</strong></h3><div class=\"panelFlex\"><ul><li>Talk to your diabetes care and education specialist about simplifying your management program.</li><li>Break down diabetes management into small, manageable chunks.</li><li>Seek the support of peers.</li></ul><img src="+image9+" alt=\"Cartoon depiction of a woman reading a book.\"/></div>"
      }
      ]
      }
    ]

    export default tabData;
