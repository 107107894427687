import { useState, useId } from "react";
import bodyMindHeadImg from "../../../../assets/images/copingDiabetes/body-self-care-reminders-head.png";
import headerUnderline from "../../../../assets/images/modal-header-underline.png";
import styles from "./SelfCareModals.scss";

export const SelfCareModalContent05 = () => {
  const id = useId();
  return (
    <>
      <div id="modalTitle-1" className="modal-title">
        <h2 className="display-h2">
          Mind - Positive Self-Talk as <span className="nowrap">Self-Care</span>
        </h2>
        <img
          className="headerUnderline"
          src={headerUnderline}
          alt="decorative underline"
        />
      </div>
      <div id="modalContent-1" className="modal-content">
        <img
          className="headImg"
          src={bodyMindHeadImg}
          alt="woman in athletic gear holding a water bottle"
        />
        <p>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.cdc.gov/diabetes/managing/mental-health.html#:~:text=People%20with%20diabetes%20are%2020,sometimes%20both%20together%20works%20best"
          >
            Thoughts, feelings, beliefs, and attitudes can affect your physical
            health
          </a>
          . Negative thoughts cause us to lose hope or stand in the way of our
          progress. A negative thought is usually a criticism of ourselves. We
          all have negative thoughts from time to time, but it’s important to
          try to overcome them because they often lead us into making unhealthy
          decisions. Recognizing your feelings and taking steps to control them
          can have a positive effect on your quality of life. Here are a few
          resources to help you identify common negative thoughts and advice on
          how to change them into positive motivation.{" "}
        </p>
        <p className="borderCapsule">
          <strong>Article:</strong>{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.cdc.gov/diabetes/library/features/change_negative_thoughts_to_reach_your_goals.html"
          >
            Change Negative Thoughts to Reach Your Goals
          </a>
        </p>
        <p className="borderCapsule">
          <strong>Handout & Worksheet:</strong>{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.cdc.gov/diabetes/prevention/pdf/handout_session11.pdf"
          >
            Talk Back to Negative Thoughts
          </a>
        </p>
        <p className="borderCapsule">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.cdc.gov/diabetes/ndep/audio/yvonnes-story-1a.mp3"
          >
            Listen to Yvonne’s story
          </a>{" "}
          of how she went from denial about having diabetes to a more positive
          response.
        </p>
      </div>
    </>
  );
};
