import image from "../../assets/images/connectingHeader.jpeg";
import "./ConnectToSpecialist.scss";

export default function ConnectToSpecialist() {
  return (
    <div className="h-container section-specialist">
      <div className="gradient-bg-container">
        <div className="home-banner-container">
          <h2 className="display-h2">Want Additional Support?</h2>
          <h4>
            Learn and Connect to Diabetes Self-Management and Education Support
            (DSMES)
          </h4>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="connecting-to-DSMES"
            className="btn-specialist"
          >
            Connect to a Specialist
          </a>
          <img
            className="specialist"
            src={image}
            alt="A diabetes support specialist sitting at a desk with a laptop"
          />
        </div>
      </div>
    </div>
  );
}
