const p = process.env.PUBLIC_URL || ''
const base = p.endsWith('/') ? p.slice(0, -1) : p

const paths = [
  { name: 'Landing', path: ''},
  { name: 'Home', path: 'home' },
  { name: 'HomeSupportTopics', path: 'home#supportTopics' },
  { name: 'QualityCare', path: 'quality-care' },
  { name: 'HealthyEating', path: 'healthy-eating-cooking' },
  { name: 'PhysicalActivity', path: 'increasing-physical-activity' },
  { name: 'FindingProvider', path: 'finding-provider' },
  { name: 'CreatingCommunity', path: 'creating-community' },
  { name: 'CopingDiabetes', path: 'living-diabetes' },
  { name: 'LearningAboutDSMES', path: 'learning-about-DSMES' },
  { name: 'ConnectingToDSMES', path: 'connecting-to-DSMES' },
  { name: 'MonitoringDiabetes', path: 'monitoring-diabetes' },
  { name: 'ReducingRisk', path: 'reducing-risk' },
  { name: 'Quiz', path: 'learning-guide' },
  { name: 'QuizResult', path: 'quizResult' },
  { name: 'LearningDSMES', path: 'learningDSMES' },
  { name: 'StyleGuide', path: 'style-guide' },
]

export default paths.reduce((allPaths, p) => ({ ...allPaths, [p.name]: `${base}/${p.path}` }), {})
