// import portionSizeImg from '../../../assets/images/SelfCare/Diabetes-Manage-Eat-Well-Plate-Graphic_600px 1.png'
import headerUnderline from "../../../../assets/images/modal-header-underline.png";
import spiritImg from "../../../../assets/images/copingDiabetes/spirit-journaling-head.png";
import styles from "./SelfCareModals.scss";

export const SelfCareModalContent09 = () => {
  return (
    <>
      <div id="modalTitle-1" className="modal-title">
        <h2 className="display-h2">Spirit - Journaling</h2>
        <img
          className="headerUnderline"
          src={headerUnderline}
          alt="decorative underline"
        />
      </div>
      <div id="modalContent-1" className="modal-content">
        <img
          className="headImg"
          src={spiritImg}
          alt="woman at a table with a notebook and pen"
        />
        <p>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.cdc.gov/diabetes/library/spotlights/journalinghealth.html"
          >
            Journaling
          </a>{" "}
          offers many positive results, such as improving memory and boosting
          creativity. Recording your thoughts and feelings in a journal could
          help you manage things about diabetes that aren’t as easy to measure.
          For example,{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.cdc.gov/diabetes/managing/mental-health.html"
          >
            stress can raise blood sugar
          </a>
          . Regular journal entries could help pinpoint what’s causing the
          stress — and whether it’s a one-time thing or part of a pattern — and
          help point the way toward a solution you might not have thought of
          otherwise.
        </p>

        <p>
          Try setting aside a few minutes every day to write some personal
          notes. Keep your journal nearby for easy access or use a journaling
          app on your phone.{" "}
        </p>
      </div>
    </>
  );
};
