import { useState, useEffect } from 'react'
import RoutePaths from '../../../routes'
import useComponentVisible from './useComponentVisible';
import AddIcon from '@mui/icons-material/Add';
import Collapse from '@mui/material/Collapse';
import { useLocation } from 'react-router-dom'

export default function FloatingLearningGuide() {
    const {
        ref,
        isComponentVisible,
        setIsComponentVisible
      } = useComponentVisible(false);
    const [viewLearningGuide, setViewLearningGuide] = useState(false);
    const [learningGuide, setLearningGuide] = useState('');
    const location = useLocation();
    
    const toggleLearningGuideMenu = (event) => {
        if (document.getElementById("learningGuideButton")) {
          var x = document.getElementById("learningGuideButton").getAttribute("aria-expanded")
          if (x === "true") document.getElementById("learningGuideButton").setAttribute("aria-expanded", "false")
          else document.getElementById("learningGuideButton").setAttribute("aria-expanded", "true")
        }
        setIsComponentVisible(!isComponentVisible)
      }

    useEffect(() => {
        if(localStorage.getItem('dsmes_compass_date') !== null) {
            let weekAgo = Date.now() - (1000 * 60 * 60 * 24 * 7)
            if (parseInt(localStorage.dsmes_compass_date) > weekAgo ) {
              setViewLearningGuide(true)
            }
          }
        if(localStorage.getItem('dsmes_learning_guide') !== null) {
            setLearningGuide(localStorage.getItem('dsmes_learning_guide'));
          }
        },[location.pathname]) 
    

    return (
        <div ref={ref}>
            { (!viewLearningGuide && !(location.pathname.includes('/learning-guide'))) && 
            <div id="fab" className="fab-wrapper">
                <a className="fab" tabindex="99" href={RoutePaths.Quiz}>Build Your Learning Guide</a>
            </div> }
            { (viewLearningGuide && !(location.pathname.includes('/learning-guide'))) &&
            <div id="fab" className="fab-wrapper">
                <button id="learningGuideButton" aria-expanded="false" onClick={(e) => toggleLearningGuideMenu(e)} className={`${"fab"} ${isComponentVisible ? "active" : ""}`}>
                Your Learning Guide &nbsp; <AddIcon/>
                </button>
                <Collapse in={isComponentVisible} className="learning-guide-collapse-panel">
                <h2 className="h4 bold">Learning Guide</h2>
                <menu className="learning-guide-menu">
                {learningGuide.indexOf('md') !== -1 && (<>
                    <li className={location.pathname.includes('/monitoring-diabetes') ? "current" : ''}><a href={RoutePaths.MonitoringDiabetes}>Monitoring Diabetes</a></li>
                    <li className={location.pathname.includes('/reducing-risk') ? "current" : ''}><a href={RoutePaths.ReducingRisk}>Reducing Risk of Complications</a></li>
                </>)}
                {learningGuide.indexOf('qualityCare') !== -1 && (<>
                    <li className={location.pathname.includes('/quality-care') ? "current" : ''}><a href={RoutePaths.QualityCare}>Getting Quality Care</a></li>
                    <li className={location.pathname.includes('/finding-provider') ? "current" : ''}><a href={RoutePaths.FindingProvider}>Finding the Right Health Care Provider</a></li>
                </>)}
                {learningGuide.indexOf('healthyEating') !== -1 && (<>
                    <li className={location.pathname.includes('/healthy-eating-cooking') ? "current" : ''}><a href={RoutePaths.HealthyEating}>Creative Eating and Cooking</a></li>
                    <li className={location.pathname.includes('/increasing-physical-activity') ? "current" : ''}><a href={RoutePaths.PhysicalActivity}>Increasing Physical Activity</a></li>
                </>)}
                {learningGuide.indexOf('coping') !== -1 && (<>
                    <li className={location.pathname.includes('/living-diabetes') ? "current" : ''}><a href={RoutePaths.CopingDiabetes}>Living with Diabetes</a></li>
                </>)}
                {learningGuide.indexOf('community') !== -1 && (<>
                    <li className={location.pathname.includes('/creating-community') ? "current" : ''}><a href={RoutePaths.CreatingCommunity}>Creating Community Support</a></li>
                </>)}
                    <li className={location.pathname.includes('/connecting-to-DSMES') ? "current" : ''}><a href={RoutePaths.ConnectingToDSMES}>Connecting to DSMES</a></li>
                    
                </menu>
                </Collapse>
            </div>
            }
        </div>
    )
}