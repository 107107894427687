import image01 from "../../assets/images/IncreasingPhysicalActivity/pa-banner-img-01.jpeg";
import image02 from "../../assets/images/IncreasingPhysicalActivity/pa-banner-img-02.jpeg";
import { PhysicalActivityTips } from "./PhysicalActivityTips";
import SupportTopics from "../SupportTopics/SupportTopics";
import FindSisterhood from "../FindSisterhood/FindSisterhood";
import ConnectToSpecialist from "../ConnectToSpecialist/ConnectToSpecialist";
import { VideoLink } from "../VideoLink/VideoLink";
import "./IncreasingPhysicalActivity.scss";

export const IncreasingPhysicalActivity = () => {
  return (
    <>
      <div className="phys-activity-page">
        <div className="gradient-bg-container">
          <div className="pa-banner-container">
            <VideoLink url="https://www.youtube.com/watch?v=1CGFe6RimJQ" />
            <div className="pa-banner main-banner">
              <div className="pa-banner-text">
                <h1 className="display-h1">
                  <span>Increasing</span> Physical Activity
                </h1>
                <p>
                  Being physically active can be fun and is important for living
                  well with diabetes. Along with your diet and any medicines you
                  are taking, regular physical activity is an important part of
                  managing your blood sugar. Small changes in your physical
                  activity can make a big difference and can help you feel more
                  energetic and healthier all around.
                </p>
              </div>
              <div className="pa-banner-image">
                <div className="img-wrapper">
                  <img
                    role="presentation"
                    src={image01}
                    alt="Smiling Woman holding golf club and golf ball"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="pa-insight-section">
            <div className="blockquote-wrapper">
              <blockquote>
                <h2 className="strong italic">
                  Diabetes Self-Management Education and Support (DSMES) Insight
                </h2>
                <p>
                  Think of physical activity as a tool to manage your diabetes.
                  It helps control blood sugar levels and lowers your risk of
                  diabetes complications such as heart disease and nerve damage.
                </p>
              </blockquote>
            </div>
          </div>
          <PhysicalActivityTips />
        </div>
        <div className="pa-bottom-banner-container">
          <div className="pa-bottom-banner">
            <div className="pa-banner-text">
              <p>
                Being physically active doesn’t have to be a burden. The most
                important thing is to find activities you enjoy and do them as
                often as you can. Be sure to come up with a plan and stick to
                it.
              </p>
            </div>
            <div className="pa-banner-image">
              <div className="img-wrapper">
                <img
                  role="presentation"
                  src={image02}
                  alt="Woman practicing yoga"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="hideExternal">
          <FindSisterhood topic="physical" />
        </div>
        <div id="SupportTopics" className="SupportTopics">
          <SupportTopics />
        </div>
        <ConnectToSpecialist />
      </div>
    </>
  );
};
