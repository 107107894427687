import { MonitoringDiabetesTips } from "./components/MonitoringDiabetesTips";
import SupportTopics from "../SupportTopics/SupportTopics";
import image1 from "../../assets/images/monitoringDiabetes/monitoringDiabetes1.jpeg";
import image2 from "../../assets/images/monitoringDiabetes/monitoringDiabetes2.jpeg";
import infoPDF from "../../assets/images/monitoringDiabetes/monitoring1.png";
import quote from "../../assets/images/monitoringDiabetesQuote.jpg";
import { VideoLink } from "../VideoLink/VideoLink";
import FindSisterhood from "../FindSisterhood/FindSisterhood";
import ConnectToSpecialist from "../ConnectToSpecialist/ConnectToSpecialist";
import infographicMonitoring from "../../assets/Blood-Sugar-Monitor-Log.pdf";
import "./MonitoringDiabetes.scss";

export default function MonitoringDiabetes() {
  return (
    <>
      <div className="monitoring-diabetes-page">
        <div className="gradient-bg-container">
          <div className="md-banner ">
            <div className="md-banner-container main-banner">
              <VideoLink url="https://www.youtube.com/watch?v=1b7-FM31wmc" />
              <div className="md-banner-text">
                <h1 className="display-h1">
                  <span>Monitoring</span> Diabetes
                </h1>
                <p>
                  Living with diabetes means that you have to keep track of the
                  way you eat and your physical activity, as well as your blood
                  sugar and A1C. It is also important to check things like blood
                  pressure and cholesterol.
                </p>
                <p>
                  While not everything is within your control, monitoring can
                  help you better manage diabetes.
                </p>
              </div>
              <div className="md-banner-image">
                <div className="img-wrapper">
                  <img
                    role="presentation"
                    className="md-banner-img"
                    src={image1}
                    alt="woman checking her blood sugar"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="md-testimonial-section">
            <div className="blockquote-wrapper">
              <blockquote className="callout quote EN">
                <img
                  role="presentation"
                  className="md-testimonial-img"
                  src={quote}
                  alt="testimonial"
                />
                <p>
                  We know that there’s a lot to think about and monitor as part
                  of managing your diabetes. You can do this. Take it step by
                  step and believe in yourself!
                </p>
              </blockquote>
            </div>
          </div>
          <MonitoringDiabetesTips />
        </div>
        <div className="md-banner-bottom">
          <div className="md-banner-container-bottom">
            <div className="md-banner-text">
              <p>
                Living with diabetes comes with life changes, such as creating
                new habits and monitoring more aspects of your health. With the
                right resources and support, you can feel confident and
                empowered to build habits to maintain a happy, healthy life.
              </p>
              <p>
                Need a resource to keep you accountable with blood sugar
                monitoring? Download and try using this template to track your
                numbers.{" "}
              </p>

              <div className="cd-testimonial-section infoPDFFlex">
                <a
                  class="blueLink"
                  href={infographicMonitoring}
                  target="_blank"
                >
                  <img alt="Blood sugar monitor log template" src={infoPDF} />
                  <p>Downloadable Resource: Blood sugar monitor log template</p>
                </a>
              </div>
            </div>
            <div className="md-banner-image">
              <div className="img-wrapper">
                <img
                  role="presentation"
                  className="md-banner-img-bottom"
                  src={image2}
                  alt="woman smiling"
                />
              </div>
            </div>
          </div>
        </div>
        <FindSisterhood topic="monitor" />
        <div id="SupportTopics" className="SupportTopics">
          <SupportTopics />
        </div>
        <ConnectToSpecialist />
      </div>
    </>
  );
}
