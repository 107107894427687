import { useState, useEffect } from 'react';
import Fade from '@mui/material/Fade';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import image1 from '../../../../assets/images/learning-carousel.jpeg'
import image2 from '../../../../assets/images/learning-carousel2.jpeg'
import styles from './Carousel.module.scss'

function Carousel() {
  const [value, setValue] = useState('1')
  const [scroll, setScroll] = useState(true)

  useEffect(() => {
    if(scroll) {
      const interval = setInterval(() => {
        if (parseInt(value) + 1 === 3) {
          setValue('1')
        } else {
          setValue((parseInt(value) + 1).toString())
        }
      }, 10000)
      return () => clearInterval(interval)
    } else {
      return
    }    
  })

  const PauseScroll = (e) => {
    if (!scroll) {
      setScroll(true)
      e.target.setAttribute("aria-label", "currently playing, click to pause")
      e.target.setAttribute("aria-pressed", "true")
    }
    else if (scroll) {
      setScroll(false)
      e.target.setAttribute("aria-label", "currently paused, click to play")
      e.target.setAttribute("aria-pressed", "false")
    }
  }
  return (
    <>
      <div className={styles["carousel-wrapper"]}>
        <div className={styles["carousel-container"]}>
          <div className={styles["fade-container"]}>
            <Fade in={value === '1'}>
              <div className={styles["carousel-slide-wrapper"]}>
                <div className={styles["carousel-image-wrapper"]}>
                  <img className="dsmes-banner-img" src={image1} alt="black woman smiling" />
                </div>
                <div>
                  <h2 className="h1">How DSMES Can Help</h2><br />
                  <p>Talk to your primary care provider or another member of your diabetes health care team if you are curious about how DSMES can help you. You’ll need a referral from your health care provider to participate in DSMES.</p>
                  <p>Here are key times to ask your diabetes health care team about participating in DSMES: </p>
                  <ul>
                    <li>When you have been recently diagnosed with diabetes</li>
                    <li>When you are at a health care provider appointment </li>
                    <li>When you have developed new health complications, such as heart disease or vision impairment</li>
                    <li>When other life changes happen, such as a new job, that make managing your diabetes harder for you </li>
                  </ul>
                </div>
              </div>
            </Fade>
            <Fade in={value === '2'}>
              <div className={styles["carousel-slide-wrapper"]}>
                <div className={styles["carousel-image-wrapper"]}>
                  <img className="dsmes-banner-img" src={image2} alt="black woman smiling" />
                </div>
                <div>
                  <h2 className="h1">Working with a Diabetes Care and Education Specialist</h2><br />
                  <p>Once you are participating in a DSMES program, you will work with your diabetes care and education specialist to:  </p>
                  <ul>
                    <li>Create a diabetes management plan that works for you</li>
                    <li>Find ways to address challenges you experience managing diabetes </li>
                    <li>Find ways to cope with the stress you may feel while managing diabetes  </li>
                    <li>Prepare to talk to friends and family about your diabetes management  </li>
                    <li>Learn more skills to manage diabetes  </li>
                  </ul>
                </div>
              </div>
            </Fade>
          </div>
          <div className={styles["button-row"]}>
            <button 
              aria-label="view slide 1" 
              onClick={() => setValue('1')} 
              className={styles.btnQuote}
              data-active-quote={(value === "1")}
            >
              &#8226;
            </button>
            <button 
              aria-label="view slide 2" 
              onClick={() => setValue('2')} 
              className={styles.btnQuote}
              data-active-quote={(value === "2")}
            >
              &#8226;
            </button>
            <button
              aria-label="play and pause"
              onClick={PauseScroll}
            >
              {scroll ? (
                <PauseIcon />
              ) : (
                <PlayArrowIcon />
              )}
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default Carousel
