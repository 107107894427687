import styles from './HealthyEatingModalCards.module.scss'

export const HealthyEatingModalContent07 = () => {
  return (
    <>
      <p>When measuring the nutrients you eat and drink, try to follow this guidance for daily nutrition intake:</p>
      <div className={styles["bfy-goals"]}>
        <div className={styles["card"]}>
          <p>Have less than <br /><span className="h4 strong">25 grams</span><br /> of added <strong>sugars</strong> daily</p>
        </div>
        <div className={styles["card"]}>
          <p>Have <br /><span className="h4 strong">1,500 milligrams</span><br /> or less of <strong>sodium</strong> daily  </p>
        </div>
        <div className={styles["card"]}>
          <p>Have at least <br /><span className="h4 strong">20–30 grams</span><br /> of <strong>fiber</strong> daily</p>
        </div>
        <div className={styles["card"]}>
          <p>Have less than <br /><span className="h4 strong">13 milligrams</span><br /> of <strong>saturated fat</strong>  </p>
        </div>
        <div className={styles["card"]}>
          <p>Have fewer than <br /><span className="h4 strong">25%</span><br /> of daily calories from <strong>carbs</strong>* </p>
        </div>
      </div>
      <p>*For example, if you’re following a 2,000-calorie diet, you should have fewer than 500 of those calories from carbs. Because carbs provide 4 calories per gram, this means you’ll want to have less than 500 grams of carbs each day (2000÷4=500).  You can ask your health-care provider to help you figure out the best carb counting method for you. </p>
    </>
  )
}