import { useState } from 'react'
import image1 from '../../../../assets/images/findingProvider-img01.jpeg'
import { ExternalLink } from '../../../ExternalLink/ExternalLink'
import './ListImportant.css'

export default function ListImportant() {
  const [lists, setLists] = useState([
    { id: '1', name: 'Understands me as a Black woman', selected: false, },
    { id: '2', name: 'Encourages me to ask questions', selected: false, },
    { id: '3', name: 'Listens to what I say', selected: false, },
    { id: '4', name: 'Explains things clearly', selected: false, },
    { id: '5', name: 'Shows that they care', selected: false, },
    { id: '6', name: 'Is honest about my health', selected: false, },
    { id: '7', name: 'Tells me what I can do to be healthy', selected: false, },
    { id: '8', name: 'Is patient with me', selected: false, },
    { id: '9', name: 'Lets me bring someone with me to appointments', selected: false, },
    { id: '10', name: 'Follows up with me', selected: false, },
  ])

  const handleLists = (e) => {
    if (e.target.getAttribute('aria-pressed') === "true") e.target.setAttribute("aria-pressed", "false")
    else e.target.setAttribute("aria-pressed", "true")
    setLists(
      lists.map((list) =>
        list.id === e.target.getAttribute('data-id')
          ? { ...list, selected: !list.selected }
          : list
      )
    )
  }

  const checkCondition = () => {
    const selectedCount = lists.filter((list) => list.selected).length
    const listItemOne = document.querySelector('.full-check')
    const listItemTwo = document.querySelector('.any-check')
    const listItemThree = document.querySelector('.zero-check')

    if (selectedCount === 10) {
      listItemOne.style.display = 'block'
      listItemTwo.style.display = 'none'
      listItemThree.style.display = 'none'
    } else if (selectedCount === 0) {
      listItemOne.style.display = 'none'
      listItemTwo.style.display = 'none'
      listItemThree.style.display = 'block'
    } else {
      listItemOne.style.display = 'none'
      listItemTwo.style.display = 'block'
      listItemThree.style.display = 'none'
    }
  }

  return (
    <div className="frp-healthcare-section">
      <div className="frp-healthcare-head-section">
        <h2>Think About Your <br/><span className="wsnw">health care</span> Provider </h2>
        <p>
          Take a moment to think about the health care provider you see now. Based on your experience with them, are they right for you? Select what’s true about your provider based on your experiences.  
        </p>
      </div>
      <div className="frp-healthcare-right-for-you">
        <p className="strong">My health care provider:</p>
        <div className="frp-healthcare-right-for-you-section">
          <div className="frp-healthcare-right-for-you-selection">
            {lists.map((list) => (
              <button
                aria-pressed='false'
                onClick={handleLists}
                data-id={list.id}
                key={list.id}
                className={`frp-healthcare-right-for-you-div${
                  list.selected ? ' selected' : ''
                }`}
                tabIndex={0}
              >
                {list.name}
              </button>
            ))}
            <button
              onClick={checkCondition}
              className="frp-healthcare-right-for-you-section-btn"
            >
              I'm Done!
            </button>
          </div>
          <div className="frp-healthcare-right-for-you-img-section">
            <div className="img-wrapper">
              <img
                role="presentation" 
                className="frp-healthcare-right-for-you-img"
                src={image1}
                alt="black woman carrying a yoga mat"
              />
            </div>
          </div>
          <div className="frp-healthcare-right-for-you-response-section">
            <div className="full-check">
            You selected all the boxes! It sounds like you’ve found a great provider. But if you don’t feel connected to your provider, talk to them about your concerns. Read on to learn more about finding the right provider.
            </div>
            <div className="any-check">
            Take a look at the boxes you didn’t select. How important are those areas to you? 
            Is your provider still meeting your needs? If not, talk to them about your concerns. 
            Keep reading to learn more about finding the right provider. You can also check out{' '} 
            <ExternalLink url="https://blackdoctor.org/find-a-doctor/">
            BlackDoctor.org
            </ExternalLink> 
            {' '}for more resources.
            </div>
            <div className="zero-check">Please make a selection.</div>
          </div>
        </div>
      </div>
    </div>
  )
}
