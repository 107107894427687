import RightProvider from "./components/RightProvider/RightProvider";
import ListImportant from "./components/ListImportant/ListImportant";
import PreparingMeet from "./components/PreparingMeet/PreparingMeet";
import BannerImg from "../../assets/images/findingProvider-img03.jpeg";
import QuoteImg from "../../assets/images/findingProviderQuote.jpg";
import FooterImage from "../../assets/images/findingProvider-img02.jpeg";
import SupportTopics from "../SupportTopics/SupportTopics";
import FindSisterhood from "../FindSisterhood/FindSisterhood";
import ConnectToSpecialist from "../ConnectToSpecialist/ConnectToSpecialist";
import { VideoLink } from "../VideoLink/VideoLink";
import "./FindingProvider.scss";

function FindingProvider() {
  return (
    <>
      <div className="fp-gradient-container pb-180">
        <div className="fp-banner-container main-banner">
          <VideoLink url="https://www.youtube.com/watch?v=DXiIZgTTakA" />
          <div className="banner-text">
            <h1 className="display-h1">
              <span className="color">Finding</span> the Right{" "}
              <div className="wsnw">Health care</div> Provider
            </h1>
            <p>
              Finding the right health care provider — one that makes you feel
              safe and supported as a Black woman — is important to managing
              your diabetes. On this page, we’ll give you tools that can help
              you connect with the right provider.
            </p>
            <p>This page will help you:</p>
            <ul className="fp-steps">
              <li>
                Think about your current health care providers and what’s
                important to you.{" "}
              </li>
              <li>
                Take action to find health care providers who are right for you.{" "}
              </li>
            </ul>
            <p> Read on to learn more.</p>
          </div>
          <div>
            <div className="fp-banner-image-wrapper">
              <img
                role="presentation"
                className="fp-banner-img"
                src={BannerImg}
                alt="black woman smiling while working at a laptop "
              />
            </div>
          </div>
        </div>
        <div className="fp-testimonial-section">
          <div className="blockquote-wrapper">
            <blockquote className="callout quote EN">
              <img
                role="presentation"
                className="fp-testimonial-img"
                src={QuoteImg}
                alt="testimonial"
              />
              <p>
                You don’t need permission to ask questions about your health.
                It’s your right to know.
              </p>
            </blockquote>
          </div>
        </div>
        <ListImportant />
      </div>
      <div className="fp-gradient-container">
        <RightProvider />
        <PreparingMeet />
      </div>
      <div className="fp-banner-bottom">
        <div className="fp-banner-container-bottom">
          <div>
            <p>
              You deserve quality health care from capable providers that care
              about you. Use the tips on this page to find the BEST providers
              for your health.
            </p>
          </div>
          <div className="fp-banner-image-wrapper">
            <img
              role="presentation"
              className="fp-banner-img fp-banner-img-bottom"
              src={FooterImage}
              alt="black woman smiling at camera"
            />
          </div>
        </div>
      </div>
      <div class="hideExternal">
        <FindSisterhood topic="finding" />
      </div>
      <div id="SupportTopics" className="SupportTopics">
        <SupportTopics />
      </div>
      <ConnectToSpecialist />
    </>
  );
}

export default FindingProvider;
