import headerUnderline from "../../../../assets/images/modal-header-underline.png";
import mindTherapyImg from "../../../../assets/images/copingDiabetes/mind-therapy.png";
import { ExternalLink } from "../../../ExternalLink/ExternalLink";
import styles from "./SelfCareModals.scss";

export const SelfCareModalContent07 = () => {
  return (
    <>
      <div id="modalTitle-1" className="modal-title">
        <h2 className="display-h2">Mind - Therapy</h2>
        <img
          className="headerUnderline"
          src={headerUnderline}
          alt="decorative underline"
        />
      </div>
      <div id="modalContent-1" className="modal-content">
        <img
          className="headImg"
          src={mindTherapyImg}
          alt="woman sitting on couch talking to a therapist"
        />
        <p>
          People with diabetes are two to three times more likely to have
          depression, and only 25%–50% of people with diabetes and depression
          get diagnosed and treated. But treatment — therapy, medicine, or both
          — is usually very effective. Being under the care of a mental health
          professional can help you process some of the emotional frustrations
          people with diabetes can experience.{" "}
        </p>
        <ul>
          <li>
            If you’re ever feeling overwhelmed by feelings of stress, anxiety,
            depression, or burnout related to diabetes, talk to a mental health
            counselor. You can use the directory sources above or get a referral
            from your primary care provider.
          </li>

          <li>
            <ExternalLink
              url="https://resourceguide.borislhensonfoundation.org/"
              cname="org-button"
              aLabel="The Boris Lawrence Henson Foundation"
            >
              The Boris Lawrence Henson Foundation
            </ExternalLink>{" "}
            and the American Diabetes Association both host directories of
            mental health providers by location.
          </li>

          <li>
            <ExternalLink
              url="https://professional.diabetes.org/mhp_listing"
              cname="org-button"
              aLabel="The Loveland Foundation"
            >
              The Loveland Foundation
            </ExternalLink>{" "}
            offers financial assistance to Black women seeking therapy.{" "}
          </li>

          <li>
            If you ever experience stress that feels beyond what can be
            addressed with a mental health counselor, need more emotional
            support, or think about suicide, call or text 988 to reach the{" "}
            <ExternalLink
              url="https://988lifeline.org/"
              cname="org-button"
              aLabel="The Loveland Foundation"
            >
              Suicide & Crisis Lifeline
            </ExternalLink>
            . It’s available free, 24/7, across the United States.{" "}
          </li>
        </ul>
        <p>
          For more insight about what to look for in a mental health provider,
          review some of the tools in the module on{" "}
          <a target="_blank" rel="noopener noreferrer" href="/finding-provider">
            Finding the Right Health Care Provider
          </a>
          .
        </p>
      </div>
    </>
  );
};
