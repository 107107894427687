// import portionSizeImg from '../../../assets/images/SelfCare/Diabetes-Manage-Eat-Well-Plate-Graphic_600px 1.png'
import headerUnderline from "../../../../assets/images/modal-header-underline.png";
import spiritImg from "../../../../assets/images/copingDiabetes/spirit-self-care-head.png";
import styles from "./SelfCareModals.scss";

export const SelfCareModalContent14 = () => {
  return (
    <>
      <div id="modalTitle-1" className="modal-title">
        <h2 className="display-h2">General Self-Care Reminders</h2>
        <img
          className="headerUnderline"
          src={headerUnderline}
          alt="decorative underline"
        />
      </div>
      <div id="modalContent-1" className="modal-content">
        <img
          className="headImg"
          src={spiritImg}
          alt="woman outside at sunset"
        />
        <p>
          When managing diabetes while also dealing with stress, you may be
          tempted to make poor choices. Be gentle with yourself and know that
          dealing with your emotions is a process. Taking care of YOU — getting
          consistent sleep, taking moments to pause, moving more, and eating
          healthy — is a key form of self-care that can help you take charge of
          the stress in your life.{" "}
        </p>

        <p>
          Recognize that even though you may be taking your medications, eating
          a balanced diet, exercising, and doing everything within your power to
          manage your health, diabetes is an unpredictable condition. Although
          you can’t plan for all the stressful moments, you do have the power to
          respond in ways that help you feel at peace.{" "}
        </p>

        <ul>
          <li>
            {" "}
            Some of those ways might mean saying “No” to other people’s needs
            and requests as a form of self-preservation.{" "}
          </li>

          <li>
            Leaning on experts and loved ones can also be a way to deal with
            stressful moments.{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.cdc.gov/diabetes/dsmes/dsmes-living-with-diabetes.html"
            >
              Talking to a diabetes care and education specialist
            </a>{" "}
            about creating a care plan can alleviate the burden of handling
            diabetes alone. For more ideas on establishing social connections
            with family, friends, and diabetes support groups, take a look at
            the{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="/creating-community"
            >
              module on Creating Community Support
            </a>
            .{" "}
          </li>
        </ul>
      </div>
    </>
  );
};
