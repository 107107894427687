import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import iconPhone from '../../../../assets/images/frp-icon-phone.svg'
import iconWebsite from '../../../../assets/images/frp-icon-website.svg'
import iconDoctors from '../../../../assets/images/frp-icon-doctors.svg'
import InsuranceImg from '../../../../assets/images/findingrightprovider1.jpg'
import RecommendationsImg from '../../../../assets/images/findingrightprovider2.jpg'
import ContactsImg from '../../../../assets/images/findingrightprovider3.jpg'
import { ExternalLink } from '../../../ExternalLink/ExternalLink'
import './RightProvider.css'

export const RightProvider = () => {
  return (
    <>
      <div className="finding-right-provider-section">
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <h3 className="accordion-header">If you have insurance, check your coverage.</h3>
          </AccordionSummary>
          <AccordionDetails>
            <div className="finding-right-provider-insurance-section">
              <div className="img-block">
                <div className="frp-img-wrapper">
                  <img
                    role="presentation" 
                    src={InsuranceImg}
                    className="finding-right-provider-insurance-img"
                    alt="Finding right provider check your insurance coverage"
                  />
                </div>
              </div>
              <div className="text-block">
                <p>
                  If you are looking for a new provider, and have insurance coverage, check for tools offered by your insurance carrier to help search for a new provider.
                </p>
                <p>
                  If you don’t have insurance, there are options for you. Visit the <ExternalLink url="https://www.diabetes.org/tools-support/health-insurance">Health Insurance Aid</ExternalLink> web page to learn more.  You can also visit the <ExternalLink url="https://www.diabeteseducator.org/living-with-diabetes/Tools-and-Resources/affordability-resources">Access and Affordability Resources</ExternalLink> web page to learn about other resources available. 
                </p>
              </div>
            </div>
            <p>
              If you do have health insurance, check out the steps below to get started finding the right provider.  
            </p>
            <div className="finding-right-provider-insurance-points">
              <ul>
                <li>
                  <img role="presentation" src={iconPhone} alt="Phone icon" />
                  <p>If you have a list of health care providers you are interested in, call their offices to see if they take your insurance and are in-network.</p>
                </li>
                <li>
                  <img role="presentation" src={iconWebsite} alt="Website icon" />
                  <p>Visit your insurance provider’s website and search for in-network health care providers in your area.</p>
                </li>
                <li>
                  <img role="presentation" src={iconDoctors} alt="Doctors icon" />
                  <p>Call the number on the back of your insurance card to ask for a list of in-network health care providers near you.</p>
                </li>
              </ul>
            </div>
          </AccordionDetails>
        </Accordion>
        <hr className="finding-right-provider-hr"/>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <h3 className="accordion-header">Ask for Recommendations:</h3>
          </AccordionSummary>
          <AccordionDetails>
            <div className="finding-right-provider-recommendations-section">
              <div className="img-block">
                <div className="frp-img-wrapper">
                  <img
                    role="presentation" 
                    src={RecommendationsImg}
                    className="finding-right-provider-recommendations-img"
                    alt="Finding right provider ask for recommendations"
                  />
                </div>
              </div>
              <div className="text-block">
                <p>
                  You may also want to ask friends or family for a recommendation. Ask them questions about their health care providers and if they’d be a good fit for you.
                </p>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
        <hr className="finding-right-provider-hr"/>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3bh-content"
            id="panel3bh-header"
          >
            <h3 className="accordion-header">You can ask questions like:</h3>
          </AccordionSummary>
          <AccordionDetails>
            <div className="finding-right-provider-recommendations-question-section">
              <div className='text-block'>
                <ul>
                  <li><p>Do you feel like you receive quality care from your health care provider?</p></li>
                  <li><p>Do you believe they understand the unique needs of Black women?</p></li>
                  <li><p>Do you trust them? </p></li>
                  <li><p>Do you feel like they listen to you? </p></li>
                  <li><p>Do you feel they are easy to reach</p></li>
                  <li><p>Do you feel rushed during your appointment?</p></li>
                </ul>
                <p>
                  The answers to these questions can help you decide if their provider would be a good fit for you.  
                </p>
              </div>
              <div className="img-block">
                <div className="frp-img-wrapper">
                  <img
                    role="presentation" 
                    src={ContactsImg}
                    className="finding-right-provider-recommendations-question-img"
                    alt="Finding right provider ask your contacts"
                  />
                </div>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
        <hr className="finding-right-provider-hr"/>
      </div>
    </>
  )
}

export default RightProvider