import { useState } from 'react'
import Q1 from './components/Q1'
import Q2 from './components/Q2'
import Q3 from './components/Q3'
import Q4 from './components/Q4'
import Q5 from './components/Q5'
import QuizResult from './components/QuizResult'

import './Quiz.scss'

function Quiz() {
  let [page, setPage] = useState(0);
  let [error, setError] = useState(false);
  const [quizAnswers, setQuizAnswers] = useState({
    q1: '',
    q2: '',
    q3: '',
    q4: '',
    q5: '',
  });
  const handleNextClick = () => {
    setPage(++page)
  }
  const handlePreviousClick = () => {
    setPage(--page)
  }

  let [lowAnswers, setLowAnswers] = useState('');
  const handleWorriedClick = (text) => {
    (lowAnswers === '') ? setLowAnswers(text) : setLowAnswers(`${lowAnswers}, ${text}`)
  }
  let [mediumAnswers, setMediumAnswers] = useState('');
  const handleNeutralClick = (text) => {
    (mediumAnswers === '') ? setMediumAnswers(text) : setMediumAnswers(`${mediumAnswers}, ${text}`)
  }

  return (
    <>
      <div className="quiz-banner">
        <div className="quiz-banner-container">
          {page === 0 && (
            <div className='quiz-content-container'>
              <h1 className='display-h2'>Answer a few questions to build a learning guide made just for you!</h1>
              <button className='start-button' value={'Start'} onClick={handleNextClick}>
                Start 
              </button>
            </div>
          )}
          {page === 1 && (
            <Q1 
              handleNextClick={handleNextClick}
              handleWorriedClick={handleWorriedClick}
              handleNeutralClick={handleNeutralClick} 
              error={error}
              setError={setError}
              answer={quizAnswers.q1}
              setQuizAnswers={setQuizAnswers}
            />
          )}
          {page === 2 && (
            <Q2 
              handleNextClick={handleNextClick}
              handlePreviousClick={handlePreviousClick}
              handleWorriedClick={handleWorriedClick}
              handleNeutralClick={handleNeutralClick}
              error={error} 
              setError={setError}
              answer={quizAnswers.q2}
              setQuizAnswers={setQuizAnswers}
            />
          )}
          {page === 3 && (
            <Q3 
              handleNextClick={handleNextClick}
              handlePreviousClick={handlePreviousClick}
              handleWorriedClick={handleWorriedClick}
              handleNeutralClick={handleNeutralClick}
              error={error} 
              setError={setError}
              answer={quizAnswers.q3}
              setQuizAnswers={setQuizAnswers}
            />
          )}
          {page === 4 && (
            <Q4 
              handleNextClick={handleNextClick}
              handlePreviousClick={handlePreviousClick}
              handleWorriedClick={handleWorriedClick}
              handleNeutralClick={handleNeutralClick}
              error={error} 
              setError={setError}
              answer={quizAnswers.q4}
              setQuizAnswers={setQuizAnswers}
            />
          )}
          {page === 5 && (
            <Q5 
              handleNextClick={handleNextClick}
              handlePreviousClick={handlePreviousClick}
              handleWorriedClick={handleWorriedClick}
              handleNeutralClick={handleNeutralClick}
              error={error} 
              setError={setError}
              answer={quizAnswers.q5}
              setQuizAnswers={setQuizAnswers}
            />
          )}
          {page === 6 && (
            <QuizResult lowAnswers={lowAnswers} mediumAnswers={mediumAnswers}/>
          )}
        </div>
    </div>
  </>
  )
}

export default Quiz
