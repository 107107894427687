import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

export const PhysicalActivityModalContent05 = () => {
  return (
    <>
      <p>Sometimes you may have a lot going on and may be too busy or struggle to find time to be active. Below is a list of ways to add activity more easily into your daily routine. Click each one to learn more. Take a screenshot of this information on your phone so you can access it later. </p>
      <div className="ideas-list">
        <Accordion className="pa-modal-accordion">
          <AccordionSummary
            expandIcon={<ArrowRightIcon/>}
            id="modal05panel01-header"
            aria-controls="modal05panel01-content"
            className="pa-modal-accordion-summary"
          >
            <h5 className="strong">Be realistic</h5>
          </AccordionSummary>
          <AccordionDetails className="pa-modal-accordion-details">
            <p tabIndex={0} >Pick a goal that you can reach within a certain timeframe, so you stay motivated. For example, your goal could be to walk 10-minutes a day.</p>
          </AccordionDetails>
        </Accordion>
        <Accordion className="pa-modal-accordion">
          <AccordionSummary
            expandIcon={<ArrowRightIcon/>}
            id="modal05panel02-header"
            aria-controls="modal05panel02-content"
            className="pa-modal-accordion-summary"
          >
            <h5 className="strong">Start small</h5>
          </AccordionSummary>
          <AccordionDetails className="pa-modal-accordion-details">
            <p tabIndex={0} >Take it slowly. Every step is one more than you did before — and a step in the right direction. For example, you could start with setting an alert to stand up and shake out your legs and move your arms around every hour on the hour.</p>
          </AccordionDetails>
        </Accordion>
        <Accordion className="pa-modal-accordion">
          <AccordionSummary
            expandIcon={<ArrowRightIcon/>}
            id="modal05panel04-header"
            aria-controls="modal05panel04-content"
            className="pa-modal-accordion-summary"
          >
            <h5 className="strong">Find something You like</h5>
          </AccordionSummary>
          <AccordionDetails className="pa-modal-accordion-details">
            <p tabIndex={0} >There are different ways that you can get active. Find the ones that you enjoy so that you’re more likely to stick to it. For example, do you like to dance?  Pick three of your favorite songs and get down! That’s about 10 minutes of healthy physical activity!</p>
          </AccordionDetails>
        </Accordion>
        <Accordion className="pa-modal-accordion">
          <AccordionSummary
            expandIcon={<ArrowRightIcon/>}
            id="modal05panel03-header"
            aria-controls="modal05panel03-content"
            className="pa-modal-accordion-summary"
          >
            <h5 className="strong">Partner up</h5>
          </AccordionSummary>
          <AccordionDetails className="pa-modal-accordion-details">
            <p tabIndex={0} >Working out with a friend makes your workout more fun. Time truly does fly when you’re having fun. For example, a walk while catching up with a friend, either in-person or while on the phone can satisfy the body and soul.</p>
          </AccordionDetails>
        </Accordion>

        <Accordion className="pa-modal-accordion">
          <AccordionSummary
            expandIcon={<ArrowRightIcon/>}
            id="modal05panel05-header"
            aria-controls="modal05panel05-content"
            className="pa-modal-accordion-summary"
          >
            <h5 className="strong">Create a habit</h5>
          </AccordionSummary>
          <AccordionDetails className="pa-modal-accordion-details">
            <p tabIndex={0} >The best way to make something a habit is to find simple ways to add it to your daily routine. For example, you could make five minutes of stretching before washing your face a part of your morning ritual.</p>
          </AccordionDetails>
        </Accordion>
      </div>
      <p><strong>Pause, Reflect, Act </strong></p>
      <p>Write down five physical activity goals that you would like to reach in the next three months (e.g., I want to have more stamina during my morning walk or be able to walk a 5K).  Writing down your goals will narrow your focus and provide motivation.</p>
    </>
  )
}