import { useNavigate } from "react-router-dom";
import RoutePaths from "../../routes";
import ReactPlayer from "react-player";
import SupportTopics from "../SupportTopics/SupportTopics";
import FindSisterhood from "../FindSisterhood/FindSisterhood";
import ConnectToSpecialist from "../ConnectToSpecialist/ConnectToSpecialist";
import Quotes from "./components/Quotes/Quotes";
import image5 from "../../assets/images/welcome-to-your-diabetes-compass.png";
import "./Home.scss";

export default function Home() {
  let navigateToTakeTheQuiz = useNavigate();
  const handleRouteToTakeTheQuiz = () => navigateToTakeTheQuiz(RoutePaths.Quiz);
  return (
    <div>
      <div className="h-container btmbord">
        <div className="gradient-bg-container">
          <div className="home-banner-container">
            <img
              src={image5}
              alt="Welcome to your diabetes compass header image"
            />
            {/* <h1 className="display-h1">
              <span class="dark-text">Welcome to</span> YOUR DIABETES COMPASS!
            </h1> */}

            <p>
              The Centers for Disease Control and Prevention designed this
              Compass tool specifically with Black women in mind since they have
              some of the highest rates of type 2 diabetes and tend to have low
              participation in personalized support services - diabetes
              self-management education and support (DSMES) services. On this
              website, you will find information, resources, and support created
              to assist Black women living with type 2 diabetes.{" "}
            </p>
          </div>
          {/*<div className="section-video">
             <img src={image6}/> 
            
           <YouTube videoId="gAC_oXVCgwY"/>
            <VideoPlayer />*/}
          <div
            className="section-video video-wrapper"
            tabIndex={0}
            aria-label="Meet your Diabetes Compass Guide"
            aria-roledescription="video player"
          >
            <ReactPlayer
              width="100%"
              height="100%"
              controls
              url="https://www.youtube.com/watch?v=i4PhbOixOM8"
            />
            <p className="capsule">Meet your guide, Deborah!</p>
          </div>
        </div>
      </div>

      <div className="h-container">
        <div className="gradient-bg-container">
          <div className="home-banner-container">
            <div className="quote-container">
              <Quotes />
            </div>
          </div>
        </div>
      </div>

      <div className="h-container create-guide-section">
        <div className="gradient-bg-container">
          <div className="home-banner-container">
            <div className="create-guide-card">
              <div className="inside-border">
                <h2 className="display-h2">
                  <span className="dark-text">Create</span> Your Diabetes
                  Compass!
                </h2>
                <p className="subtitle-guide">
                  There’s a lot of information online, and it can be
                  overwhelming.{" "}
                  <strong>
                    Are you looking for support or community? Are you looking
                    for tips on how to manage diabetes?
                  </strong>{" "}
                  Create your own guide to get recommended resources based on
                  your answers to a quick self-assessment or pick from the
                  topics below to get started.
                </p>
                <button
                  onClick={handleRouteToTakeTheQuiz}
                  className="btn-learning-guide"
                >
                  Build Your Learning Guide
                </button>
              </div>
            </div>
          </div>
        </div>
        <div id="supportTopics" className="SupportTopics">
          <SupportTopics />
        </div>
        <div class="hideExternal">
          <FindSisterhood topic="home" />
        </div>
        <ConnectToSpecialist />
      </div>
    </div>
  );
}
