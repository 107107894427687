import { useId } from 'react'
import bodyRelaxHeadImg from '../../../../assets/images/copingDiabetes/body-relax-head-img.png'
import { ExternalLink } from '../../../ExternalLink/ExternalLink'
import iconLungs from '../../../../assets/images/copingDiabetes/icon-lungs.svg'
import iconCal from '../../../../assets/images/copingDiabetes/icon-calendar.svg'
import iconRun from '../../../../assets/images/copingDiabetes/icon-person.svg'
import iconNose from '../../../../assets/images/copingDiabetes/icon-nose.svg'
import headerUnderline from '../../../../assets/images/modal-header-underline.png'
import styles from './SelfCareModals.scss'

export const SelfCareModalContent01 = () => {
  const id = useId();
  return (
    <>
      
      <div id="modalTitle-1" className="modal-title">
            <h2 className="display-h2">BODY-Relax</h2>
            <img className="headerUnderline" src={headerUnderline} alt="decorative underline"/>
          </div>
          <div id="modalContent-1" className="modal-content">
            <img className="headImg" src={bodyRelaxHeadImg} alt="woman applying skin care to her face"/>
                <div className="modalFlex">                      
                    <div className="flexCard">
                        <img className="icon" src={iconLungs} alt="icon of lungs"/>
                        <p><ExternalLink url="https://www.nccih.nih.gov/health/stress" cname="org-button" aLabel="Stress">Stress</ExternalLink> can cause physical  reactions and tension in the  body, but relaxation techniques  can counteract the negative effects of stress. Try to ease stress by finding time to  concentrate on your breathing.</p>
                    </div>
                    
                    <div className="flexCard">
                        <img className="icon" src={iconCal} alt="icon of calendar"/>
                        <p>Regularly dedicate a day or moments throughout the week for me-time. During this time, try to free yourself from work and obligations and spend time with yourself doing whatever you want to do such as reading, listening to music, writing, painting, or watching a movie. </p>
                    </div>
                </div>
                <div className="modalFlex">
                    <div className="flexCard">
                        <img className="icon" src={iconRun} alt="icon of stick figure running"/>
                        <p>Commit to a day of pampering by booking a spa date or doing an at-home facial with some soothing aromatherapy and light music. </p>
                    </div>
                    
                    <div className="flexCard">
                        <img className="icon" src={iconNose} alt="icon of a nose"/>
                        <p>Deep breathing exercises can reduce hypoglycemia in people with type 2 diabetes, which may make it a useful self-care tool.</p>
                    </div>
                </div>
          </div>
    </>
  )
}