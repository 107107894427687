import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import RoutePaths from "../../routes";
import logo from "../../assets/images/header.svg";
import moblogo from "../../assets/images/mobile-logo.svg";
import dbcLogo from "../../assets/images/diabetes_compass_logo.png";
import NavMenu from "./components/NavMenu";
import FloatingLearningGuide from "./components/FloatingLearningGuide";

import "./Navbar.scss";

function Navbar() {
  const location = useLocation();

  useEffect(() => {
    let stateCheck = setInterval(() => {
      if (document.readyState === "complete") {
        clearInterval(stateCheck);
        // document ready
        if (location.pathname.includes("/monitoring-diabetes")) {
          if (document.getElementById("tab-title") != null)
            document.getElementById("tab-title").innerHTML =
              "Monitoring Diabetes | CDC";
          document.body.dataset["theme"] = "blue";
        }
        if (location.pathname.includes("/reducing-risk")) {
          document.body.dataset["theme"] = "azure";
          if (document.getElementById("tab-title") != null)
            document.getElementById("tab-title").innerHTML =
              "Reducing Risk of Complications | CDC";
        }
        if (location.pathname.includes("/healthy-eating-cooking")) {
          if (document.getElementById("tab-title") != null)
            document.getElementById("tab-title").innerHTML =
              "Creative Eating and Cooking | CDC";
          document.body.dataset["theme"] = "yellow";
        }
        if (location.pathname.includes("/increasing-physical-activity")) {
          if (document.getElementById("tab-title") != null)
            document.getElementById("tab-title").innerHTML =
              "Increasing Physical Activity | CDC";
          document.body.dataset["theme"] = "cyan";
        }
        if (location.pathname.includes("/quality-care")) {
          if (document.getElementById("tab-title") != null)
            document.getElementById("tab-title").innerHTML =
              "Getting Quality Care | CDC";
          document.body.dataset["theme"] = "amber";
        }
        if (location.pathname.includes("/finding-provider")) {
          if (document.getElementById("tab-title") != null)
            document.getElementById("tab-title").innerHTML =
              "Finding the Right Provider | CDC";
          document.body.dataset["theme"] = "amber";
        }
        if (location.pathname.includes("/creating-community")) {
          if (document.getElementById("tab-title") != null)
            document.getElementById("tab-title").innerHTML =
              "Creating Community Support | CDC";
          else console.log("uh oh");
          document.body.dataset["theme"] = "blue";
        }
        if (location.pathname.includes("/living-diabetes")) {
          if (document.getElementById("tab-title") != null)
            document.getElementById("tab-title").innerHTML =
              "Living with Diabetes | CDC";
          document.body.dataset["theme"] = "pink";
        }
        if (location.pathname.includes("/connecting-to-DSMES")) {
          if (document.getElementById("tab-title") != null)
            document.getElementById("tab-title").innerHTML =
              "Connecting to DSMES | CDC";
          document.body.dataset["theme"] = "cyan";
        }
        if (location.pathname.includes("/learning-about-DSMES")) {
          document.body.dataset["theme"] = "amber";
          if (document.getElementById("tab-title") != null)
            document.getElementById("tab-title").innerHTML =
              "Learning About DSMES | CDC";
        }
        if (location.pathname.includes("/home")) {
          document.body.dataset["theme"] = "amber";
          if (document.getElementById("tab-title") != null)
            document.getElementById("tab-title").innerHTML = "Home | CDC";
        }
        if (location.pathname.includes("/learning-guide")) {
          if (document.getElementById("tab-title") != null)
            document.getElementById("tab-title").innerHTML =
              "Learning Guide | CDC";
          document.body.dataset["theme"] = "amber";
        }
        if (
          location.pathname === "/" ||
          location.pathname === "/ddt/dsmes-onramp/"
        )
          document.body.dataset["theme"] = "amber";
      }
    }, 100);
  }, [location.pathname]);

  return (
    <>
      <nav>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="#main-content"
          tabindex="1"
          class="visually-hidden focusable skip-link"
          role="navigation"
        >
          Skip to main content
        </a>
        <div
          className="main-header-logo"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignContent: "center",
            padding: "13px",
          }}
        >
          <img
            className="logoimage"
            src={logo}
            alt="Centers for Disease Control and Prevention. CDC twenty four seven. Saving Lives, Protecting People"
          />
          <img
            className="mobilelogoimage"
            src={moblogo}
            alt="Centers for Disease Control and Prevention. CDC twenty four seven. Saving Lives, Protecting People"
          />
        </div>
        <div className="row nav-row">
          <div className="container main-nav" style={{ padding: "3px 0" }}>
            <a href={RoutePaths.Home} tabIndex="2">
              <img
                className="logoimage"
                style={{
                  height: 60,
                  width: "auto",
                  marginBottom: "0px",
                  position: "relative",
                  float: "right",
                }}
                src={dbcLogo}
                alt="Diabetes Compass logo"
              />
            </a>
            <NavMenu />
          </div>
        </div>
        <FloatingLearningGuide />
      </nav>
    </>
  );
}

export default Navbar;
