import { useState, useRef } from "react";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import RoutePaths from "../../../../routes";
import popup1 from "../../../../assets/images/popup1.jpg";
import popup2 from "../../../../assets/images/popup2.jpg";
import popup3 from "../../../../assets/images/popup3.jpg";
import popup4 from "../../../../assets/images/popup4.jpg";
import popup5 from "../../../../assets/images/popup5.jpg";
import popup6 from "../../../../assets/images/popup6.jpg";
import popup7 from "../../../../assets/images/popup7.jpg";
import popup8 from "../../../../assets/images/popup8.jpg";
import { ExternalLink } from "../../../ExternalLink/ExternalLink";
import "./PopUp.css";

export const PopUp = () => {
  const [imageModels, setImageModels] = useState({
    imageModelOne: false,
    imageModelTwo: false,
    imageModelThree: false,
    imageModelFour: false,
    imageModelFive: false,
    imageModelSix: false,
    imageModelSeven: false,
    imageModelEight: false,
  });

  const getImageModelHandler = (imageModelName) => {
    return {
      isOpen: imageModels[imageModelName],
      open: () =>
        setImageModels((state) => ({ ...state, [imageModelName]: true })),
      close: () =>
        setImageModels((state) => ({ ...state, [imageModelName]: false })),
    };
  };

  const imageModelOne = getImageModelHandler("imageModelOne");
  const imageModelTwo = getImageModelHandler("imageModelTwo");
  const imageModelThree = getImageModelHandler("imageModelThree");
  const imageModelFour = getImageModelHandler("imageModelFour");
  const imageModelFive = getImageModelHandler("imageModelFive");
  const imageModelSix = getImageModelHandler("imageModelSix");
  const imageModelSeven = getImageModelHandler("imageModelSeven");
  const imageModelEight = getImageModelHandler("imageModelEight");

  const [lists, setLists] = useState([
    { id: "1", name: "Vision", selected: false },
    { id: "2", name: "Hearing", selected: false },
    { id: "3", name: "Kidney function", selected: false },
    { id: "4", name: "Dental", selected: false },
    { id: "5", name: "Foot health", selected: false },
  ]);

  const handleLists = (e) => {
    if (e.target.getAttribute("aria-pressed") === "true")
      e.target.setAttribute("aria-pressed", "false");
    else e.target.setAttribute("aria-pressed", "true");
    setLists(
      lists.map((list) =>
        list.id === e.target.getAttribute("data-id")
          ? { ...list, selected: !list.selected }
          : list
      )
    );
  };

  const ref = useRef(null);

  const verticalScroll = () => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleOnClickHelper = (e) => {
    checkCondition();
    verticalScroll();
  };

  const checkCondition = () => {
    const selectedCount = lists.filter((list) => list.selected).length;
    const listItemOne = document.querySelector(".full-selection");
    const listItemTwo = document.querySelector(".any-selection");
    const listItemThree = document.querySelector(".no-selection");

    if (selectedCount === 5) {
      listItemOne.style.display = "block";
      listItemTwo.style.display = "none";
      listItemThree.style.display = "none";
    } else if (selectedCount === 0) {
      listItemOne.style.display = "none";
      listItemTwo.style.display = "none";
      listItemThree.style.display = "block";
    } else {
      listItemOne.style.display = "none";
      listItemTwo.style.display = "block";
      listItemThree.style.display = "none";
    }
  };

  return (
    <section className="rr-suggestions-section">
      <div className="rr-suggestions-flex-wrapper">
        <div className="tip-button-wrapper">
          <button
            style={{ backgroundImage: `url(${popup1})` }}
            className="tip"
            onClick={imageModelOne.open}
          >
            <h2>I Will Put My Health and Well-Being First</h2>
          </button>
        </div>
        <div className="tip-button-wrapper">
          <button
            style={{ backgroundImage: `url(${popup2})` }}
            className="tip"
            onClick={imageModelTwo.open}
          >
            <h2>I Will Get Regular Health Checkups</h2>
          </button>
        </div>
        <div className="tip-button-wrapper">
          <button
            style={{ backgroundImage: `url(${popup3})` }}
            className="tip"
            onClick={imageModelThree.open}
          >
            <h2>I Will Take Care of My Feet</h2>
          </button>
        </div>
        <div className="tip-button-wrapper">
          <button
            style={{ backgroundImage: `url(${popup4})` }}
            className="tip"
            onClick={imageModelFour.open}
          >
            <h2>I Will Take Care of My Eyes</h2>
          </button>
        </div>
        <div className="tip-button-wrapper">
          <button
            style={{ backgroundImage: `url(${popup5})` }}
            className="tip"
            onClick={imageModelFive.open}
          >
            <h2>I Will Monitor My Blood Sugar</h2>
          </button>
        </div>
        <div className="tip-button-wrapper">
          <button
            style={{ backgroundImage: `url(${popup6})` }}
            className="tip"
            onClick={imageModelSix.open}
          >
            <h2>I Will Talk About My Feelings</h2>
          </button>
        </div>
        <div className="tip-button-wrapper">
          <button
            style={{ backgroundImage: `url(${popup7})` }}
            className="tip"
            onClick={imageModelSeven.open}
          >
            <h2>I Will Get My Vaccinations</h2>
          </button>
        </div>
        <div className="tip-button-wrapper">
          <button
            style={{ backgroundImage: `url(${popup8})` }}
            className="tip"
            onClick={imageModelEight.open}
          >
            <h2>I Will Practice Healthy Habits</h2>
          </button>
        </div>
      </div>
      <div className="dialog-wrapper">
        <Dialog
          role="dialog"
          open={imageModelOne.isOpen}
          onClose={imageModelOne.close}
          className="dsmes-modal modal-theme-azure"
          aria-label="I Will Put My Health and Well-Being First"
        >
          <button
            className="modal-close-btn"
            onClick={imageModelOne.close}
            aria-label="Close"
          >
            <CloseIcon className="modal-close-icon" />
          </button>
          <div
            className="modal-header mtr4"
            style={{ backgroundImage: `url(${popup1})` }}
          >
            &nbsp;
          </div>
          <div id="modalTitle-1" className="modal-title">
            <h3 tabIndex={0} className="strong">
              I Will Put My Health and Well-Being First
            </h3>
          </div>
          <div tabIndex={0} id="modalContent-1" className="modal-content">
            <p>
              The first step is up to you. Make a promise to yourself that you
              will put your health first!
            </p>
            <p>
              Sticking to this promise can be hard, especially when you are
              always taking care of others. But staying healthy is good for you
              and those around you. Find a pen and paper and write down: “
              <strong>I promise to put my health first</strong>.” Sign your name
              to this and write today’s date. Keep this paper somewhere you can
              see it every day.
            </p>
          </div>
          <div className="modal-footer">
            <button
              onClick={() => {
                imageModelOne.close();
                imageModelTwo.open();
              }}
              className="next"
              aria-label="Next"
            >
              <svg
                width="17"
                height="28"
                viewBox="0 0 17 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2 26L14 14L2 2"
                  stroke="white"
                  strokeWidth="3"
                  strokeLinecap="round"
                />
              </svg>
            </button>
          </div>
        </Dialog>
        <Dialog
          role="dialog"
          open={imageModelTwo.isOpen}
          onClose={imageModelTwo.close}
          className="dsmes-modal modal-theme-azure"
          aria-label="I Will Get Regular Health Checkups"
        >
          <button
            className="modal-close-btn"
            onClick={imageModelTwo.close}
            aria-label="Close"
          >
            <CloseIcon className="modal-close-icon" />
          </button>
          <div
            className="modal-header mtr2"
            style={{ backgroundImage: `url(${popup2})` }}
          >
            &nbsp;
          </div>
          <div id="modalTitle-2" className="modal-title">
            <h3 tabIndex={0} className="strong">
              I Will Get Regular Health Checkups
            </h3>
          </div>
          <div tabIndex={0} id="modalContent-2" className="modal-content">
            <p>
              Regular health checkups are a great way to lower your risk. If you
              can, plan to see your health-care provider every three months so
              they can check your A1C levels and your blood pressure and answer
              your questions.
            </p>
            <p>
              Here’s a list of checkups that you should have every year (or more
              often as recommended by your health-care provider) to stay
              healthy. Mark the checkups you already had this year, and mark the
              ones you have scheduled.
            </p>
            <div className="image-data-grid-modal-body-text-section">
              {lists.map((list) => (
                <button
                  aria-pressed="false"
                  onClick={handleLists}
                  data-id={list.id}
                  key={list.id}
                  className={`checkup-questions${
                    list.selected ? " selected" : ""
                  }`}
                  tabIndex={0}
                >
                  {list.name}
                </button>
              ))}
              <div className="image-data-grids-selection" ref={ref}>
                <button
                  onClick={handleOnClickHelper}
                  className="image-data-grids-selections-btn"
                >
                  I'm Done!
                </button>
                <div className="full-selection">
                  Great job! Keeping up with your checkups is helping to lower
                  your risk for other complications.
                </div>
                <div className="any-selection">
                  Look at the appointments you did not mark. Set a goal to call
                  to make an appointment for each of them this week — getting
                  these checkups can help reduce your risk of complications and
                  keep your health on track!
                </div>
                <div className="no-selection">Please make a selection.</div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              onClick={() => {
                imageModelTwo.close();
                imageModelOne.open();
              }}
              className="prev"
              aria-label="Previous"
            >
              <svg
                width="17"
                height="28"
                viewBox="0 0 17 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15 2L3 14L15 26"
                  stroke="white"
                  strokeWidth="3"
                  strokeLinecap="round"
                />
              </svg>
            </button>
            <button
              onClick={() => {
                imageModelTwo.close();
                imageModelThree.open();
              }}
              className="next"
              aria-label="Next"
            >
              <svg
                width="17"
                height="28"
                viewBox="0 0 17 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2 26L14 14L2 2"
                  stroke="white"
                  strokeWidth="3"
                  strokeLinecap="round"
                />
              </svg>
            </button>
          </div>
        </Dialog>
        <Dialog
          role="dialog"
          open={imageModelThree.isOpen}
          onClose={imageModelThree.close}
          className="dsmes-modal modal-theme-azure"
          aria-label="I Will Take Care of My Feet"
        >
          <button
            className="modal-close-btn"
            onClick={imageModelThree.close}
            aria-label="Close"
          >
            <CloseIcon className="modal-close-icon" />
          </button>
          <div
            className="modal-header"
            style={{ backgroundImage: `url(${popup3})` }}
          >
            &nbsp;
          </div>
          <div id="modalTitle-3" className="modal-title">
            <h3 tabIndex={0} className="strong">
              I Will Take Care of My Feet
            </h3>
          </div>
          <div tabIndex={0} id="modalContent-3" className="modal-content">
            <p>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.cdc.gov/diabetes/library/features/healthy-feet.html"
              >
                Diabetes can affect how much blood is flowing to your feet
              </a>
              . This can increase your risk of having a serious infection if you
              have a cut or sore on your foot. In addition, about half of all
              people with diabetes have some sort of nerve damage, often in
              their feet. This can cause you to have less feeling in your feet.
              Pain is the body’s way of telling you something is wrong so you
              can take care of yourself. If you don’t feel pain in your feet,
              you may not notice a cut, blister, sore, or other problem.
            </p>
            <p>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.cdc.gov/diabetes/library/socialmedia/infographics/feet-healthy.html"
                target="_blank"
              >
                Checking
              </a>{" "}
              your feet every day can help catch problems early before they
              become serious. Look for any cuts, redness, bruises, or sores that
              aren’t healing. If you see a problem, call your health care
              provider right away.
            </p>
          </div>
          <div className="modal-footer">
            <button
              onClick={() => {
                imageModelThree.close();
                imageModelTwo.open();
              }}
              className="prev"
              aria-label="Previous"
            >
              <svg
                width="17"
                height="28"
                viewBox="0 0 17 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15 2L3 14L15 26"
                  stroke="white"
                  strokeWidth="3"
                  strokeLinecap="round"
                />
              </svg>
            </button>
            <button
              onClick={() => {
                imageModelThree.close();
                imageModelFour.open();
              }}
              className="next"
              aria-label="Next"
            >
              <svg
                width="17"
                height="28"
                viewBox="0 0 17 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2 26L14 14L2 2"
                  stroke="white"
                  strokeWidth="3"
                  strokeLinecap="round"
                />
              </svg>
            </button>
          </div>
        </Dialog>
        <Dialog
          role="dialog"
          open={imageModelFour.isOpen}
          onClose={imageModelFour.close}
          className="dsmes-modal modal-theme-azure"
          aria-label="I Will Take Care of My Eyes"
        >
          <button
            className="modal-close-btn"
            onClick={imageModelFour.close}
            aria-label="Close"
          >
            <CloseIcon className="modal-close-icon" />
          </button>
          <div
            className="modal-header"
            style={{ backgroundImage: `url(${popup4})` }}
          >
            &nbsp;
          </div>
          <div id="modalTitle-4" className="modal-title">
            <h3 tabIndex={0} className="strong">
              I Will Take Care of My Eyes
            </h3>
          </div>
          <div tabIndex={0} id="modalContent-4" className="modal-content">
            <p>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.cdc.gov/diabetes/managing/diabetes-vision-loss.html"
                target="_blank"
              >
                Diabetes also affects your eyes
              </a>{" "}
              and is the leading cause of vision loss in people ages 18 to 64.
              Eye diseases that can affect people with diabetes include diabetic
              retinopathy, macular edema, cataracts, and glaucoma. Schedule a
              yearly visit with an eye doctor to help keep your eye health on
              track.
            </p>
            <p>
              Eye diseases may be hard for you to notice. Look out for symptoms
              like blurred vision or dark spots.
            </p>
          </div>
          <div className="modal-footer">
            <button
              onClick={() => {
                imageModelFour.close();
                imageModelThree.open();
              }}
              className="prev"
              aria-label="Previous"
            >
              <svg
                width="17"
                height="28"
                viewBox="0 0 17 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15 2L3 14L15 26"
                  stroke="white"
                  strokeWidth="3"
                  strokeLinecap="round"
                />
              </svg>
            </button>
            <button
              onClick={() => {
                imageModelFour.close();
                imageModelFive.open();
              }}
              className="next"
              aria-label="Next"
            >
              <svg
                width="17"
                height="28"
                viewBox="0 0 17 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2 26L14 14L2 2"
                  stroke="white"
                  strokeWidth="3"
                  strokeLinecap="round"
                />
              </svg>
            </button>
          </div>
        </Dialog>
        <Dialog
          role="dialog"
          open={imageModelFive.isOpen}
          onClose={imageModelFive.close}
          className="dsmes-modal modal-theme-azure"
          aria-label="I Will Monitor My Blood Sugar"
        >
          <button
            className="modal-close-btn"
            onClick={imageModelFive.close}
            aria-label="Close"
          >
            <CloseIcon className="modal-close-icon" />
          </button>
          <div
            className="modal-header"
            style={{ backgroundImage: `url(${popup5})` }}
          >
            &nbsp;
          </div>
          <div id="modalTitle-5" className="modal-title">
            <h3 tabIndex={0} className="strong">
              I Will Monitor My Blood Sugar
            </h3>
          </div>
          <div tabIndex={0} id="modalContent-5" className="modal-content">
            <p>
              Monitoring your blood sugar is an important part of reducing your
              risk of issues like vision loss and kidney disease.
            </p>
            <p>
              If you use insulin for your type 1 or type 2 diabetes, or if you
              are on other diabetes medicines, you could be at risk for low
              blood sugar (hypoglycemia).
            </p>
            <p>
              Many things can also cause high blood sugar (hyperglycemia),
              including being sick, being stressed, eating more than planned,
              and not giving yourself enough insulin.
            </p>
            <p>Typical blood sugar targets are:</p>
            <ul>
              <li>Before a meal: 80-130 mg/dL</li>
              <li>Two hours after the start of a meal: Less than 180 mg/dL</li>
            </ul>
            <p>
              Talk to your health-care provider about what your blood sugar
              levels should be. Monitor your blood sugar as recommended by your
              health-care provider to help prevent low or high blood sugar.
            </p>
            <p>
              Learn more by checking out the{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="/monitoring-diabetes"
              >
                Monitoring Diabetes
              </a>{" "}
              page.
            </p>
          </div>
          <div className="modal-footer">
            <button
              onClick={() => {
                imageModelFive.close();
                imageModelFour.open();
              }}
              className="prev"
              aria-label="Previous"
            >
              <svg
                width="17"
                height="28"
                viewBox="0 0 17 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15 2L3 14L15 26"
                  stroke="white"
                  strokeWidth="3"
                  strokeLinecap="round"
                />
              </svg>
            </button>
            <button
              onClick={() => {
                imageModelFive.close();
                imageModelSix.open();
              }}
              className="next"
              aria-label="Next"
            >
              <svg
                width="17"
                height="28"
                viewBox="0 0 17 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2 26L14 14L2 2"
                  stroke="white"
                  strokeWidth="3"
                  strokeLinecap="round"
                />
              </svg>
            </button>
          </div>
        </Dialog>
        <Dialog
          role="dialog"
          open={imageModelSix.isOpen}
          onClose={imageModelSix.close}
          className="dsmes-modal modal-theme-azure"
          aria-label="I Will Talk About My Feelings"
        >
          <button
            className="modal-close-btn"
            onClick={imageModelSix.close}
            aria-label="Close"
          >
            <CloseIcon className="modal-close-icon" />
          </button>
          <div
            className="modal-header"
            style={{ backgroundImage: `url(${popup6})` }}
          >
            &nbsp;
          </div>
          <div id="modalTitle-6" className="modal-title">
            <h3 tabIndex={0} className="strong">
              I Will Talk About My Feelings
            </h3>
          </div>
          <div tabIndex={0} id="modalContent-6" className="modal-content">
            <p>
              Did you know Black Americans are 20% more likely to report serious
              mental health distress?
            </p>
            <p>
              Are you are feeling stress, sadness, or other similar feelings? If
              so, don’t hold it in! Get out your journal, call a friend, talk to
              a spiritual leader, or meet with a diabetes care specialist.
              Managing your stress should be a priority, and it’s important to
              understand how to recognize when you are stressed and how to
              manage stress positively. Check out the Living With Diabetes
              module or read some diabetes educator tips to learn more about{" "}
              <ExternalLink url=" https://www.diabeteseducator.org/docs/default-source/living-with-diabetes/tip-sheets/healthy-coping/stress_eng.pdf?sfvrsn=ca66b458_8">
                dealing with stress
              </ExternalLink>{" "}
              to learn more.
            </p>
            <p>
              Learn more about healthy ways to manage your mental health by
              visiting the{" "}
              <a
                href={RoutePaths.CopingDiabetes}
                target="_blank"
                rel="noopener noreferrer"
              >
                Living with Diabetes
              </a>{" "}
              page.{" "}
            </p>
          </div>
          <div className="modal-footer">
            <button
              onClick={() => {
                imageModelSix.close();
                imageModelFive.open();
              }}
              className="prev"
              aria-label="Previous"
            >
              <svg
                width="17"
                height="28"
                viewBox="0 0 17 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15 2L3 14L15 26"
                  stroke="white"
                  strokeWidth="3"
                  strokeLinecap="round"
                />
              </svg>
            </button>
            <button
              onClick={() => {
                imageModelSix.close();
                imageModelSeven.open();
              }}
              className="next"
              aria-label="Next"
            >
              <svg
                width="17"
                height="28"
                viewBox="0 0 17 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2 26L14 14L2 2"
                  stroke="white"
                  strokeWidth="3"
                  strokeLinecap="round"
                />
              </svg>
            </button>
          </div>
        </Dialog>
        <Dialog
          role="dialog"
          open={imageModelSeven.isOpen}
          onClose={imageModelSeven.close}
          className="dsmes-modal modal-theme-azure"
          aria-label="I Will Get My Vaccinations"
        >
          <button
            className="modal-close-btn"
            onClick={imageModelSeven.close}
            aria-label="Close"
          >
            <CloseIcon className="modal-close-icon" />
          </button>
          <div
            className="modal-header mtr3"
            style={{ backgroundImage: `url(${popup7})` }}
          >
            &nbsp;
          </div>
          <div id="modalTitle-7" className="modal-title">
            <h3 tabIndex={0} className="strong">
              I Will Get My Vaccinations
            </h3>
          </div>
          <div tabIndex={0} id="modalContent-7" className="modal-content">
            <p>
              Diabetes can increase your risk of getting sick. Keeping up with
              regular vaccinations can help. Talk with your provider about what
              vaccinations you may need, like the yearly flu vaccine, the
              pneumonia vaccine, the COVID-19 vaccine, and others.
            </p>
          </div>
          <div className="modal-footer">
            <button
              onClick={() => {
                imageModelSeven.close();
                imageModelSix.open();
              }}
              className="prev"
              aria-label="Previous"
            >
              <svg
                width="17"
                height="28"
                viewBox="0 0 17 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15 2L3 14L15 26"
                  stroke="white"
                  strokeWidth="3"
                  strokeLinecap="round"
                />
              </svg>
            </button>
            <button
              onClick={() => {
                imageModelSeven.close();
                imageModelEight.open();
              }}
              className="next"
              aria-label="Next"
            >
              <svg
                width="17"
                height="28"
                viewBox="0 0 17 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2 26L14 14L2 2"
                  stroke="white"
                  strokeWidth="3"
                  strokeLinecap="round"
                />
              </svg>
            </button>
          </div>
        </Dialog>
        <Dialog
          role="dialog"
          open={imageModelEight.isOpen}
          onClose={imageModelEight.close}
          className="dsmes-modal modal-theme-azure"
          aria-label="I Will Practice Healthy Habits"
        >
          <button
            className="modal-close-btn"
            onClick={imageModelEight.close}
            aria-label="Close"
          >
            <CloseIcon className="modal-close-icon" />
          </button>
          <div
            className="modal-header mtr5"
            style={{ backgroundImage: `url(${popup8})` }}
          >
            &nbsp;
          </div>
          <div id="modalTitle-8" className="modal-title">
            <h3 tabIndex={0} className="strong">
              I Will Practice Healthy Habits
            </h3>
          </div>
          <div tabIndex={0} id="modalContent-8" className="modal-content">
            <p>
              Healthy habits can go a long way in lowering your risk of other
              health issues. Here are a few healthy tips:
            </p>
            <p>
              <strong>Quit Smoking or Don’t Start:</strong> Smoking increases
              your risk of strokes, heart attacks, and other health issues.
            </p>
            <p>
              <strong>Move Your Body:</strong> Moving daily can help reduce risk
              of health problems. Try to sit less and move more, like going on
              walks while on a call, stretching while catching up on your
              favorite show, or even dancing while listening to music. Check out
              our{" "}
              <a
                href={RoutePaths.PhysicalActivity}
                target="_blank"
                rel="noopener noreferrer"
              >
                Increasing Physical Activity
              </a>{" "}
              page to learn more ways to get moving.
            </p>
            <p>
              <strong>Brush & Floss Your Teeth:</strong> Diabetes can cause gum
              infections. Take care of your teeth daily!
            </p>
          </div>
          <div className="modal-footer">
            <button
              onClick={() => {
                imageModelEight.close();
                imageModelSeven.open();
              }}
              className="prev"
              aria-label="Previous"
            >
              <svg
                width="17"
                height="28"
                viewBox="0 0 17 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15 2L3 14L15 26"
                  stroke="white"
                  strokeWidth="3"
                  strokeLinecap="round"
                />
              </svg>
            </button>
          </div>
        </Dialog>
      </div>
    </section>
  );
};
