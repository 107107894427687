// import portionSizeImg from '../../../assets/images/SelfCare/Diabetes-Manage-Eat-Well-Plate-Graphic_600px 1.png'
import headerUnderline from "../../../../assets/images/modal-header-underline.png";
import spiritImg from "../../../../assets/images/copingDiabetes/spirit-volunteer-head.png";
import styles from "./SelfCareModals.scss";

export const SelfCareModalContent11 = () => {
  return (
    <>
      <div id="modalTitle-1" className="modal-title">
        <h2 className="display-h2">Spirit - Volunteer</h2>
        <img
          className="headerUnderline"
          src={headerUnderline}
          alt="decorative underline"
        />
      </div>
      <div id="modalContent-1" className="modal-content">
        <img
          className="headImg"
          src={spiritImg}
          alt="woman and young girl reading a book smiling"
        />
        <p>
          Since ancient times, helping others has been seen as a virtue that can
          benefit both the giver and the receiver. Helping others can reduce
          unhealthy feelings and increase healthy feelings like love, joy, and
          generosity. <strong>Volunteering</strong> can be a small but mighty
          act that can help you{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.cdc.gov/emotional-wellbeing/features/power-of-connection.htm"
          >
            build meaningful social connections and feel a sense of belonging
          </a>{" "}
          that adds to your overall health and quality of life.{" "}
        </p>
      </div>
    </>
  );
};
