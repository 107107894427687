import BannerImg from "../../assets/images/creatingCommunity/creating-community-header.jpeg";
import { ExternalLink } from "../ExternalLink/ExternalLink";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import imgPreDB from "../../../src/assets/images/creatingCommunity/prediabetes.png";
import imgFamily from "../../../src/assets/images/creatingCommunity/creating-community-header-family-prediabetes.jpg";
import imgVillage from "../../../src/assets/images/creatingCommunity/creating-community-family-friends.jpg";
import imgPeerGroup from "../../../src/assets/images/creatingCommunity/creating-community-connecting.jpg";
import imgSupport from "../../../src/assets/images/creatingCommunity/creating-community-support-group.jpg";
import emergImg from "../../../src/assets/images/creatingCommunity/Diabetes-Care-Kit.png";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SupportTopics from "../SupportTopics/SupportTopics";
import FindSisterhood from "../FindSisterhood/FindSisterhood";
import ConnectToSpecialist from "../ConnectToSpecialist/ConnectToSpecialist";
import americanDiabetes from "../../assets/images/creatingCommunity/americanDiabetesOrg.png";
import africanAmericanDiabetes from "../../assets/images/creatingCommunity/aada_logo.webp";
import adces from "../../assets/images/creatingCommunity/adces.png";
import beyond from "../../assets/images/creatingCommunity/beyond.png";
import tcoyd from "../../assets/images/creatingCommunity/tcoyd.png";
import defeat from "../../assets/images/creatingCommunity/defeatDiabetesOrg.png";
import { VideoLink } from "../VideoLink/VideoLink";
import infographicCreating from "../../assets/Preparing-Your-Village.pdf";
import infographicCreatingKit from "../../assets/Diabetes-Care-Kit.pdf";
import "./CreatingCommunity.css";
import { Grid } from "@mui/material";

function CreatingCommunity() {
  return (
    <>
      <div className="community-gradient-container">
        <div className="community-banner-container main-banner">
          <VideoLink url="https://www.youtube.com/watch?v=03v4k3PmeP0" />
          <div className="community-banner-text">
            <h1 className="display-h1">
              <span>Creating</span> Community Support
            </h1>
            <p>
              Despite stressors and circumstances that can make you feel like
              you have to be a superwoman, you don’t have to manage your type 2
              diabetes alone. In fact, the feeling of community a person has is
              one of the best ways to predict how well they will manage their
              diabetes, as help from family, friends, and others can reduce
              health risks. You can create support for your diabetes journey in
              different ways. For instance, a small community of people can help
              you access more health information than you can alone. This module
              will offer tips on:
            </p>
            <ul>
              <li>How to talk with your family about living with diabetes </li>
              <li>Ways your family and friends can support you</li>
              <li>Connecting with communities of people managing diabetes</li>
            </ul>
          </div>

          <div className="creatingAccordions"></div>

          <div className="community-banner-image-container">
            <div className="community-banner-image-wrapper">
              <img
                role="presentation"
                className="community-banner-img"
                src={BannerImg}
                alt="two women smiling on a bench"
              />
            </div>
          </div>
        </div>
        {/* </div> */}

        {/* begin accordions */}
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            alt="Click to expand the section How To Talk With Your Family About Diabetes."
          >
            <h3 className="accordion-header">
              How To Talk With Your Family About Diabetes
            </h3>
          </AccordionSummary>
          <AccordionDetails>
            <div className="accordionDetails">
              <div className="img-block"></div>
              <div className="text-block">
                <p>
                  People who have a family history of diabetes are more likely
                  to have prediabetes and develop diabetes. By sharing your
                  diabetes journey, you can encourage your family members to
                  learn more about how to reduce their risk of developing type 2
                  diabetes.{" "}
                </p>
                <p className="strong">
                  <strong>
                    Consider having your loved ones take{" "}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.cdc.gov/prediabetes/risktest/index.html"
                    >
                      CDC’s online prediabetes risk test:
                    </a>
                  </strong>
                </p>
                <div className="riskTestWrapper">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.cdc.gov/prediabetes/risktest/index.html"
                  >
                    <img
                      src={imgPreDB}
                      alt="Screenshot of the CDC Prediabetes Risk Test"
                    />
                  </a>
                  <br />
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.cdc.gov/prediabetes/risktest/index.html"
                  >
                    https://www.cdc.gov/prediabetes/risktest/index.html
                  </a>
                </div>
                <h3 className="paleGreenBanner">Family Tips</h3>
                <p className="strong">
                  <strong>
                    Here are some tips on talking with your family about how
                    they can reduce their risk of developing type 2 diabetes:{" "}
                  </strong>
                </p>
                <ul>
                  <li>
                    Educate yourself about prediabetes causes and symptoms
                    before talking with family members.
                    <ul>
                      <li>
                        Many of the lifestyle habits to manage your diabetes are
                        also effective for diabetes prevention, including{" "}
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="increasing-physical-activity"
                        >
                          physical activity
                        </a>
                        , eating healthfully, and managing weight.{" "}
                      </li>
                    </ul>
                  </li>
                  <li>
                    Talk openly and honestly with family about common diabetes
                    symptoms and{" "}
                    <ExternalLink
                      url="https://diabetes.org/tools-support/diabetes-prevention/diabetes-myths"
                      cname="org-button"
                      aLabel="Common Diabetes Myths"
                    >
                      myths
                    </ExternalLink>
                    .
                  </li>
                  <li>
                    Prepare for how family members may respond to your concerns.
                    You know that diabetes can happen to anyone, but your loved
                    one may view your concern about their health differently.
                    Let your loved one know that you care about their health and
                    want to help them prevent or delay type 2 diabetes.
                  </li>
                  <li>
                    Take on the challenge together with your family of finding
                    healthier food alternatives at social gatherings and special
                    holidays like Thanksgiving. Avoiding foods that are linked
                    to your traditions or culture can make you feel disconnected
                    from your family and identity. Check out the{" "}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="healthy-eating-cooking"
                    >
                      Creative Eating and Cooking module
                    </a>{" "}
                    to find new ways you can prepare your family’s favorite
                    foods.
                  </li>
                </ul>
                <div className="community-banner-image-wrapper">
                  <img
                    role="presentation"
                    className="community-banner-img"
                    src={imgFamily}
                    alt="Three women cooking in the kitchen smiling."
                  />
                </div>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            alt="Click to expand the section Ways Your Family and Friends Can Support You."
          >
            <h3 className="accordion-header">
              Ways Your Family and Friends Can Support You
            </h3>
          </AccordionSummary>
          <AccordionDetails>
            <div className="accordionDetails">
              <div className="text-block">
                <p>
                  Even though their hearts may be in the right place, the people
                  who love you the most may not always know how to be helpful.
                  Preparing your village of family and friends for your diabetes
                  journey can be a great way to make use of the existing
                  communities in your life. Here are some general tips you can
                  share with them on ways they can help you:
                </p>
                <div className="flexGrid">
                  <div className="gridItem">
                    Ask them how they can help, and then listen to what they
                    say.
                  </div>
                  <div className="gridItem">
                    Ask them to try not to nag or lecture you about your habits.
                  </div>
                </div>

                <div className="flexGrid">
                  <div className="gridItem">
                    Talk to them about the changes in mood you experience. Let
                    them know your moods can change with blood sugar levels,
                    from happy to sad or irritable.
                  </div>
                  <div className="gridItem">
                    Ask them if it is OK to go along with you on medical
                    appointments.
                  </div>
                </div>
                <div className="LikeWhatYouSee">
                  <h3>Like what you see so far?</h3>
                  <p>
                    Download and share the full list with kinfolk and friends.{" "}
                  </p>
                  <p className="strong">
                    <strong>
                      <a
                        href={infographicCreating}
                        taret="_blank"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Preparing Your Village: Friends, Family & Diabetes (PDF){" "}
                      </a>
                    </strong>
                  </p>
                </div>
                <div className="community-banner-image-wrapper">
                  <img
                    role="presentation"
                    className="community-banner-img"
                    alt="Two women laughing"
                    src={imgVillage}
                  />
                </div>
                <h3 className="paleGreenBanner">Emergency Care</h3>
                <p>
                  Empowering your loved ones with important information can be a
                  helpful way to include them in your diabetes care,{" "}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.cdc.gov/diabetes/library/factsheets/help-diabetes-when-you-live-apart.html"
                  >
                    especially if they don’t live nearby
                  </a>
                  . For example, you can share your key medical contacts,
                  typical doses of medicines you are taking, and emergency
                  contacts. Use{" "}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.cdc.gov/diabetes/pdfs/managing/A_far_away_h.pdf#page=5"
                  >
                    this template
                  </a>{" "}
                  to gather important information in one place and be sure to
                  share the copies with your loved ones.{" "}
                </p>
              </div>
              <p>
                <strong>Creating Diabetes Care Kits</strong>
              </p>
              <p>
                Even though you may be monitoring different aspects of your
                diabetes, diabetic emergencies like extremely high blood sugar
                or low blood sugar can happen anytime or anywhere. Creating
                diabetes care kits can be a good way to have life-saving
                resources available so your loved ones are equipped to help you.
                Keep the kit in an easy-to-carry waterproof bag or storage
                container. Show your friends and family where you keep it.
                Consider making and sharing recorded tutorials or written
                instructions so they know how to use the items inside. Here are
                a few items you may want to include in your kit:
              </p>
              <div className="emergencyFlexList">
                <span className="emergListLeft">
                  <div className="emergencyListItem">
                    <span>&#8226;</span>
                    <div>
                      Insulin and syringes for every injection (learn more about{" "}
                      <ExternalLink
                        url="https://diabetes.org/healthy-living/medication-treatments/insulin-other-injectables/insulin-storage-and-syringe-safety#:~:text=Insulin%20Storage%20and%20Syringe%20Safety.%20Insulin%20kept%20at,so%20it%20is%20ready%20for%20your%20next%20injection"
                        cname="org-button"
                        aLabel="Insulin Storage"
                      >
                        insulin storage
                      </ExternalLink>
                      )
                    </div>
                  </div>
                  <div className="emergencyListItem">
                    <span>&#8226;</span>
                    <div>Blood sugar (glucose) meter</div>
                  </div>
                  <div className="emergencyListItem">
                    <span>&#8226;</span>
                    <div>
                      Extra batteries for your blood sugar meter and insulin
                      pump
                    </div>
                  </div>
                  <div className="emergencyListItem">
                    <span>&#8226;</span>
                    <div>Lancets and lancing devices</div>
                  </div>
                  <div className="emergencyListItem">
                    <span>&#8226;</span>
                    <div>
                      Insulin pump supplies, including extra pump sets and
                      insertion devices
                    </div>
                  </div>
                  <div className="emergencyListItem">
                    <span>&#8226;</span>
                    <div>Glucagon kits</div>
                  </div>
                  <div className="emergencyListItem">
                    <span>&#8226;</span>
                    <div>Ketone strips</div>
                  </div>
                  <div className="emergencyListItem">
                    <span>&#8226;</span>
                    <div>Alcohol wipes</div>
                  </div>
                  <div className="emergencyListItem">
                    <span>&#8226;</span>
                    <div>
                      Glucose tablets or 15 grams of “quick carbs” (such as
                      juice, hard candy, or honey) to treat low blood sugar
                    </div>
                  </div>
                  <div className="emergencyListItem">
                    <span>&#8226;</span>
                    <div>Oral diabetes medicine</div>
                  </div>
                  <div className="emergencyListItem">
                    <span>&#8226;</span>
                    <div>
                      An empty plastic bottle or sharps container to safely
                      carry syringes, needles, and lancets
                    </div>
                  </div>
                  <div className="emergencyListItem leftIndent">
                    <span>&#111;</span>
                    <div>
                      Be sure to store your supplies properly according to the
                      manufacturer’s instructions. Also check the expiration
                      dates for your supplies every few months and replace
                      anything that’s close to expiring. You can then use the
                      supplies that were in the kit for your daily care before
                      they reach the expiration date.
                    </div>
                  </div>
                </span>
                <span className="coloredSquare">
                  <img
                    src={emergImg}
                    alt="Illustration of an diabetes emergency kit with notepad"
                  />
                  <p>
                    YouTube:{" "}
                    <ExternalLink
                      url="https://www.youtube.com/watch?v=KFqVq7rm2AI&amp;t=17s"
                      cname="org-button"
                      aLabel="How to pack your diabetes emergency kit"
                    >
                      How to pack your diabetes emergency kit
                    </ExternalLink>{" "}
                    (American Association of Clinical Endocrinology)
                  </p>
                  <br />
                  <p>
                    Find this emergency kit list helpful?{" "}
                    <a
                      href={infographicCreatingKit}
                      taret="_blank"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Download a copy to help create your own emergency kit.
                    </a>{" "}
                  </p>
                </span>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <h3 className="accordion-header">
              Connecting With Communities of People Managing Diabetes
            </h3>
          </AccordionSummary>
          <AccordionDetails>
            <div className="accordionDetails">
              <div className="img-block"></div>
              <div className="text-block">
                <p>
                  Friends and family can be great sources of support and
                  understanding. But they may not be able to relate to some of
                  the experiences you have as someone living with diabetes.
                  That’s why peer support groups can play a powerful role in
                  helping you manage diabetes. A peer group can help you find
                  support and a sense of sisterhood and connection. If you don’t
                  have a support group or sister circle in your area, think
                  about starting one. Chapters of "Divine Nine" sororities and
                  nonprofits serving historically Black communities can make
                  great partners. If you’re a woman of faith, church ministries
                  can also be a good place to connect and fellowship about your
                  experiences with diabetes. The{" "}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.cdc.gov/diabetes/professional-info/toolkits/new-beginnings/index.html"
                  >
                    <strong>New Beginnings</strong>
                  </a>{" "}
                  discussion guide is a tool that can help you jump-start a crew
                  of supportive sister friends. It uses stories, activities, and
                  facilitated discussion to address the emotional impact of
                  diabetes. Plus, it was created to meet the needs of Black
                  women just like you.
                </p>

                <div className="community-banner-image-wrapper">
                  <img
                    role="presentation"
                    className="community-banner-img"
                    alt="Five people seated in a circle talking."
                    src={imgPeerGroup}
                  />
                </div>
                <p className="strong">
                  Here are some tips to get the most out of a support group:{" "}
                </p>
                <ul>
                  <li>
                    It’s important to be honest about what you’re dealing with
                    from day to day and to support your peers by listening to
                    them.
                  </li>
                  <li>Ask questions and ask for help when you need it.</li>
                  <li>
                    Like the African proverb says, “If you want to go fast, go
                    alone. But if you want to go far, go together.” Share your
                    goals and ask for the support you need to reach them.
                  </li>
                </ul>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>

        <div className="community-support-title">
          <div className="community-support-text-container">
            <h2>Finding a Peer Support Group</h2>
            <p>
              Although peer support groups shouldn’t replace professional health
              care advice, being able to connect with others who share your
              lived experience is a key part of building your community. 
              <ExternalLink url="https://www.diabeteseducator.org/living-with-diabetes/peer-support">
                Joining a diabetes peer support group
              </ExternalLink>{" "}
              means you can share your thoughts and feelings with people like
              you. Below you’ll find organizations that can help you connect
              with others who are also on a diabetes journey:
            </p>

            <div className="community-banner-image-container">
              <div className="community-banner-image-wrapper">
                <img
                  role="presentation"
                  className="community-banner-img"
                  src={imgSupport}
                  alt="Three women at a coffeshop talking."
                />
              </div>
            </div>
          </div>
        </div>
        <div className="support-container">
          <div className="support-orgs-container">
            <Grid container spacing={8}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                {/*<div className='org-logo-container'>
                <img className="org-logo" src={americanDiabetes} alt="American Diabetes Association logo" />
              </div>*/}
                <div className="org-text-container">
                  <h3>American Diabetes Association</h3>
                  <p>
                    The American Diabetes Association (ADA) has offices around
                    the country that can help connect you with peer support
                    groups in your community. You can search for your 
                    <ExternalLink url="https://www.diabetes.org/community/local-offices">
                      local ADA office
                    </ExternalLink>{" "}
                    for help finding a support group near you.
                  </p>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                {/*<div className='org-logo-container'>
                <img className="org-logo" src={defeat} alt="Defeat Diabetes Foundation logo" />
              </div>*/}
                <div className="org-text-container">
                  <h3>Defeat Diabetes Foundation</h3>
                  <p>
                    The Defeat Diabetes Foundation provides community-based
                    programs and resources to people living with diabetes. It
                    also has a 
                    <ExternalLink url="https://defeatdiabetes.org/get-healthy__trashed/diabetes-support-groups/">
                      directory
                    </ExternalLink>{" "}
                    with support groups organized by state.
                  </p>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                {/*  <div className='org-logo-container'>
              <img className="org-logo" src={tcoyd} alt="Taking Control of Your Diabetes logo" />
              </div>*/}
                <div className="org-text-container">
                  <h3>Taking Control of Your Diabetes</h3>
                  <p>
                    Taking Control of Your Diabetes is a nonprofit organization
                    that helps to educate and motivate people with diabetes. It
                    also hosts 
                    <ExternalLink url="https://tcoyd.org/patient-events/">
                      conferences for people with diabetes
                    </ExternalLink>
                    .
                  </p>
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                className="fit-org-beyond"
              >
                {/* <div className='org-logo-container'>
               <img className="org-logo" src={beyond} alt="Beyond Type 2 logo" />
              </div>*/}
                <div className="org-text-container">
                  <h3>Beyond Type 2</h3>
                  <p>
                    Beyond Type 2 aims to unite the global diabetes community
                    and empower everyone living with type 2 diabetes. It runs an{" "}
                    <ExternalLink url="https://community.beyondtype2.org/">
                      online community for those impacted by type 2 diabetes
                    </ExternalLink>
                    .
                  </p>
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                className="fit-org-beyond"
              >
                {/* <div className='org-logo-container'>
              <img className="org-logo" src={africanAmericanDiabetes} alt="African American Diabetes Association" />
              </div>*/}
                <div className="org-text-container">
                  <h3>African American Diabetes Association</h3>
                  <p>
                    The African American Diabetes Association (AADA) is one of
                    the only national organizations established to address and
                    educate African American people living with diabetes and
                    their families. You can{" "}
                    <ExternalLink url="https://www.africanamericandiabetes.org/general-6-1">
                      become a member or learn how to start an AADA chapter near
                      you
                    </ExternalLink>
                  </p>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
      {/* End accordions */}
      <FindSisterhood topic="support" />
      <div id="SupportTopics" className="SupportTopics">
        <SupportTopics />
      </div>
      <ConnectToSpecialist />
    </>
  );
}

export default CreatingCommunity;
